import { NON_PII_QUESTIONS } from "@/constants";
import { SurveyAnswer, useAppContext } from "@/contexts";
import { isCareFirstPartner } from "@/utils/partners";

import { useSaveTriageData } from "./useSaveTriageData";
import { useTrackEvent } from "./useTrackEvent";

export const useAnswerSurveyQuestion = () => {
  const {
    enrollmentType,
    partnerInfo,
    surveyAnswers,
    surveyMetadata,
    setSurveyAnswers,
  } = useAppContext();
  const { saveTriageData } = useSaveTriageData(partnerInfo?.id);

  const { trackAnonEvent, trackSurveyAnonEvent } = useTrackEvent();

  const getNewSurveyAnswersState = (
    questionId: string,
    answer: SurveyAnswer,
    additionalAnswers?: Record<string, SurveyAnswer>,
  ) => ({
    ...surveyAnswers,
    [questionId]: answer,
    ...(!!additionalAnswers && additionalAnswers),
  });

  const answerSurveyQuestion = async (
    questionId: string,
    answer: SurveyAnswer,
    additionalAnswers?: Record<string, SurveyAnswer>,
    skipTracking = false,
  ) => {
    const newSurveyAnswers = getNewSurveyAnswersState(
      questionId,
      answer,
      additionalAnswers,
    );

    const allAnswerIds: string[] = Array.isArray(answer)
      ? answer
      : [answer?.toString() || ""];

    const trackedAnswerIds = NON_PII_QUESTIONS.includes(questionId)
      ? allAnswerIds
      : [];

    if (!skipTracking) {
      if (surveyMetadata) {
        trackSurveyAnonEvent("b2bSurveyAnonQuestionAnswered", {
          answerIds: trackedAnswerIds,
          pageId: questionId,
          questionId,
        });
      } else {
        trackAnonEvent("b2bEnrollmentAnonQuestionAnswered", {
          answerIds: trackedAnswerIds,
          answerTexts: [],
          pageId: questionId,
          questionId,
        });
      }
    }

    if (
      isCareFirstPartner(partnerInfo) &&
      enrollmentType === "INITIAL_ENROLLMENT"
    ) {
      await saveTriageData(newSurveyAnswers, "PARTIAL");
    }

    setSurveyAnswers(newSurveyAnswers);
  };

  return { answerSurveyQuestion, getNewSurveyAnswersState };
};
