import React from "react";

import { Spacing, Stack } from "@noom/wax-component-library";

import { QuestionOption } from "@/components/survey/Question";

import { CheckboxButton } from "./CheckboxButton";

export type CheckboxOption = QuestionOption & {
  subtext?: string;
};

type CheckboxOptionsProps = {
  checkedItems: string[];
  options: CheckboxOption[];
  submitCheckboxQuestion: () => void;
  toggleCheckedState: (optionId: string, currentCheckedState: boolean) => void;
};

export const CheckboxOptions: React.FC<CheckboxOptionsProps> = ({
  checkedItems,
  options,
  submitCheckboxQuestion,
  toggleCheckedState,
}) => (
  <Stack spacing={Spacing[4]}>
    {options.map((option) => {
      const checked = !!checkedItems.find(
        (checkedItem) => checkedItem === option.id,
      );

      return (
        <CheckboxButton
          checked={checked}
          key={option.id}
          onChange={() => toggleCheckedState(option.id, checked)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              submitCheckboxQuestion();
            }
          }}
        >
          <Stack
            spacing={Spacing[0]}
            padding={option.subtext && `${Spacing[16]}px 0`}
          >
            <span style={option.subtext ? { fontWeight: 500 } : undefined}>
              {option.text}
            </span>
            {option.subtext && <span>{option.subtext}</span>}
          </Stack>
        </CheckboxButton>
      );
    })}
  </Stack>
);
