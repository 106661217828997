import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import type { GetTelehealthEligibleStatesResponseProto } from "@noom/noom-contracts/noom_contracts/healthcare/eligible_states_service";
import { Api } from "@noom/noomscape";
import { Button, Select, Spacing, Stack } from "@noom/wax-component-library";

import { USState } from "@/constants";
import { useAppContext } from "@/contexts";
import { qualifiesForNoomMed } from "@/utils/qualification";
import { captureException } from "@/utils/sentry";

import { QuestionComponentProps, QuestionDefinition } from "../../Question";
import { BaseQuestion } from "../core";

const QUESTION_ID = "locationState";

declare module "@/contexts" {
  interface SurveyAnswers {
    locationState?: string;
  }
}

type LocationStateFormValues = {
  locationState?: string;
};

const US_STATE_ABBREVIATIONS = Object.keys(USState) as (keyof typeof USState)[];

const stateOptions = US_STATE_ABBREVIATIONS.map((key) => ({
  id: key,
  text: USState[key],
}));

const LocationStateQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const initialValues: LocationStateFormValues = {
    locationState: surveyAnswers?.locationState,
  };

  const { telehealthEligibleStates, setTelehealthEligibleStates } =
    useAppContext();

  useEffect(() => {
    async function loadTelehealthEligibleStates() {
      try {
        const result: GetTelehealthEligibleStatesResponseProto = await Api.call(
          "telehealth.getEligibleTelehealthStates",
          Api.api.telehealth.getEligibleTelehealthStates,
        );
        setTelehealthEligibleStates(result.eligibleStates);
      } catch (err) {
        // set an empty array as default so users can continue the enrollment
        // but not enrolling in Med
        // log the error to sentry
        setTelehealthEligibleStates([]);
        captureException(err);
      }
    }

    if (!telehealthEligibleStates) {
      loadTelehealthEligibleStates();
    }
  }, [telehealthEligibleStates, setTelehealthEligibleStates]);

  return (
    <BaseQuestion
      onClickBack={onClickBack}
      questionHelperText={t("locationState.questionHelperText")}
      questionId={QUESTION_ID}
      questionText={t("locationState.questionText")}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={({ locationState }: LocationStateFormValues) => {
          if (!locationState) {
            return;
          }

          onClickNext(locationState);
        }}
      >
        {({ handleSubmit, getFieldProps, values }) => (
          <Form onSubmit={handleSubmit}>
            <Stack spacing={Spacing[8]}>
              <Select {...getFieldProps("locationState")}>
                <option aria-label="Select a state" value="">
                  {t("locationState.placeholder")}
                </option>
                {stateOptions.map(({ id, text }) => (
                  <option key={id} value={id}>
                    {text}
                  </option>
                ))}
              </Select>
              <Button
                isDisabled={!values.locationState || !telehealthEligibleStates}
                colorScheme="primary"
                size="xl"
                type="submit"
              >
                {t("locationState.ctaText")}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </BaseQuestion>
  );
};

export const Q_LOCATION_STATE: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: (state) =>
    !state.partnerInfo?.configs.ACCOLADE_PARTNER &&
    state.selectedProgram !== "MOOD" &&
    qualifiesForNoomMed(state),
  component: LocationStateQuestion,
};
