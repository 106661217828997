import React from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  CompassColor,
  Heading,
  Image,
  Text
} from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { BaseInterstitial } from "@/components/survey/questionTypes/core";
import { PrimaryCareProviderSelection } from "@/constants";

const QUESTION_ID = "diabetesSupport";

declare module "@/contexts" {
  interface SurveyAnswers {
    diabetesSupport?: boolean;
  }
}

const DiabetesSupport: React.FC<QuestionComponentProps> = ({ onClickNext }) => {
  const { t } = useTranslation();

  return (
    <BaseInterstitial
      backgroundColor={CompassColor.blueberry}
      onClickBack={() => null}
      onClickNext={() => Promise.resolve()}
      questionId={QUESTION_ID}
      width="550px"
    >
      <Box>
        <Image
          alt="Diamond"
          src="/assets/img/diamond-tarocco.svg"
          height={{ base: "48px", lg: "64px" }}
          alignSelf="start"
          mt="40px"
        />
        <Box mt="40px">
          <Text
            fontSize="20px"
            color={CompassColor.white}
            mb="10px"
            display="inline-block"
          >
            {t("diabetesSupport.subHeading")}
          </Text>
          <Heading
            fontSize="36px"
            fontWeight={500}
            lineHeight="36px"
            color={CompassColor.white}
            mb="28px"
            fontFamily="serif"
          >
            {t("diabetesSupport.heading")}
          </Heading>
          <Text fontSize="20px" color={CompassColor.white}>
            {t("diabetesSupport.body")}
          </Text>
        </Box>
      </Box>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        marginTop="var(--spacing-xl)"
      >
        <Button
          colorScheme="primary"
          onClick={() => onClickNext(true)}
          padding="unset"
          size="xl"
        >
          {t("diabetesSupport.cta")}
        </Button>
      </Box>
    </BaseInterstitial>
  );
}

export const Q_DIABETES_SUPPORT: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo, surveyAnswers: { primaryCareProvider } }) =>
    !!(partnerInfo?.configs.OFFERS_DIABETES_MANAGEMENT && primaryCareProvider === PrimaryCareProviderSelection.YES),
  component: DiabetesSupport,
};
