import { PartnerProto_EligibilityKeyProto_Value } from "@noom/noom-contracts/noom_contracts/backend/partner/model/partner";

import { EligibilityEnforcementType } from "./Eligibility";

export type EligibilityKeyType = Exclude<
  PartnerProto_EligibilityKeyProto_Value,
  "UNKNOWN" | "UNRECOGNIZED"
>;

export type PartnerEligibilityFileLocation = {
  id: number;
  location: string;
  filenameRegex?: string;
  lastFileIngested?: string;
  lastFileIngestedTimeStamp?: Date;
};

export type Partner = {
  configs: {
    ACCOLADE_PARTNER: boolean;
    CUSTOM_SUPPORT_LINK?: string;
    DISPLAY_NAME?: string;
    DIVISIONAL_ONLY_PARTNER: boolean;
    ELIGIBILITY_KEY: string;
    ENROLL_PASSCODE: boolean;
    ENROLL_UHC: boolean;
    ENROLL_UPID: boolean;
    ENROLL_VIRGIN_PULSE: boolean;
    HIDE_COST: boolean;
    MAINTAIN_ORIGINAL_LOGO: boolean;
    MEDICARE_PARTNER: boolean;
    OFFERS_DIABETES_MANAGEMENT: boolean;
    OFFERS_MED: boolean;
    OFFERS_RETRIAGE: boolean;
    OFFER_SCALE_SHIPMENT: boolean;
    REQUIRES_DOB: boolean;
    REQUIRES_PARTICIPANT_ID: boolean;
    UPFRONT_ELIGIBILITY_ENFORCEMENT: EligibilityEnforcementType;
  };
  defaultBatch: number;
  deploymentId: number;
  division: string;
  eligibilityKey: EligibilityKeyType;
  enrollPasscode: boolean;
  enrollUPID: boolean;
  enrollVirginPulse: boolean;
  goLiveDate?: Date;
  hideCost: boolean;
  id: number;
  name: string;
  requireDob: boolean;
  requireParticipantId: boolean;
  slug: string;
  supportedLocales: string[];
  upfrontEligibilityEnforcement: EligibilityEnforcementType;
  eligibilityFileLocations: PartnerEligibilityFileLocation[];
};

export const getDisplayName = (partner?: Partner | null) =>
  partner?.configs?.DISPLAY_NAME || partner?.name;
