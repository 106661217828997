
import { Api } from "@noom/noomscape";

import { User } from "@/models";

import axios from "axios";

export type MigrateToB2BErrorResponse = {
  email: string;
};

Api.register(
  "account.migrateToB2B",
  async ({ upid, userData }: { upid: string; userData: User }) => {
    const response = await axios({
      url: "/users/migrateToB2B",
      urlWithPathParams: "/users/migrateToB2B",
      baseURL: window.ENV.SERVER_URL,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: { ...userData, upid },
    });

    return response.data;
  },
);
