import React from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  CompassColor,
  Image,
  Spacing,
  Stack,
  Text,
  useMediaQuery,
} from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { shouldAskSDOH } from "@/utils/survey/sdoh";

import { BaseInterstitial } from "../../core";

const QUESTION_ID = "socialDeterminantsOfHealth";

const SocialDeterminantsOfHealth: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
}) => {
  const [isBrowser] = useMediaQuery("(min-width: 1160px)");
  const { t } = useTranslation();

  return (
    <BaseInterstitial
      backgroundColor={CompassColor.blueberry}
      ctaText={t("socialDeterminantsOfHealth.ctaText")}
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      questionId={QUESTION_ID}
      width={isBrowser ? "600px" : undefined}
    >
      <Stack marginBottom="var(--spacing-m)" spacing={Spacing[8]}>
        <Image
          height={{ base: "48px", lg: "64px" }}
          margin="var(--spacing-l) 0px var(--spacing-s) 0px"
          src="/assets/img/diamond-tarocco.svg"
        />
        <Text
          color={CompassColor.white}
          fontFamily="Untitled Serif"
          fontSize="28px"
          fontWeight="500"
          lineHeight="36px"
        >
          {t("socialDeterminantsOfHealth.heading")}
        </Text>
        <Text color={CompassColor.white} fontSize="18px" lineHeight="26px">
          <Trans i18nKey="socialDeterminantsOfHealth.message" />
        </Text>
      </Stack>
    </BaseInterstitial>
  );
};
export const Q_SOCIAL_DETERMINANTS_OF_HEALTH: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo, selectedProgram }) =>
    shouldAskSDOH(partnerInfo, selectedProgram),
  component: SocialDeterminantsOfHealth,
};
