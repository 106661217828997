import React from "react";
import { useTranslation } from "react-i18next";

import { shouldAskSDOH } from "@/utils/survey/sdoh";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../../Question";
import { MultipleChoiceQuestion } from "../../core";

const QUESTION_ID = "nutritionBasics";

declare module "@/contexts" {
  interface SurveyAnswers {
    nutritionBasics?: "veryConfident" | "somewhatConfident" | "notConfident";
  }
}

const NutritionBasicsQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    { id: "veryConfident", text: t("nutritionBasics.options.veryConfident") },
    {
      id: "somewhatConfident",
      text: t("nutritionBasics.options.somewhatConfident"),
    },
    { id: "notConfident", text: t("nutritionBasics.options.notConfident") },
  ];

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionId={QUESTION_ID}
      questionText={t("nutritionBasics.questionText")}
      questionHelperText={t("nutritionBasics.questionHelperText")}
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_NUTRITION_BASICS: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo, selectedProgram }) => shouldAskSDOH(partnerInfo, selectedProgram),
  component: NutritionBasicsQuestion,
};
