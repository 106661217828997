import React from "react";
import { useTranslation } from "react-i18next";

import { ScreenedOutReason } from "@/constants";
import { useAppContext } from "@/contexts";
import {
  shouldShowCardiometabolicQuestionForRetriage,
  areSexAndGenderPrePopulated,
} from "@/utils/survey/retriage";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "sex";
export const SEX_QUESTION_ID = QUESTION_ID;

declare module "@/contexts" {
  interface SurveyAnswers {
    sex?: string;
  }
}

const SexQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { selectedProgram } = useAppContext();
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    { id: "male", text: t("sex.options.male") },
    { id: "female", text: t("sex.options.female") },
    { id: "intersex", text: t("sex.options.intersex") },
    { id: "preferNotToSay", text: t("sex.options.preferNotToSay") },
  ];

  const questionDescription =
    selectedProgram === "MOOD" ? "" : t("sex.questionDescription");

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionId={QUESTION_ID}
      questionDescription={questionDescription}
      questionText={t("sex.questionText")}
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_SEX: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    screenedOutReason,
    selectedProgram,
    surveyAnswers: { sex },
  }) =>
    // Hide question if the user answered in the metabolic path and was screened out.
    !(
      sex &&
      selectedProgram === "MOOD" &&
      // Should include its own ScreenedOutReason (if applicable) and any that follow this question.
      [ScreenedOutReason.PREGNANT].includes(
        screenedOutReason as ScreenedOutReason,
      )
    ),
  component: SexQuestion,
};

export const Q_RETRIAGE_SEX: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ retriageEligibility, surveyAnswers }) =>
    !areSexAndGenderPrePopulated(surveyAnswers, retriageEligibility) &&
    shouldShowCardiometabolicQuestionForRetriage(retriageEligibility),
  component: SexQuestion,
};
