import React from "react";
import { useTranslation } from "react-i18next";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "@/components/survey/Question";
import { MultipleChoiceQuestion } from "@/components/survey/questionTypes/core";
import { HealthConditionOptionId, PrimaryCareProviderSelection } from "@/constants";

const QUESTION_ID = "primaryCareProvider";

declare module "@/contexts" {
  interface SurveyAnswers {
    primaryCareProvider?: PrimaryCareProviderSelection;
  }
}

const PrimaryCareProviderQuestion: React.FC<QuestionComponentProps> = ({ onClickBack, onClickNext, surveyAnswers }) => {
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    {
      id: PrimaryCareProviderSelection.YES,
      text: t("primaryCareProvider.options.yes"),
    },
    { id: PrimaryCareProviderSelection.NO, text: t("primaryCareProvider.options.no") },
  ];

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionId={QUESTION_ID}
      questionText={t("primaryCareProvider.questionText")}
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
}

export const Q_PRIMARY_CARE_PROVIDER: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo, surveyAnswers: { healthConditions } }) =>
    !!(partnerInfo?.configs.OFFERS_DIABETES_MANAGEMENT && healthConditions?.includes(HealthConditionOptionId.TYPE2_DIABETES)),
  component: PrimaryCareProviderQuestion,
};
