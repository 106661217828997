import { ProgramOffering } from "@/constants";
import { AppContextType } from "@/contexts";

/**
 * Represents a mapping of experiment names to their assigned variations
 * Used to track which variation a user is assigned to for each experiment
 */
export type ExperimentAssignments = Record<string, string>;

export type Context = {
  partnerId: string;
  selectedProgram: AppContextType["selectedProgram"];
  partnerOfferings: ProgramOffering[];
};

/**
 * Represents an ECT (separate core product experiments) configuration
 * Contains the experiment name and variation information
 */
export type EctExperiment = {
  /** The name/identifier of the experiment */
  name: string;
  /** The specific variation assigned to this experiment */
  variation: string;
};

/**
 * Represents a variation within an experiment
 * Contains configuration for how the variation should be weighted and any mapped ECT experiments
 */
export type Variation = {
  /** The name/identifier of the variation */
  name: string;
  /** The weight/probability of this variation being assigned (used for traffic allocation) */
  weight: number;
  /** Optional nested experiments that should be triggered when this variation is assigned */
  ectExperiments?: EctExperiment[];
};

export type Operator =
  | "EQUALS" // Exact match
  | "NOT EQUALS" // Exact mismatch
  | "IN" // Context value is in the list of target values
  | "NOT IN" // Context value is not in the list of target values
  | "CONTAINS" // Context value contains the target value
  | "NOT CONTAINS"; // Context value does not contain the target value

export type TargetingValue<T extends Operator> = T extends "IN" | "NOT IN"
  ? string[]
  : string;

export type ContextValue<T extends Operator> = T extends
  | "CONTAINS"
  | "NOT CONTAINS"
  ? string[]
  : string;

/**
 * Represents a targeting rule that determines which users are eligible for an experiment
 */
export type Targeting = {
  /** The context key being targeted (e.g., partnerId, selectedProgram, partnerOfferings, etc.) */
  key: keyof Context;
} & {
  [K in Operator]: {
    /** The operator to use for comparison (equals, contains, etc.) */
    operator: K;
    /** The value to compare against */
    value: TargetingValue<K>;
  };
}[Operator];

/**
 * Represents a complete experiment configuration
 * Contains all the necessary information to run an experiment including variations and targeting rules
 */
export type Experiment = {
  /** The name/identifier of the experiment */
  name: string;
  /** List of variations available in this experiment */
  variations: Variation[];
  /** Optional targeting rules that determine which users are eligible for the experiment */
  targeting?: Targeting[];
  /** Whether this experiment should be exclusive (exclusive with other experiments) */
  isExclusive?: boolean;
};

// See experiment.example.ts for examples of how to use the Experiment type
// Selection Process:
// 1. Filters eligible experiments based on targeting rules
// 2. Select one experiment deterministically based on user ID
// 3. If the selected experiment is exclusive it will be the sole experiment the user is assigned
// 4. Else the user is assigned all eligible non-exclusive experiments
export const EXPERIMENT_CONFIG: Experiment[] = [];
