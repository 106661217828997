import React, { useEffect } from "react";

import {
  Box,
  Heading,
  Image,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import { SurveyHeader } from "@/components/SurveyHeader";
import { useTrackEvent } from "@/hooks";

import { BaseOutline } from "./BaseOutline";

type BaseQuestionProps = {
  children?: React.ReactNode;
  footer?: React.ReactNode;
  footerPosition?: "absolute" | "static";
  headerImageSrc?: string;
  hideBackButton?: boolean;
  hideHeader?: boolean;
  hideSurveyProgressBar?: boolean;
  imageSrc?: string;
  onClickBack: () => void;
  questionDescription?: string;
  questionHelperText?: string;
  questionId: string;
  questionText: string;
};

export const BaseQuestion: React.FC<BaseQuestionProps> = ({
  children,
  footer,
  footerPosition = "absolute",
  headerImageSrc,
  hideBackButton,
  hideHeader,
  hideSurveyProgressBar,
  imageSrc,
  onClickBack,
  questionDescription,
  questionId,
  questionHelperText,
  questionText,
}) => {
  const { trackAnonPageViewed } = useTrackEvent();

  useEffect(() => {
    trackAnonPageViewed(questionId);
  }, [questionId]);

  return (
    <BaseOutline>
      {!hideHeader && (
        <Box>
          <SurveyHeader
            headerImageSrc={headerImageSrc}
            hideBackButton={hideBackButton}
            hideSurveyProgressBar={hideSurveyProgressBar}
            onClickBack={onClickBack}
          />
        </Box>
      )}
      <Box margin="var(--spacing-m)">
        <Stack
          margin="auto"
          maxWidth="400px"
          spacing={Spacing[8]}
          padding={
            hideHeader
              ? {
                  lg: "var(--spacing-xxl) 0",
                }
              : undefined
          }
        >
          <Stack spacing={Spacing[4]}>
            {imageSrc && (
              <Box display="flex" justifyContent="center">
                <Image src={imageSrc} />
              </Box>
            )}
            {questionDescription && (
              <Heading fontSize="24px" fontWeight={500} lineHeight="32px">
                {questionDescription}
              </Heading>
            )}
            <Heading fontSize="24px" fontWeight={500} lineHeight="32px">
              {questionText}
            </Heading>
            {questionHelperText && <Text>{questionHelperText}</Text>}
          </Stack>
          {children}
        </Stack>
        {footer && (
          <Box
            bottom={0}
            marginTop="var(--spacing-xxxl)"
            padding={{
              base: "var(--spacing-m) 0",
              lg:
                footerPosition === "absolute"
                  ? "var(--spacing-m) 0"
                  : "var(--spacing-m) 0 0",
            }}
            position={{ md: footerPosition }}
          >
            {footer}
          </Box>
        )}
      </Box>
    </BaseOutline>
  );
};
