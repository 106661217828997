import { parse } from "date-fns";

import { CheckEligibilityRequestProto_Body } from "@noom/noom-contracts/noom_contracts/backend/partner/service/partner_eligibility_service";
import { Api } from "@noom/noomscape";

import { DOB_FORMAT } from "@/constants";
import {
  EligibilityResponse,
  EligibilityVerificationProperties,
} from "@/models";

import axios from "axios";

function generateRequestBody(
  properties: EligibilityVerificationProperties,
  partnerId: number,
  leadSource?: string,
): CheckEligibilityRequestProto_Body {
  const { dateOfBirth, ...params } = properties;
  const body: CheckEligibilityRequestProto_Body = {
    ...params,
    partnerId,
    leadSource,
  };
  if (dateOfBirth) {
    const dob = parse(dateOfBirth, DOB_FORMAT, new Date());
    body.dateOfBirth = {
      year: dob.getFullYear(),
      month: dob.getMonth() + 1,
      day: dob.getDate(),
    };
  }
  return body;
}

Api.register(
  "partner.checkEligibility",
  async ({
    eligibilityVerificationProperties,
    leadSource,
    partnerId,
  }: {
    eligibilityVerificationProperties: EligibilityVerificationProperties;
    leadSource?: string;
    partnerId: number;
  }) => {
    const response = await axios({
      url: "/partners/checkEligibility",
      urlWithPathParams: "/partners/checkEligibility",
      baseURL: window.ENV.SERVER_URL,
      data: generateRequestBody(
        eligibilityVerificationProperties,
        partnerId,
        leadSource,
      ),
      method: "POST",
    });

    return response.data as EligibilityResponse;
  },
);
