import React from "react";
import { useTranslation } from "react-i18next";

import { useAppContext } from "@/contexts";
import { convertLbToKg } from "@/utils/UnitUtils";
import { calculateBMIMetric } from "@/utils/bmi";
import { meetsMedBmiAndComorbityCriteria } from "@/utils/qualification";
import { shouldAskBaselineOutcomes } from "@/utils/survey/outcomes";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "glp1Medication";

const GLP1_OPTION_IDS = [
  "yes",
  "prescriptionNotYetStarted",
  "noButConsidering",
  "no",
] as const;

type Glp1MedicationAnswer = (typeof GLP1_OPTION_IDS)[number];

const RETRIAGE_GLP1_OPTION_IDS: Glp1MedicationAnswer[] = ["yes", "no"];

declare module "@/contexts" {
  interface SurveyAnswers {
    glp1Medication?: Glp1MedicationAnswer;
  }
}

const Glp1MedicationQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { enrollmentType } = useAppContext();
  const { t } = useTranslation();

  const optionIds =
    enrollmentType === "RETRIAGE" ? RETRIAGE_GLP1_OPTION_IDS : GLP1_OPTION_IDS;

  const options: QuestionOption[] = optionIds.map((id) => ({
    id,
    text: t(`glp1Medication.options.${id}`),
  }));

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionId={QUESTION_ID}
      questionText={t("glp1Medication.questionText")}
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_GLP1_MEDICATION: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo, selectedProgram }) =>
    selectedProgram !== "MOOD" && shouldAskBaselineOutcomes(partnerInfo),
  component: Glp1MedicationQuestion,
};

export const Q_OUTCOMES_GLP1_MEDICATION: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    surveyAnswers: { weight: weightInLbs },
    userProfile,
  }) => {
    const { currentProgram, heightCms, latestWeightKgs } = userProfile || {};

    // Only show for cardiometabolic programs
    if (currentProgram === "MOOD") {
      return false;
    }

    // Only show for BMI >= 27

    // Use BMI obtained from user profile & latest weigh-in if it is available
    if (heightCms !== undefined && latestWeightKgs !== undefined) {
      return calculateBMIMetric(heightCms, latestWeightKgs) >= 27;
    }

    // Fallback to BMI obtained from height on user profile (if available) and weight from survey
    if (heightCms !== undefined && weightInLbs !== undefined) {
      return (
        calculateBMIMetric(
          heightCms,
          convertLbToKg({
            mainUnitValue: weightInLbs,
          }).mainUnitValue,
        ) >= 27
      );
    }

    // If we weren't able to calculate BMI for some reason default to showing question.
    return true;
  },

  component: Glp1MedicationQuestion,
};

export const Q_RETRIAGE_GLP1_MEDICATION: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    retriageEligibility: { eligibleRetriagePrograms = [] } = {},
    surveyAnswers: {
      healthConditions,
      heightWeight: { heightFeetPortion, heightInchesPortion, weight } = {},
    },
  }) => {
    // This question differs from Med specific questions in that it only
    // requires not meeting BMI and comorbidity criteria
    if (!eligibleRetriagePrograms.includes("MED")) {
      return false;
    }

    if (
      heightFeetPortion === undefined ||
      heightInchesPortion === undefined ||
      weight === undefined
    ) {
      return false;
    }

    return !meetsMedBmiAndComorbityCriteria(
      healthConditions,
      heightFeetPortion,
      heightInchesPortion,
      weight,
    );
  },
  component: Glp1MedicationQuestion,
};
