import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  Button,
  Checkbox,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import { PhoneInput, PhoneInputSchema, PhoneNumber } from "@/components/form";
import {
  AddressForm,
  AddressSchema,
  buildAddressSchema,
} from "@/components/forms";
import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { DEFAULT_ADDRESS } from "@/constants";
import { useAppContext } from "@/contexts";
import { Address } from "@/models";

import { BaseQuestion } from "../core";

const QUESTION_ID = "medSignup";

export type FormValues = {
  address: Address;
  sameShippingAddress?: boolean;
  shippingAddress?: Address;
  phoneNumber: PhoneNumber;
};

declare module "@/contexts" {
  interface SurveyAnswers {
    medSignup?: Omit<FormValues, "sameShippingAddress">;
  }
}

const getValidationSchema = () =>
  Yup.object({
    address: AddressSchema,
    sameShippingAddress: Yup.boolean(),
    phoneNumber: PhoneInputSchema,
    shippingAddress: buildAddressSchema("shippingAddress").when(
      "sameShippingAddress",
      {
        is: true,
        then: () => Yup.mixed().notRequired(),
        otherwise: (s) => s.required(),
      },
    ),
  });

const RetriageMedSignup: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { eligibilityInfo, selectedProgram } = useAppContext();
  const { t } = useTranslation();

  const defaultInitialValues: FormValues = {
    address: eligibilityInfo?.address || DEFAULT_ADDRESS,
    phoneNumber: {
      country: "us",
      dialCode: "1",
      value: eligibilityInfo?.phoneNumber || "",
    },
    sameShippingAddress: true,
    shippingAddress: eligibilityInfo?.address || DEFAULT_ADDRESS,
  };

  const onSubmit = (values: FormValues) => {
    const { sameShippingAddress, ...answers } = values;
    if (sameShippingAddress) {
      delete answers.shippingAddress;
    }

    onClickNext(answers);
  };

  return (
    <BaseQuestion
      onClickBack={onClickBack}
      questionId={QUESTION_ID}
      questionText={t("retriageMedSignup.questionText")}
    >
      <Formik
        initialValues={{
          ...defaultInitialValues,
          ...surveyAnswers[QUESTION_ID],
        }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={getValidationSchema()}
      >
        {({ getFieldProps, handleSubmit, isSubmitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Stack spacing={Spacing[8]} pb={Spacing[8]}>
              <PhoneInput
                focusPhoneNumber
                label={t("form.phoneNumber.label")}
                name="phoneNumber"
                placeholder={t("form.phoneNumber.placeholder")}
              />
              <AddressForm
                countryCode={values.address.country}
                countrySelectDisabled={
                  selectedProgram === "MED" || selectedProgram === "NMPBA"
                }
                hideTaxRegHelper
              />
              {surveyAnswers.scaleOptIn && (
                <Checkbox
                  {...getFieldProps("sameShippingAddress")}
                  isChecked={values.sameShippingAddress === true}
                >
                  {t("retriageMedSignup.sameShippingAddress")}
                </Checkbox>
              )}
              {surveyAnswers.scaleOptIn && !values.sameShippingAddress && (
                <Stack spacing={Spacing[4]}>
                  <Text fontWeight={500}>
                    {t("retriageMedSignup.differentShippingAddress")}
                  </Text>
                  <AddressForm
                    formName="shippingAddress"
                    countryCode={values.shippingAddress?.country || ""}
                    countrySelectDisabled={
                      selectedProgram === "MED" || selectedProgram === "NMPBA"
                    }
                  />
                </Stack>
              )}
              <Button
                colorScheme="primary"
                isLoading={isSubmitting}
                size="xl"
                type="submit"
              >
                {t("retriageMedSignup.cta")}
              </Button>
            </Stack>
          </form>
        )}
      </Formik>
    </BaseQuestion>
  );
};
export const Q_RETRIAGE_MED_SIGNUP: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ selectedProgram }) =>
    selectedProgram === "MED" || selectedProgram === "NMPBA",
  component: RetriageMedSignup,
};
