import React from "react";
import { useTranslation } from "react-i18next";

import { shouldShowWomensHealthQuestions } from "@/utils/survey/womensHealth";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "menstrualCycle";

declare module "@/contexts" {
  interface SurveyAnswers {
    menstrualCycle?: string;
  }
}

const MenstrualCycleQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    {
      id: "yes",
      text: t("menstrualCycle.options.yes"),
    },
    { id: "no", text: t("menstrualCycle.options.no") },
    { id: "preferNotToSay", text: t("menstrualCycle.options.preferNotToSay") },
  ];
  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionId={QUESTION_ID}
      questionHelperText={t("menstrualCycle.questionHelperText")}
      questionText={t("menstrualCycle.questionText")}
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_MENSTRUAL_CYCLE: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: (state) =>
    shouldShowWomensHealthQuestions(state) &&
    (state.surveyAnswers.menopause === "noSymptoms" ||
      state.surveyAnswers.menopause === "perimenopause"),
  component: MenstrualCycleQuestion,
};
