import React from "react";
import { useTranslation } from "react-i18next";

import { shouldShowWomensHealthQuestions } from "@/utils/survey/womensHealth";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "menopause";
const MENOPAUSE_ANSWERS = [
  "noSymptoms",
  "perimenopause",
  "menopause",
  "postMenopause",
  "preferNotToSay",
] as const;
export type MenopauseAnswer = (typeof MENOPAUSE_ANSWERS)[number];

declare module "@/contexts" {
  interface SurveyAnswers {
    menopause?: MenopauseAnswer;
  }
}

const MenopauseQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const options: QuestionOption[] = MENOPAUSE_ANSWERS.map((id) => ({
    id,
    text: t(`menopause.options.${id}`),
  }));

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionId={QUESTION_ID}
      questionHelperText={t("menopause.questionHelperText")}
      questionText={t("menopause.questionText")}
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_MENOPAUSE: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: (state) => shouldShowWomensHealthQuestions(state),
  component: MenopauseQuestion,
};
