import React from "react";
import { useTranslation } from "react-i18next";

import { qualifiesForNoomMed } from "@/utils/qualification";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "familyHistoryWeight";

declare module "@/contexts" {
  interface SurveyAnswers {
    familyHistoryWeight?: string;
  }
}

const FamilyHistoryWeightQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    { id: "yes", text: t("familyHistoryWeight.options.yes") },
    { id: "no", text: t("familyHistoryWeight.options.no") },
  ];

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionId={QUESTION_ID}
      questionText={t("familyHistoryWeight.questionText")}
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_FAMILY_HISTORY_WEIGHT: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: (state) =>
    state.selectedProgram !== "MOOD" && qualifiesForNoomMed(state),
  component: FamilyHistoryWeightQuestion,
};
