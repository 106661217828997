import React from "react";
import { useTranslation } from "react-i18next";

import { HealthConditionOptionId } from "@/constants";
import { SurveyAnswer, useAppContext } from "@/contexts";
import { isB2CPartner } from "@/utils/partners";
import { shouldAskQuarterlyQuestion } from "@/utils/survey/outcomes";
import { shouldShowCardiometabolicQuestionForRetriage } from "@/utils/survey/retriage";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { CheckboxQuestion } from "../core";

const QUESTION_ID = "healthConditions";

declare module "@/contexts" {
  interface SurveyAnswers {
    healthConditions?: string[];
  }
}

const HealthConditionsQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { surveyMetadata, userProfile } = useAppContext();
  const { t } = useTranslation();

  const isMale =
    userProfile?.gender === "MALE" || surveyAnswers?.sex === "male";

  const questionText =
    surveyMetadata?.name === "RECURRING_OUTCOMES"
      ? t("healthConditions.questionText.recurringOutcomes")
      : t("healthConditions.questionText.enrollment");

  const options: (Omit<QuestionOption, "id"> & {
    id: HealthConditionOptionId;
  })[] = [
    {
      id: HealthConditionOptionId.HIGH_BLOOD_PRESSURE,
      text: t("healthConditions.options.highBloodPressure"),
    },
    {
      id: HealthConditionOptionId.TYPE1_DIABETES,
      text: t("healthConditions.options.type1Diabetes"),
    },
    {
      id: HealthConditionOptionId.TYPE2_DIABETES,
      text: t("healthConditions.options.type2Diabetes"),
    },
    {
      id: HealthConditionOptionId.DEPRESSION_OR_ANXIETY,
      text: t("healthConditions.options.depressionOrAnxiety"),
    },
    {
      id: HealthConditionOptionId.ABNORMAL_CHOLESTEROL,
      text: t("healthConditions.options.abnormalCholesterol"),
    },
    {
      id: HealthConditionOptionId.OSTEOARTHRITIS,
      text: t("healthConditions.options.osteoarthritis"),
    },
    {
      id: HealthConditionOptionId.SLEEP_PROBLEMS,
      text: t("healthConditions.options.sleepApneaOrSnoring"),
    },
    {
      id: HealthConditionOptionId.LIVER_DISEASE,
      text: t("healthConditions.options.liverDisease"),
    },
    {
      id: HealthConditionOptionId.HEART_DISEASE,
      text: t("healthConditions.options.heartDisease"),
    },
    isMale
      ? {
          id: HealthConditionOptionId.LOW_TESTOSTERONE,
          text: t("healthConditions.options.lowTestosterone"),
        }
      : {
          id: HealthConditionOptionId.PCOS_OR_FERTILITY_PROBLEMS,
          text: t("healthConditions.options.pcosOrFertilityProblems"),
        },
    {
      id: HealthConditionOptionId.CANCER_UNDER_TREATMENT,
      text: t("healthConditions.options.cancerUnderTreatment"),
    },
    {
      id: HealthConditionOptionId.NONE,
      text: t("healthConditions.options.none"),
    },
  ];

  const screenOutOrProceed = async (answer: SurveyAnswer) => {
    const dppIneligibleConditions = [
      HealthConditionOptionId.TYPE1_DIABETES,
      HealthConditionOptionId.TYPE2_DIABETES,
    ];

    const healthConditions = answer as HealthConditionOptionId[];

    if (healthConditions.some((hc) => dppIneligibleConditions.includes(hc))) {
      onClickNext(answer, {
        dppEligible: false,
      });
    } else {
      onClickNext(answer);
    }
  };

  return (
    <CheckboxQuestion
      onClickBack={onClickBack}
      onClickNext={screenOutOrProceed}
      options={options}
      questionId={QUESTION_ID}
      questionText={questionText}
      selectedItems={surveyAnswers[QUESTION_ID] || []}
    />
  );
};

export const Q_HEALTH_CONDITIONS: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo }) => !isB2CPartner(partnerInfo),
  component: HealthConditionsQuestion,
};

export const Q_OUTCOMES_HEALTH_CONDITIONS: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ userProfile }) =>
    shouldAskQuarterlyQuestion(userProfile),
  component: HealthConditionsQuestion,
};

export const Q_RETRIAGE_HEALTH_CONDITIONS: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ retriageEligibility }) =>
    shouldShowCardiometabolicQuestionForRetriage(retriageEligibility),
  component: HealthConditionsQuestion,
};
