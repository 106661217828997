import React from "react";
import { useTranslation } from "react-i18next";

import { CompassColor, Heading } from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { NoomMedMoreOptionsSelection } from "@/constants";

import { BaseInterstitial } from "../../core";

const QUESTION_ID = "continueProgram";

const ContinueProgram: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
}) => {
  const { t } = useTranslation();

  return (
    <BaseInterstitial
      autoAdvance
      autoAdvanceMs={2500}
      backgroundColor={CompassColor.sky}
      centerChildren
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      questionId={QUESTION_ID}
      width="600px"
    >
      <Heading
        fontSize="24px"
        fontWeight={500}
        lineHeight="32px"
        textAlign="center"
      >
        {t("continueProgram.message")}
      </Heading>
    </BaseInterstitial>
  );
};

export const Q_CONTINUE_PROGRAM: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    surveyAnswers: { dppEligible, noomMedMoreOptions },
  }) =>
    noomMedMoreOptions === NoomMedMoreOptionsSelection.OTHER &&
    dppEligible !== false,
  component: ContinueProgram,
};
