import { MigrateUserRequestProto_Body } from "@noom/noom-contracts/noom_contracts/backend/retriage/retriage_service";
import { Api } from "@noom/noomscape";

import axios from "axios";

Api.register(
  "retriage.migrateUser",
  async (request: MigrateUserRequestProto_Body) => {
    await axios({
      url: "/retriage/migrateUser",
      urlWithPathParams: "/retriage/migrateUser",
      baseURL: window.ENV.SERVER_URL,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: request,
    });
  },
);
