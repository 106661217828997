import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  CompassColor,
  Heading,
  Image,
  Spacing,
  Stack,
  Text,
  useMediaQuery,
} from "@noom/wax-component-library";

import { NAMESPACES } from "@/app/i18n";
import { ASSET_URL } from "@/constants";

type ValueProp = {
  alt: string;
  captionKey: string;
  url: string;
};

const STATIC_CONTENT_IMAGES: ValueProp[] = [
  {
    alt: "Hike",
    captionKey: "enrollmentStaticContent.valueProp1",
    url: "/assets/img/hike.png",
  },
  {
    alt: "Swim",
    captionKey: "enrollmentStaticContent.valueProp2",
    url: "/assets/img/swim.png",
  },
  {
    alt: "Stretch",
    captionKey: "enrollmentStaticContent.valueProp3",
    url: "/assets/img/stretch.png",
  },
];

type EnrollmentStaticContentProps = {
  employerName?: string;
  employerSlug?: string;
  showPricePill: boolean;
  showOriginalLogo?: boolean;
  isLoading: boolean;
};

export const EnrollmentStaticContent: React.FC<
  EnrollmentStaticContentProps
> = ({
  employerName,
  employerSlug,
  showPricePill,
  showOriginalLogo,
  isLoading,
}) => {
  const [isBrowser] = useMediaQuery("(min-width: 1160px)");
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const { t } = useTranslation(NAMESPACES.employerLanding);

  const imageUrl = `${ASSET_URL}/img/${employerSlug}/logo.png`;

  const showWhiteLogo = employerSlug && !showOriginalLogo;

  return (
    <Box color={CompassColor.white}>
      <Stack
        alignItems="center"
        height="36px"
        isInline
        justifyContent="space-between"
      >
        <Box display="flex" gap="var(--spacing-m)">
          <Image
            alt="Noom"
            height="24px"
            src="/assets/img/noom-logo-white.svg"
          />
          {employerSlug && !isLoading && (
            <>
              <Box
                backgroundColor={CompassColor.white}
                height="24px"
                width="2px"
                display={isImageLoaded ? "block" : "none"}
              />
              <Image
                alt={employerName}
                src={imageUrl}
                onLoad={() => setIsImageLoaded(true)}
                height="24px"
                filter={showWhiteLogo ? "brightness(0) invert(1)" : "none"}
                opacity={isImageLoaded ? 1 : 0}
                transition="opacity 0.1s ease-in-out"
              />
            </>
          )}
        </Box>
        {showPricePill && isBrowser && (
          <Box
            backgroundColor={CompassColor.black}
            borderRadius="18px"
            display="flex"
            fontFamily="Brown LL"
            fontSize="20px"
            gap="var(--spacing-s)"
            lineHeight="26px"
            padding="var(--spacing-xs) var(--spacing-s)"
            textTransform="uppercase"
          >
            <Text color={CompassColor.grey2} textDecoration="line-through">
              $199
            </Text>
            <Text color={CompassColor.white} fontWeight={500}>
              {t("enrollmentStaticContent.free")}
            </Text>
          </Box>
        )}
      </Stack>
      <Text
        as="p"
        color={CompassColor.sand02}
        fontFamily="Brown LL"
        fontSize="18px"
        fontWeight={500}
        lineHeight="21px"
        marginTop={{ base: "var(--spacing-l)", lg: "var(--spacing-xxxh)" }}
        textTransform="uppercase"
      >
        {t("enrollmentStaticContent.heading")}
      </Text>
      <Heading
        fontSize={{ base: "24px", lg: "40px" }}
        fontWeight={500}
        lineHeight={{ base: "32px", lg: "50px" }}
        marginBottom={{ base: "var(--spacing-xl)", lg: "var(--spacing-h)" }}
        marginTop={{ base: "var(--spacing-s)", lg: "var(--spacing-m)" }}
      >
        {t("enrollmentStaticContent.description")}
      </Heading>
      {isBrowser && (
        <Stack isInline spacing={Spacing[8]}>
          {STATIC_CONTENT_IMAGES.map(({ alt, captionKey, url }) => (
            <Stack key={url} maxWidth="192px" spacing={Spacing[4]}>
              <Image alt={alt} src={url} width="96px" />
              <Box fontFamily="Untitled Sans" fontSize="20px" lineHeight="28px">
                <Trans
                  components={{
                    boldText: (
                      <Text color={CompassColor.white} fontWeight={500} />
                    ),
                  }}
                  i18nKey={captionKey}
                  ns={NAMESPACES.employerLanding}
                />
              </Box>
            </Stack>
          ))}
        </Stack>
      )}
    </Box>
  );
};
