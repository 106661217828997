import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { CheckboxOption } from "@/components/survey/checkbox";
import { CoursePackSlug } from "@/constants";
import { SurveyAnswer, useAppContext } from "@/contexts";
import { useTrackEvent } from "@/hooks";
import {
  getRecommendedCoursePacks,
  shouldShowPersonalizationFlow,
} from "@/utils/survey/contentPersonalization";

import { QuestionComponentProps, QuestionDefinition } from "../../Question";
import { CheckboxQuestion } from "../core";

const QUESTION_ID = "coursePackSelection";

declare module "@/contexts" {
  interface SurveyAnswers {
    coursePackSelection?: CoursePackSlug[];
  }
}

const CoursePackSelectionQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { selectedProgram } = useAppContext();
  const { trackAnonEvent } = useTrackEvent();
  const { t } = useTranslation();

  const recommendedCoursePacks = getRecommendedCoursePacks(
    selectedProgram,
    surveyAnswers,
  );

  const options: (Omit<CheckboxOption, "id"> & {
    id: CoursePackSlug;
  })[] = [
    {
      id: "AGING_AND_METABOLISM",
      text: t("coursePackSelection.options.agingAndMetabolism.text"),
      subtext: t("coursePackSelection.options.agingAndMetabolism.subtext"),
    },

    {
      id: "NW_PLUS",
      text: t("coursePackSelection.options.stressEating.text"),
      subtext: t("coursePackSelection.options.stressEating.subtext"),
    },

    {
      id: "SLEEP",
      text: t("coursePackSelection.options.sleep.text"),
      subtext: t("coursePackSelection.options.sleep.subtext"),
    },

    {
      id: "GLP1",
      text: t("coursePackSelection.options.glp1.text"),
      subtext: t("coursePackSelection.options.glp1.subtext"),
    },
  ];

  const trackSelectedCoursePacksAndProceed = async (answer: SurveyAnswer) => {
    const selectedCoursePacks = answer as CoursePackSlug[];

    trackAnonEvent("b2bEnrollmentAnonCoursePacksSelected", {
      recommendedCoursePacks,
      selectedCoursePacks,
    });

    onClickNext(selectedCoursePacks);
  };

  useEffect(() => {
    trackAnonEvent("b2bEnrollmentAnonCoursePacksRecommended", {
      recommendedCoursePacks,
    });
  }, []);

  return (
    <CheckboxQuestion
      canProceedWithoutAnswer
      onClickBack={onClickBack}
      onClickNext={trackSelectedCoursePacksAndProceed}
      options={options.filter((option) =>
        recommendedCoursePacks.includes(option.id),
      )}
      hiddenOptions={options.filter(
        (option) => !recommendedCoursePacks.includes(option.id),
      )}
      questionHelperText={t("coursePackSelection.questionHelperText")}
      questionId={QUESTION_ID}
      questionText={t("coursePackSelection.questionText")}
      selectedItems={surveyAnswers[QUESTION_ID] || recommendedCoursePacks}
    />
  );
};

export const Q_COURSE_PACK_SELECTION: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo, selectedProgram, surveyAnswers }) =>
    shouldShowPersonalizationFlow(partnerInfo, selectedProgram, surveyAnswers),
  component: CoursePackSelectionQuestion,
};
