import React from "react";
import { useTranslation } from "react-i18next";

import { useAppContext } from "@/contexts";
import { shouldAskBaselineOutcomes } from "@/utils/survey/outcomes";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { CheckboxQuestion } from "../core";

const QUESTION_ID = "mskPain";

declare module "@/contexts" {
  interface SurveyAnswers {
    mskPain?: string[];
  }
}

const MskPainQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { surveyMetadata } = useAppContext();
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    { id: "knee", text: t("mskPain.options.knee") },
    { id: "hip", text: t("mskPain.options.hip") },
    {
      id: "lowerBack",
      text: t("mskPain.options.lowerBack"),
    },
    {
      id: "upperBack",
      text: t("mskPain.options.upperBack"),
    },
    { id: "none", text: t("mskPain.options.none") },
  ];

  const questionText =
    surveyMetadata?.name === "RECURRING_OUTCOMES"
      ? t("mskPain.questionText.recurringOutcomes")
      : t("mskPain.questionText.enrollment");

  return (
    <CheckboxQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionHelperText={t("mskPain.questionHelperText")}
      questionId={QUESTION_ID}
      questionText={questionText}
      selectedItems={surveyAnswers[QUESTION_ID] || []}
    />
  );
};

export const Q_MSK_PAIN: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo, selectedProgram }) =>
    selectedProgram !== "MOOD" && shouldAskBaselineOutcomes(partnerInfo),
  component: MskPainQuestion,
};

export const Q_OUTCOMES_MSK_PAIN: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ userProfile }) =>
    userProfile?.currentProgram !== "MOOD",
  component: MskPainQuestion,
};
