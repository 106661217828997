import React from "react";

import { Box, CompassColor, Image, Text } from "@noom/wax-component-library";

import { useSurveyProgressBar } from "@/hooks";

import { SurveyProgressBar } from "./survey-progress-bar/SurveyProgressBar";

type SurveyHeaderProps = {
  headerImageSrc?: string;
  hideBackButton?: boolean;
  hideSurveyProgressBar?: boolean;
  onClickBack: () => void;
};

export const SurveyHeader: React.FC<SurveyHeaderProps> = ({
  headerImageSrc,
  hideBackButton,
  hideSurveyProgressBar,
  onClickBack,
}) => {
  const {
    currentProgress,
    currentSectionDescription,
    currentSectionIndex,
    lastProgress,
    percentages,
  } = useSurveyProgressBar();

  return (
    <Box>
      <Box
        display="grid"
        gridTemplateColumns={
          hideBackButton ? "none" : "var(--spacing-l) auto var(--spacing-l)"
        }
        margin="var(--spacing-m)"
        position={headerImageSrc ? "absolute" : "static"}
      >
        {!hideBackButton && (
          <button type="button" tabIndex={-1}>
            <Image
              alt="Back"
              cursor="pointer"
              onClick={onClickBack}
              src="/assets/img/left-arrow.svg"
            />
          </button>
        )}
        {!hideSurveyProgressBar && (
          <Box
            display="flex"
            flexDirection="column"
            justifySelf="center"
            width="100%"
          >
            <Text
              color={CompassColor.lagoon}
              display="flex"
              fontFamily="Brown LL"
              fontSize="14px"
              fontStyle="normal"
              fontWeight={500}
              justifyContent="center"
              letterSpacing="0.03em"
              lineHeight="21px"
              textTransform="uppercase"
            >
              {currentSectionDescription}
            </Text>
          </Box>
        )}
      </Box>
      {!hideSurveyProgressBar && (
        <Box margin="var(--spacing-m)">
          <SurveyProgressBar
            currentProgress={currentProgress}
            currentSectionIndex={currentSectionIndex}
            lastProgress={lastProgress}
            percentages={percentages}
          />
        </Box>
      )}
      {headerImageSrc && (
        <Box display="flex" justifyContent="center">
          <Image src={headerImageSrc} width="100%" />
        </Box>
      )}
    </Box>
  );
};
