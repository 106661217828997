
import { LibCategories, waitForConsent } from "@/utils/consent";
import {
  shouldApplyLDUFlag,
  FacebookPixelParameters,
} from "@/utils/pixels/publishers/facebook";

import axios from "axios";

type ActionSource = "website" | "email";
type DataProcessingOption = "LDU";

interface FBCapiUserData {
  external_id?: string;
  email?: string;
  phone?: string;
}

interface FBCapiCustomData {
  content_category?: string;
  content_ids?: string;
  content_type?: string;
  value?: number;
  currency?: string;
  order_id?: string;
  custom_properties?: Record<string, any>;
}

interface FBCapiEventData {
  event_id: string;
  event_name: string;
  event_source_url: string;
  pixel_id: string;
  action_source: ActionSource;
  user_data: FBCapiUserData;
  custom_data: FBCapiCustomData;

  data_processing_options?: DataProcessingOption[];
  data_processing_options_country?: number;
  data_processing_options_state?: number;
  opt_out?: boolean;

  // Used only for debugging/testing
  test_event_code?: string;
}

interface FBCapiPayload extends FacebookPixelParameters {
  eventID: string;
  customProperties?: Record<string, any>;
  email?: string;
  phone?: string;
}

/**
 * Extracts the value for testEventCode URL query param will be send further to the CAPI server
 * Only used for testing
 */
function getTestEventCodeUrlParameter() {
  const urlParams = new URLSearchParams(window.location.search);
  const testEventCode = urlParams.get("testEventCode");

  return testEventCode || null;
}

export function fireFacebookCapi(
  pixelId: string,
  eventName: string,
  payload: FBCapiPayload,
) {
  const userData: FBCapiUserData = {
    external_id: payload.external_id,
    email: payload.email,
    phone: payload.phone,
  };

  const customData: FBCapiCustomData = {
    value: payload.value,
    currency: payload.currency,
    custom_properties: payload.customProperties,
  };

  const eventData: FBCapiEventData = {
    event_id: payload.eventID,
    event_name: eventName,
    event_source_url: window.location.href,
    action_source: "website",
    user_data: userData,
    custom_data: customData,
    pixel_id: pixelId,
  };

  if (shouldApplyLDUFlag()) {
    Object.assign(eventData, {
      data_processing_options: ["LDU"],
      data_processing_options_country: 1,
      data_processing_options_state: 1000,
      opt_out: true,
    });
  }

  const testEventCode = getTestEventCodeUrlParameter();
  if (testEventCode) {
    Object.assign(eventData, {
      test_event_code: testEventCode,
    });
  }

  return waitForConsent([LibCategories.targetingCookies]).then(() =>
    axios({
      method: "POST",
      baseURL: window.ENV.GROWTH_API_DOMAIN,
      url: "/pixel/v1/i/facebookCapiPixel/",
      urlWithPathParams: "/pixel/v1/i/facebookCapiPixel/",
      data: eventData,
    }),
  );
}
