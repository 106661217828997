import React, { useCallback, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { ScreenedOutReason } from "@/constants";
import { SurveyAnswer, SurveyAnswers, useAppContext } from "@/contexts";
import {
  useAnswerSurveyQuestion,
  useExperiment,
  useGoto,
  useTrackEvent,
} from "@/hooks";

import { QuestionSection } from "./Question";

type SurveyProps = {
  baseRoute: string;
  onSurveyComplete: (surveyAnswers: SurveyAnswers) => Promise<void>;
  questionSet: QuestionSection[];
  resetOnOpen?: boolean;
};

export const Survey: React.FC<SurveyProps> = ({
  baseRoute,
  onSurveyComplete,
  questionSet,
  resetOnOpen = true,
}) => {
  const { answerSurveyQuestion, getNewSurveyAnswersState } =
    useAnswerSurveyQuestion();
  const {
    enrollmentInfo,
    hasB2cAccount,
    partnerInfo,
    retriageEligibility,
    screenedOutReason,
    selectedProgram,
    surveyAnswers,
    surveyMetadata,
    telehealthEligibleStates,
    userProfile,
    setQuestionSet,
    setSurveyAnswers,
  } = useAppContext();
  const { getExperimentAssignments } = useExperiment();
  const { screenOut } = useGoto();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { trackSurveyAnonEvent, trackSurveyIdentifiedEvent } = useTrackEvent();

  const questions = questionSet.flatMap(
    (questionSection) => questionSection.questions,
  );

  const getQuestionIdFromPath = useCallback(
    () => pathname.split("/").pop() || "",
    [pathname],
  );

  const pushHistoryPreserveQuerystring = (newPathName: string) => {
    navigate({
      pathname: newPathName,
      search,
    });
  };

  // TODO: Remove newState as an argument - it was added as a hack because
  // state updates occur asynchronously
  const findNextQuestion = (startingIndex: number, newState: SurveyAnswers) => {
    const updatedProgram =
      newState?.updatedProgram === undefined
        ? selectedProgram
        : newState?.updatedProgram;

    return questions.find(
      (question, index) =>
        index >= startingIndex &&
        question.shouldShowQuestion({
          enrollmentInfo,
          experiments: getExperimentAssignments(),
          hasB2cAccount,
          partnerInfo,
          retriageEligibility,
          screenedOutReason,
          selectedProgram: updatedProgram,
          surveyAnswers: newState,
          telehealthEligibleStates,
          userProfile,
        }),
    );
  };

  const goToFirstQuestion = () => {
    const firstQuestion = findNextQuestion(0, surveyAnswers);

    if (!firstQuestion) {
      return;
    }

    navigate(
      {
        pathname: `${baseRoute}/${firstQuestion.id}`,
        search,
      },
      { replace: true },
    );
  };

  const getQuestionIndexById = (questionId: string): number =>
    questions.findIndex((question) => question.id === questionId);

  /**
   *
   * @param answer to be stored at surveyAnswers[questionId]
   * @param additionalAnswers to be stored at their specified key
   */
  const onClickNext = async (
    answer: SurveyAnswer,
    additionalAnswers?: Record<string, SurveyAnswer>,
    reason?: ScreenedOutReason,
  ) => {
    const questionId = getQuestionIdFromPath();

    const newSurveyAnswers = getNewSurveyAnswersState(
      questionId,
      answer,
      additionalAnswers,
    );
    await answerSurveyQuestion(questionId, answer, additionalAnswers);

    const questionIndex = getQuestionIndexById(questionId);
    const nextQuestion = findNextQuestion(questionIndex + 1, newSurveyAnswers);

    if (reason) {
      screenOut(reason);
      return;
    }

    if (!nextQuestion) {
      if (surveyMetadata) {
        trackSurveyAnonEvent("b2bSurveyAnonSurveyCompleted", {});
        trackSurveyIdentifiedEvent("b2bSurveyIdentifiedSurveyCompleted", {});
      }
      await onSurveyComplete(newSurveyAnswers);
    } else {
      const nextQuestionPath = pathname.replace(questionId, nextQuestion.id);
      pushHistoryPreserveQuerystring(nextQuestionPath);
    }
  };

  const onClickBack = () => {
    /**
     * This clears the answer for the question before going back so we don't
     * store answers that may not be relevant after changing previous answers.
     *
     * Examples:
     * - pregnant question will be skipped after going back and changing sex
     *   from female to male
     * - locationState question will be skipped after going back and proceeding
     *   again if a Noom Med ineligible state is selected the first time
     */
    const newSurveyAnswers = { ...surveyAnswers };
    delete newSurveyAnswers[getQuestionIdFromPath()];
    setSurveyAnswers(newSurveyAnswers);

    navigate(-1);
  };

  useEffect(() => {
    if (resetOnOpen) {
      goToFirstQuestion();

      if (surveyMetadata) {
        trackSurveyAnonEvent("b2bSurveyAnonSurveyStarted", {});
        trackSurveyIdentifiedEvent("b2bSurveyIdentifiedSurveyStarted", {});
      }
    }
  }, []);

  useEffect(() => {
    setQuestionSet(questionSet);
  }, [questionSet]);

  return (
    <Routes>
      {questions.map((question) => {
        const { id: questionId, component: Component } = question;
        return (
          <Route
            key={questionId}
            path={questionId}
            element={
              <Component
                onClickBack={onClickBack}
                onClickNext={onClickNext}
                surveyAnswers={surveyAnswers}
              />
            }
          />
        );
      })}
    </Routes>
  );
};
