import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Button, Spacing, Stack, Text } from "@noom/wax-component-library";

import { InputWithSuffix } from "@/components/form";
import { enforcePositiveFloat } from "@/components/form/utils";
import { shouldAskQuarterlyQuestion } from "@/utils/survey/outcomes";
import { shouldShowDppQualification } from "@/utils/survey/retriage";

import { QuestionComponentProps, QuestionDefinition } from "../../Question";
import { BaseQuestion } from "../core";

const QUESTION_ID = "a1c";

declare module "@/contexts" {
  interface SurveyAnswers {
    a1c?: number;
  }
}

type A1cFormValues = {
  a1c?: number;
};

const A1cQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const initialValues: A1cFormValues = {
    a1c: surveyAnswers.a1c,
  };

  const validationSchema = Yup.object({
    a1c: Yup.number()
      .required(t("a1c.form.a1c.errors.required"))
      .min(3, t("a1c.form.a1c.errors.invalid"))
      .max(20, t("a1c.form.a1c.errors.invalid")),
  });

  return (
    <BaseQuestion
      onClickBack={onClickBack}
      questionId={QUESTION_ID}
      questionText={t("a1c.questionText")}
    >
      <Formik
        initialValues={initialValues}
        // If a1c is submitted we expect it to be a number due to the
        // validation schema
        onSubmit={({ a1c }: A1cFormValues) => onClickNext(a1c!)}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({ errors, getFieldProps, handleSubmit, touched }) => (
          <Form onSubmit={handleSubmit}>
            <Stack spacing={Spacing[8]}>
              <InputWithSuffix
                {...getFieldProps("a1c")}
                aria-label="a1c portion"
                autoFocus
                error={touched.a1c && errors.a1c}
                onKeyDown={enforcePositiveFloat}
                step="0.1"
                suffix="%"
                type="number"
              />
              <Button colorScheme="primary" size="xl" type="submit">
                {t("a1c.ctaText.next")}
              </Button>
              <Button
                variant="unstyled"
                w="100%"
                onClick={() => onClickNext(undefined)}
              >
                <Text textDecoration="underline">
                  {t("a1c.ctaText.forgot")}
                </Text>
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </BaseQuestion>
  );
};

export const Q_A1C: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ selectedProgram, surveyAnswers: { hadA1cTested } }) =>
    selectedProgram !== "MOOD" && hadA1cTested === "yes",
  component: A1cQuestion,
};

export const Q_OUTCOMES_A1C: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ surveyAnswers: { hadA1cTested }, userProfile }) =>
    userProfile?.currentProgram !== "MOOD" &&
    hadA1cTested === "yes" &&
    shouldAskQuarterlyQuestion(userProfile),
  component: A1cQuestion,
};

export const Q_RETRIAGE_A1C: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: (state) => {
    const {
      surveyAnswers: { prediabetesHistory },
    } = state;
    return (
      shouldShowDppQualification(state) && !!prediabetesHistory?.includes("a1c")
    );
  },
  component: A1cQuestion,
};
