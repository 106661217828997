import Cookies from "js-cookie";

import { SurveyAnswers } from "@/contexts/app-context";
import { captureException } from "@/utils/sentry";

export type AutologinData = {
  accessCode: string;
  accessToken: string;
};

const IDENTITY_COOKIE_KEY = "identity_data";

type IdentityData = {
  email: string;
  accessCode: string;
  accessToken: string;
  clientId: string;
};

export const setIdentityData = (data: IdentityData) => {
    Cookies.set(IDENTITY_COOKIE_KEY, JSON.stringify(data), {
      // expires in 24 hours
      expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
      domain: window.ENV.COOKIE_DOMAIN,
      path: "/",
      secure: true,
      sameSite: "strict",
    });
}

/**
 * Attempts to read autologin_data cookie set by the mobile app in web views.
 * If its not present or an error occurs, return undefined and let the caller handle it.
 *
 * To set up an autologindata cookie:
 * 1. Go to https://account.test.wsli.dev
 * 2. Login with a user
 * 3. Open the console and run the command "JSON.stringify(window.reduxStore.getState().auth)"
 * 4. Paste the result into a cookie called "autologin_data" wherever you are testing autologin data
 *    (make sure not to have single quotes around the string)
 */
export const getAutologinData = (): AutologinData | undefined => {
  try {
    const autologinDataCookie = Cookies.get("autologin_data");

    if (!autologinDataCookie) {
      return undefined;
    }
    const autologinData: AutologinData = JSON.parse(autologinDataCookie);

    if (!autologinData.accessCode || !autologinData.accessToken) {
      throw Error("autologin_data cookie is missing expected data");
    }
    return autologinData;
  } catch (error) {
    captureException(error);
  }
  return undefined;
};

const SURVEY_ANSWER_COOKIE_NAME = "noom_b2b_wea_sa";

// IMPORTANT - Before expanding the allow list, we need to ensure the survey answers will be properly parsed in getSurveyAnswersFromCookie.
// Due to type conversions of objects that don't have corresponding primitive value (ie Date)
// we need to make sure we recreate the expected object/type before returning the surveyAnswer object.
type SURVEY_ANSWER_ALLOW_LIST = "eligibilityCheck" | "createAccount";

/**
 * Stores survey answers (from SURVEY_ANSWER_ALLOW_LIST) in a cookie.
 * @param surveyAnswers
 */
export const setSurveyAnswerCookie = (
  surveyAnswers: Pick<SurveyAnswers, SURVEY_ANSWER_ALLOW_LIST>,
) => {
  Cookies.set(SURVEY_ANSWER_COOKIE_NAME, JSON.stringify(surveyAnswers), {
    expires: 1,
  });
};

export const getSurveyAnswersFromCookie = (): SurveyAnswers | undefined => {
  const surveyAnswerCookie = Cookies.get(SURVEY_ANSWER_COOKIE_NAME);
  try {
    if (surveyAnswerCookie) {
      let surveyAnswersFromCookie: SurveyAnswers =
        JSON.parse(surveyAnswerCookie);

      // dob is actually a string here due to surveyAnswersFromCookie
      // having been stringified and re-parsed so convert it back to a Date.
      if (surveyAnswersFromCookie.eligibilityCheck?.dob) {
        surveyAnswersFromCookie = {
          ...surveyAnswersFromCookie,
          eligibilityCheck: {
            ...surveyAnswersFromCookie.eligibilityCheck,
            dob: new Date(surveyAnswersFromCookie.eligibilityCheck.dob),
          },
        };
      }
      return surveyAnswersFromCookie;
    }
  } catch (error) {
    captureException(error);
  }
  return undefined;
};
