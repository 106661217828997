import {
  GenderProto_Value,
  SexProto_Value,
} from "@noom/noom-contracts/noom_contracts/backend/user/model/sex_and_gender";

import { QuestionVisibilityState } from "@/components/survey/Question";
import { NoomMedMoreOptionsSelection } from "@/constants";
import { SurveyAnswers } from "@/contexts/app-context";
import { RetriageEligibility } from "@/models";

import { qualifiesForNoomMed } from "../qualification";

export const shouldShowCardiometabolicQuestionForRetriage = (
  retriageEligibility: RetriageEligibility | undefined,
): boolean => {
  const { currentProgram, eligibleRetriagePrograms = [] } =
    retriageEligibility || {};

  // The retriage eligibility API will not return a retriage program if it is
  // the user's current program (e.g. DPP -> DPP)
  return (
    (currentProgram === "WEIGHT" || currentProgram === "DPP") &&
    (eligibleRetriagePrograms.includes("DPP") ||
      eligibleRetriagePrograms.includes("MED"))
  );
};

// Only the following sex and gender values can be cleanly mapped to their respective question options.
// For other values, we'll need to ask the question again.
export const GenderProtoMap: Partial<Record<GenderProto_Value, string>> = {
  MALE: "male",
  FEMALE: "female",
};
export const SexProtoMap: Partial<Record<SexProto_Value, string>> = {
  MALE: "male",
  FEMALE: "female",
  INTERSEX: "intersex",
};

/**
 * Returns true if we prepopulated both sex and gender from retriage eligibility.
 * @param surveyAnswers
 * @param retriageEligibility
 * @returns
 */
export const areSexAndGenderPrePopulated = (
  surveyAnswers: SurveyAnswers,
  retriageEligibility?: RetriageEligibility,
): boolean => {
  const { gender, sex } = retriageEligibility?.userProfile || {};

  if (!(sex && gender)) {
    return false;
  }

  const isGenderPrePopulated = surveyAnswers.gender && !!GenderProtoMap[gender];
  const isSexPrePopulated = surveyAnswers.sex && !!SexProtoMap[sex];

  return !!(isGenderPrePopulated && isSexPrePopulated);
};

export const shouldShowDppQualification = (
  state: QuestionVisibilityState,
): boolean => {
  const {
    retriageEligibility: { currentProgram, eligibleRetriagePrograms = [] } = {},
    selectedProgram,
    surveyAnswers: { dppEligible, noomMedMoreOptions },
  } = state;
  return (
    (!qualifiesForNoomMed(state) ||
      noomMedMoreOptions === NoomMedMoreOptionsSelection.OTHER) &&
    currentProgram === "WEIGHT" &&
    eligibleRetriagePrograms.includes("DPP") &&
    selectedProgram !== "MED" &&
    selectedProgram !== "MOOD" &&
    selectedProgram !== "NMPBA" &&
    dppEligible !== false
  );
};
