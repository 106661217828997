import React from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  Button,
  CompassColor,
  Heading,
  Image,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { shouldShowPersonalizationFlow } from "@/utils/survey/contentPersonalization";

import { BaseInterstitial } from "../../core";

const QUESTION_ID = "personalizationIntro";

const PersonalizationIntro: React.FC<QuestionComponentProps> = ({
  onClickNext,
}) => {
  const { t } = useTranslation();

  return (
    <BaseInterstitial
      backgroundColor={CompassColor.sky}
      onClickBack={() => null}
      onClickNext={() => Promise.resolve()}
      questionId={QUESTION_ID}
      width="550px"
    >
      <Stack spacing={Spacing[8]} alignItems="stretch">
        <Image
          alt="Puzzle Piece Graphic"
          margin="auto"
          src="/assets/img/puzzle2.svg"
          mt="20px"
        />
        <Heading fontSize="24px" fontWeight={500} lineHeight="32px">
          {t("personalizationIntro.heading")}
        </Heading>
        <Text fontSize="20px">
          <Trans i18nKey="personalizationIntro.body">
            At Noom, we take a holistic approach to sustainable weight loss.
            Science tells us that factors like stress, metabolism, movement, and
            sleep can all greatly affect your ability to lose weight.
            <br />
            <br />
            We&apos;ve analyzed your responses so far, and recommend extra
            support in certain areas. Head to the next screen to learn more.
          </Trans>
        </Text>
      </Stack>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        marginTop="var(--spacing-xl)"
      >
        <Button
          colorScheme="primary"
          onClick={() => onClickNext(null)}
          padding="unset"
          size="xl"
          whiteSpace="unset"
        >
          <Trans i18nKey="personalizationIntro.cta">Next</Trans>
        </Button>
      </Box>
    </BaseInterstitial>
  );
};

export const Q_PERSONALIZATION_INTRO: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo, selectedProgram, surveyAnswers }) =>
    shouldShowPersonalizationFlow(partnerInfo, selectedProgram, surveyAnswers),
  component: PersonalizationIntro,
};
