import { Formik } from "formik";
import { TFunction } from "i18next";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Button, Input, Spacing, Stack } from "@noom/wax-component-library";

import {
  AgeInput,
  AgeInputValidationSchema,
  DateInput,
  DOBSchema,
} from "@/components/form";
import { useAppContext } from "@/contexts";
import { useQueryParams, useTrackEvent } from "@/hooks";

export type EligibilityCheckFormValues = {
  age?: number;
  dob: Date | null;
  employeeId?: string;
  firstName: string;
  lastName: string;
  isMedEligible?: boolean;
  policyId?: string;
};

export const defaultEligibilityCheckFormValues: EligibilityCheckFormValues = {
  age: undefined,
  dob: null,
  employeeId: "",
  firstName: "",
  lastName: "",
  isMedEligible: false,
  policyId: "",
};

const getValidationSchema = (
  t: TFunction,
  showDobField: boolean,
  showEmployeeIdField: boolean,
  isUhcPartner: boolean,
) => {
  const MAX_LENGTH = 150;
  const baseSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(
        MAX_LENGTH,
        t("form.errors.maxLength", {
          label: t("form.firstName.label"),
          maxLength: MAX_LENGTH,
        }),
      )
      .required(t("form.firstName.errors.required")),
    lastName: Yup.string()
      .max(
        MAX_LENGTH,
        t("form.errors.maxLength", {
          label: t("form.lastName.label"),
          maxLength: MAX_LENGTH,
        }),
      )
      .required(t("form.lastName.errors.required")),
  });

  const employeeIdSchema = Yup.object({
    employeeId: Yup.string()
      .max(
        MAX_LENGTH,
        t("form.errors.maxLength", {
          label: t("form.employeeId.label"),
          maxLength: MAX_LENGTH,
        }),
      )
      .required(
        t("form.employeeId.errors.required", {
          context: isUhcPartner ? "member" : "",
        }),
      ),
  });

  let validationSchema = baseSchema;

  const ageSchema = showDobField ? DOBSchema : AgeInputValidationSchema;
  validationSchema = validationSchema.concat(ageSchema);

  if (showEmployeeIdField) {
    validationSchema = validationSchema.concat(employeeIdSchema);
  }

  if (isUhcPartner) {
    const policyIdSchema = Yup.object({
      policyId: Yup.string()
        .max(
          MAX_LENGTH,
          t("form.errors.maxLength", {
            label: t("form.policyId.label"),
            maxLength: MAX_LENGTH,
          }),
        )
        .required(t("form.policyId.errors.required")),
    });

    validationSchema = validationSchema.concat(policyIdSchema);
  }

  return validationSchema;
};

export const EligibilityCheckForm: React.FC<{
  initialValues: EligibilityCheckFormValues;
  isLoading?: boolean;
  onSubmit: (values: EligibilityCheckFormValues) => void;
  pageId: string;
  setInitialValues: React.Dispatch<EligibilityCheckFormValues>;
}> = ({
  initialValues,
  isLoading,
  pageId,
  setInitialValues,
  onSubmit: onParentSubmit,
}) => {
  const { enrollmentType, partnerInfo } = useAppContext();
  const queryParams = useQueryParams();
  const { trackIdentifiedPageViewed } = useTrackEvent();
  const { i18n, t } = useTranslation();

  const { configs, slug } = partnerInfo || {};
  const { eligibilityId } = queryParams;

  const isUhcPartner =
    configs?.UPFRONT_ELIGIBILITY_ENFORCEMENT === "UHC_OPTUM_BIG_FIVE";

  const ctaText =
    configs?.UPFRONT_ELIGIBILITY_ENFORCEMENT === "NONE" ||
    enrollmentType === "RETRIAGE"
      ? t("eligibilityCheck.ctaText.next")
      : t("eligibilityCheck.ctaText.checkEligibility");
  const showDobField =
    configs?.UPFRONT_ELIGIBILITY_ENFORCEMENT === "FULL_NAME_DOB" ||
    configs?.UPFRONT_ELIGIBILITY_ENFORCEMENT === "LN_DOB" ||
    configs?.REQUIRES_DOB ||
    false;
  const showEmployeeIdField =
    configs?.UPFRONT_ELIGIBILITY_ENFORCEMENT === "PARTICIPANT_ID" ||
    configs?.UPFRONT_ELIGIBILITY_ENFORCEMENT === "PARTICIPANT_ID_LAST_NAME" ||
    configs?.UPFRONT_ELIGIBILITY_ENFORCEMENT === "PARTICIPANT_ID_FULL_NAME" ||
    isUhcPartner;

  const onSubmit = async (values: EligibilityCheckFormValues) => {
    const trimmedValues: EligibilityCheckFormValues = {
      ...values,
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
    };

    // Store the values in local React state so that they persist when clicking
    // back on the eligibility error state
    setInitialValues(trimmedValues);
    onParentSubmit(trimmedValues);
  };

  useEffect(() => {
    trackIdentifiedPageViewed(pageId);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema(
        t,
        showDobField,
        showEmployeeIdField,
        isUhcPartner,
      )}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({
        errors,
        getFieldProps,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        validateField,
      }) => {
        const handleInputChange = async (
          e: React.ChangeEvent<HTMLInputElement>,
          fieldName: keyof EligibilityCheckFormValues,
        ) => {
          // This is a hacky method to get each field to validate on change
          // individually.
          await handleChange(e);
          validateField(fieldName);
        };

        return (
          <form onSubmit={handleSubmit}>
            <Stack spacing={Spacing[8]}>
              <Input
                {...getFieldProps("firstName")}
                autoFocus
                error={touched.firstName && errors.firstName}
                label={t("form.firstName.label")}
                onChange={(e) => handleInputChange(e, "firstName")}
                placeholder={t("form.firstName.placeholder")}
                isReadOnly={Boolean(
                  initialValues.firstName &&
                    eligibilityId &&
                    configs?.UPFRONT_ELIGIBILITY_ENFORCEMENT ===
                      "FULL_NAME_DOB",
                )}
              />
              <Input
                {...getFieldProps("lastName")}
                error={touched.lastName && errors.lastName}
                label={t("form.lastName.label")}
                onChange={(e) => handleInputChange(e, "lastName")}
                placeholder={t("form.lastName.placeholder")}
                isReadOnly={Boolean(
                  initialValues.lastName &&
                    eligibilityId &&
                    (configs?.UPFRONT_ELIGIBILITY_ENFORCEMENT ===
                      "FULL_NAME_DOB" ||
                      configs?.UPFRONT_ELIGIBILITY_ENFORCEMENT === "LN_DOB"),
                )}
              />
              {showDobField ? (
                <DateInput
                  {...getFieldProps("dob")}
                  error={touched.dob && errors.dob}
                  label={t("form.dob.label")}
                  onChange={() => validateField("dob")}
                  placeholder={t("form.dob.placeholder")}
                  isReadOnly={Boolean(
                    initialValues.dob &&
                      eligibilityId &&
                      (configs?.UPFRONT_ELIGIBILITY_ENFORCEMENT ===
                        "FULL_NAME_DOB" ||
                        configs?.UPFRONT_ELIGIBILITY_ENFORCEMENT === "LN_DOB"),
                  )}
                />
              ) : (
                <AgeInput
                  name="age"
                  onChange={(e) => handleInputChange(e, "age")}
                />
              )}
              {showEmployeeIdField && (
                <Input
                  {...getFieldProps("employeeId")}
                  error={touched.employeeId && errors.employeeId}
                  helper={
                    i18n.exists(`form.employeeId.helper_${slug}`)
                      ? t(`form.employeeId.helper_${slug}`)
                      : undefined
                  }
                  label={t("form.employeeId.label", {
                    context: isUhcPartner ? "member" : "",
                  })}
                  onChange={(e) => handleInputChange(e, "employeeId")}
                  placeholder={t("form.employeeId.placeholder", {
                    context: isUhcPartner ? "member" : "",
                  })}
                  isReadOnly={Boolean(
                    initialValues.employeeId &&
                      eligibilityId &&
                      (configs?.UPFRONT_ELIGIBILITY_ENFORCEMENT ===
                        "PARTICIPANT_ID" ||
                        configs?.UPFRONT_ELIGIBILITY_ENFORCEMENT ===
                          "PARTICIPANT_ID_LAST_NAME" ||
                       configs?.UPFRONT_ELIGIBILITY_ENFORCEMENT ===
                          "PARTICIPANT_ID_FULL_NAME" ||
                        configs?.UPFRONT_ELIGIBILITY_ENFORCEMENT ===
                          "UHC_OPTUM_BIG_FIVE"),
                  )}
                />
              )}
              {isUhcPartner && (
                <Input
                  {...getFieldProps("policyId")}
                  error={touched.policyId && errors.policyId}
                  label={t("form.policyId.label")}
                  onChange={(e) => handleInputChange(e, "policyId")}
                  placeholder={t("form.policyId.placeholder")}
                />
              )}
              <Button
                colorScheme="primary"
                isLoading={isLoading || isSubmitting}
                size="xl"
                type="submit"
              >
                {ctaText}
              </Button>
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};
