
import { Api } from "@noom/noomscape";

import type { CreateLoginTokenResponse } from "@/api/account/createLoginToken";
import { setIdentityData } from "@/utils/cookies"
import { captureException } from "@/utils/sentry";

import { AxiosError } from "axios";

// this is the client id which represents our end users
// logged in via web or native applications
const CLIENT_ID = "noom-coach"

export const useCreateLoginToken = () => {
  const createLoginToken = async (email: string, password: string) => {
    const response: CreateLoginTokenResponse = await Api.call(
      "account.createLoginToken",
      Api.api.account.createLoginToken,
      {
        email,
        password,
      },
    );

    // we set this cookie on login so that if we end up sending the user to medical intake
    // they will not be prompted to log in again
    setIdentityData({
      accessCode: response.accessCode,
      accessToken: response.access_token,
      email: response.accountEmail,
      clientId: CLIENT_ID,
    })

    return response.access_token;
  };

  const isProvidingValidPassword = async (email: string, password: string) => {
    try {
      await createLoginToken(email, password);
      return true;
    } catch (e: unknown) {
      let errorCode;
      if (e instanceof AxiosError) {
        errorCode = e.response?.data.errorCode;

        if (errorCode === "INCORRECT_PASSWORD") {
          return false;
        }

        if (errorCode === "NO_ACCOUNT_FOR_INFO") {
          return true;
        }
      }

      captureException(e, { errorCode });
      return false;
    }
  };

  return { createLoginToken, isProvidingValidPassword };
};
