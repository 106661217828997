import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button, Spacing, Stack, Text } from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { ProgramOffering } from "@/constants";
import { useAppContext } from "@/contexts";

import { BaseQuestion, ImageBulletListCard } from "../core";

const QUESTION_ID = "scaleOptIn";

declare module "@/contexts" {
  interface SurveyAnswers {
    scaleOptIn?: boolean;
  }
}

const ScaleOptIn: React.FC<QuestionComponentProps> = ({ onClickNext }) => {
  const { selectedProgram } = useAppContext();
  const { t } = useTranslation();
  const bulletListItems = [
    {
      image: "/assets/img/scaleOptIn-chart.png",
      text: t("scaleOptIn.valueProps.item1"),
    },
    {
      image: "/assets/img/scaleOptIn-scale.png",
      text: t("scaleOptIn.valueProps.item2"),
    },
  ];

  const DEFERRED_SHIPPING_TEXT: Partial<Record<ProgramOffering, JSX.Element>> =
    {
      NMPBA: (
        <Text>
          <Trans i18nKey="scaleOptIn.deferredShippingText.nmpba" />
        </Text>
      ),
      MED: <Text>{t("scaleOptIn.deferredShippingText.med")}</Text>,
    };

  return (
    <BaseQuestion
      hideHeader
      onClickBack={() => undefined}
      questionId={QUESTION_ID}
      questionText={t("scaleOptIn.questionText")}
    >
      <ImageBulletListCard items={bulletListItems} />
      <Text>
        <Trans i18nKey="scaleOptIn.questionHelperText" />
      </Text>
      {selectedProgram && DEFERRED_SHIPPING_TEXT[selectedProgram]}
      <Stack spacing={Spacing[4]} pt={Spacing[2]}>
        <Button
          colorScheme="primary"
          onClick={() => {
            onClickNext(true);
          }}
          size="xl"
          type="submit"
        >
          {t("scaleOptIn.ctaText.yes")}
        </Button>
        <Button
          onClick={() => {
            onClickNext(false);
          }}
          size="xl"
          variant="outline"
        >
          {t("scaleOptIn.ctaText.no")}
        </Button>
      </Stack>
    </BaseQuestion>
  );
};

export const Q_SCALE_OPT_IN: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo, selectedProgram }) =>
    selectedProgram !== "MOOD" &&
    (selectedProgram === "MED" ||
      selectedProgram === "NMPBA" ||
      partnerInfo?.configs.OFFER_SCALE_SHIPMENT === true),
  component: ScaleOptIn,
};

export const Q_RETRIAGE_SCALE_OPT_IN: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ selectedProgram }) =>
    selectedProgram === "MED" || selectedProgram === "NMPBA",
  component: ScaleOptIn,
};
