import { ProgramOffering } from "@/constants";
import { Partner } from "@/models";

import { isB2CPartner } from "../partners";

/**
 * Determines if the SDOH questions should be asked.
 *
 * @param partnerInfo used to determine if the partner is a B2C partner
 * @param selectedProgram
 * @returns
 */
export const shouldAskSDOH = (
  partnerInfo: Partner | null,
  selectedProgram: ProgramOffering | null,
) => {
  if (partnerInfo?.id === undefined) {
    return false;
  }

  return selectedProgram !== "MOOD" && !isB2CPartner(partnerInfo);
};
