/**
 * Endpoint for trigger a password reset email to be sent
 */

import { Api } from "@noom/noomscape";

import axios from "axios";


Api.register(
  "account.passwordResets",
  ({
    email,
    postResetRedirectUrl,
  }: {
    email: string;
    postResetRedirectUrl: string;
  }) =>
    axios({
      url: "/account/passwordResets",
      urlWithPathParams: "/account/passwordResets",
      baseURL: window.ENV.PRODUCT_API_URL,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email,
        postResetRedirectUrl,
        // Without these properties the endpoint will error despite them being made NO-OP do to security vulnerability.
        // TODO: remove once account lambda no longer requires them in the payload.
        from: "NO-OP",
        resetUrl: "NO-OP",
      },
    }),
);
