import { KinesisClient, PutRecordCommand } from "@aws-sdk/client-kinesis";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";

import type { NoomEventProto } from "@noom/noom-contracts/noom_contracts/events/noom_event";
import type { NoomB2BEnrollmentEventProto } from "@noom/noom-contracts/noom_contracts/events/web/b2b/enrollment";
import type { NoomB2BEnrollmentAnonEventProto } from "@noom/noom-contracts/noom_contracts/events/web/b2b/enrollment_anon";
import type {
  NoomB2BEnrollmentIdentifiedEventProto,
  NoomB2BEnrollmentIdentifiedEventProto_SharedPropertiesProto,
} from "@noom/noom-contracts/noom_contracts/events/web/b2b/enrollment_identified";
import { NoomB2BSurveyEventProto } from "@noom/noom-contracts/noom_contracts/events/web/b2b/survey";
import {
  NoomB2BSurveyAnonEventProto,
  NoomB2BSurveyAnonEventProto_SharedPropertiesProto,
} from "@noom/noom-contracts/noom_contracts/events/web/b2b/survey_anon";
import {
  NoomB2BSurveyIdentifiedEventProto,
  NoomB2BSurveyIdentifiedEventProto_SharedPropertiesProto,
} from "@noom/noom-contracts/noom_contracts/events/web/b2b/survey_identified";

import { useAppContext } from "@/contexts";
import { withPiiQueryParams } from "@/utils/pixels/urlParamsPii";
import { createCivilDateTime } from "@/utils/proto";
import { captureException } from "@/utils/sentry";
import { isBuyflowTraffic } from "@/utils/userSegment";

import { useExperiment } from "./useExperiment";
import { useQueryParams } from "./useQueryParams";

const AWS_REGION = "us-east-1";

export const useTrackEvent = () => {
  const {
    b2bAnonymousUserId,
    b2bIdentifiedUserId,
    eligibilityInfo,
    enrollmentType,
    partnerInfo,
    retriageEligibility,
    surveyMetadata,
    userProfile,
  } = useAppContext();
  const { getExperimentAssignments } = useExperiment();
  const queryParams = useQueryParams();

  const growthUserId = Cookies.get("_userId");
  const shouldUseGrowthUserId = isBuyflowTraffic() && !!growthUserId;

  const identifiedEventPartitionKey = shouldUseGrowthUserId
    ? growthUserId
    : b2bIdentifiedUserId;

  const getTransformedExperimentAssignments = () =>
    Object.entries(getExperimentAssignments()).map(
      ([name, variation]) => `${name}_${variation}`,
    );

  /**
   * Gets segment info from eligibility or user profile response (mutually exclusive).
   */
  const getSegmentInfo: () => {
    segmentId?: string;
    segmentName?: string;
  } = () => {
    if (eligibilityInfo) {
      return {
        segmentId: eligibilityInfo.segmentId,
        segmentName: eligibilityInfo.segmentName,
      };
    }
    if (retriageEligibility) {
      return {
        segmentId: retriageEligibility.segmentId,
        segmentName: retriageEligibility.segmentName,
      };
    }
    if (userProfile) {
      return {
        segmentId: userProfile.segmentId,
        segmentName: userProfile.segmentName,
      };
    }
    return {};
  };

  /**
   * Sends the event to AWS Kinesis to be picked up by the data team.
   *
   * @param event Event structure specified by the noom-contracts schema
   */
  const sendEvent = async (event: NoomEventProto, partitionKey: string) => {
    const command = new PutRecordCommand({
      Data: new TextEncoder().encode(JSON.stringify(event)),
      PartitionKey: partitionKey,
      StreamName: "event-stream",
    });

    try {
      const kinesisClient = new KinesisClient({
        credentials: fromCognitoIdentityPool({
          clientConfig: {
            region: AWS_REGION,
          },
          identityPoolId: window.ENV.COGNITO_IDENTITY_POOL,
        }),
        region: AWS_REGION,
      });

      await kinesisClient.send(command);
    } catch (e) {
      captureException(e);
    }
  };

  /**
   * Constructs a Noom Event object with shared properties across B2B
   * enrollment events - both anonymous and identified events.
   *
   * @param eventPayload Specific event payload to be nested under the top
   * level Noom Event.
   * @returns Noom Event object that will be sent using Kinesis.
   */
  const constructEnrollmentEvent = (
    eventPayload: Omit<NoomB2BEnrollmentEventProto, "sharedProperties">,
  ): NoomEventProto => {
    const now = new Date(Date.now());
    const timestamp = now.toISOString();
    const localDateTime = createCivilDateTime(now);
    const experimentAssignments = getTransformedExperimentAssignments();

    return {
      noomB2bEnrollmentEvent: {
        sharedProperties: {
          experimentAssignments,
          b2bPartnerId: partnerInfo?.id || -1,
          // Use the internal name instead of the display name.
          b2bPartnerName: partnerInfo?.name || "",
          enrollmentType: enrollmentType || "UNKNOWN",
          eventLocalDatetime: localDateTime,
          ...getSegmentInfo(),
        },
        ...eventPayload,
      },
      sharedProperties: {
        eventTimestamp: timestamp,
        eventUuid: uuidv4(),
        experimentalProperties: {},
      },
    };
  };

  /**
   * Constructs a Noom Event object with shared properties across B2B
   * survey events - both anonymous and identified events.
   *
   * @param eventPayload Specific event payload to be nested under the top
   * level Noom Event.
   * @returns Noom Event object that will be sent using Kinesis.
   */
  const constructSurveyEvent = (
    eventPayload: Omit<NoomB2BSurveyEventProto, "sharedProperties">,
  ): NoomEventProto => {
    const now = new Date(Date.now());
    const timestamp = now.toISOString();
    const localDateTime = createCivilDateTime(now);
    const experimentAssignments = getTransformedExperimentAssignments();

    return {
      noomB2bSurveyEvent: {
        sharedProperties: {
          surveyInstance: surveyMetadata?.instance,
          surveyName: surveyMetadata?.name || "UNKNOWN",
          experimentAssignments,
          eventLocalDatetime: localDateTime,
          ...getSegmentInfo(),
        },
        ...eventPayload,
      },
      sharedProperties: {
        eventTimestamp: timestamp,
        eventUuid: uuidv4(),
        experimentalProperties: {},
      },
    };
  };

  /**
   * Tracking function for anonymous survey events.
   * These are sent to the B2B Anonymous project in Mixpanel.
   *
   * @param eventType One of the events in NoomB2BSurveyAnonEventProto
   * @param eventProperties Properties for the event of "eventType"
   */
  const trackSurveyAnonEvent = async <
    T extends keyof Omit<NoomB2BSurveyAnonEventProto, "sharedProperties">,
  >(
    eventType: T,
    eventProperties: NoomB2BSurveyAnonEventProto[T],
  ) => {
    const sharedProperties: NoomB2BSurveyAnonEventProto_SharedPropertiesProto =
      {
        anonymousUserId: b2bAnonymousUserId,
      };

    const event = constructSurveyEvent({
      noomB2bSurveyAnonEvent: {
        sharedProperties,
        [eventType]: eventProperties,
      },
    });

    await sendEvent(event, b2bAnonymousUserId);
  };

  /**
   * Tracking function for identified survey events.
   * These are sent to the Growth project in Mixpanel.
   *
   * @param eventType One of the events in NoomB2BSurveyIdentifiedEventProto
   * @param eventProperties Properties for the event of "eventType"
   */
  const trackSurveyIdentifiedEvent = async <
    T extends keyof Omit<NoomB2BSurveyIdentifiedEventProto, "sharedProperties">,
  >(
    eventType: T,
    eventProperties: NoomB2BSurveyIdentifiedEventProto[T],
  ) => {
    const sharedProperties: NoomB2BSurveyIdentifiedEventProto_SharedPropertiesProto =
      {
        b2bUserId: b2bIdentifiedUserId,
      };

    const event = constructSurveyEvent({
      noomB2bSurveyIdentifiedEvent: {
        sharedProperties,
        [eventType]: eventProperties,
      },
    });

    await sendEvent(event, b2bIdentifiedUserId);
  };

  /**
   * Tracking function for anonymous enrollment events (e.g. DPP triage). These are sent
   * to the B2B Anonymous project in Mixpanel.
   *
   * @param eventType Event defined in noom-contracts
   * @param payload Additional properties to send with the event
   */
  const trackAnonEvent = async <
    T extends keyof Omit<NoomB2BEnrollmentAnonEventProto, "sharedProperties">,
  >(
    eventType: T,
    payload: NoomB2BEnrollmentAnonEventProto[T],
  ) => {
    const event = constructEnrollmentEvent({
      noomB2bEnrollmentAnonEvent: {
        sharedProperties: {
          anonymousUserId: b2bAnonymousUserId,
        },
        [eventType]: payload,
      },
    });
    await sendEvent(event, b2bAnonymousUserId);
  };

  /**
   * Tracking function for identified enrollment events. These are sent to the Growth
   * project in Mixpanel.
   *
   * @param eventType Event defined in noom-contracts
   * @param payload Additional properties to send with the event
   */
  const trackIdentifiedEvent = async <
    T extends keyof Omit<
      NoomB2BEnrollmentIdentifiedEventProto,
      "sharedProperties"
    >,
  >(
    eventType: T,
    payload: NoomB2BEnrollmentIdentifiedEventProto[T],
  ) => {
    const sharedProperties: NoomB2BEnrollmentIdentifiedEventProto_SharedPropertiesProto =
      shouldUseGrowthUserId
        ? {
            growthUserId,
          }
        : {
            b2bUserId: b2bIdentifiedUserId,
          };

    const event = constructEnrollmentEvent({
      noomB2bEnrollmentIdentifiedEvent: {
        sharedProperties,
        [eventType]: payload,
      },
    });

    await sendEvent(event, identifiedEventPartitionKey);
  };

  /**
   * Helper function to call the appropriate page viewed
   * event depending on whether the user is an enrollment
   * or general survey flow.
   *
   * @param pageId
   */
  const trackAnonPageViewed = async (pageId: string) => {
    if (surveyMetadata) {
      trackSurveyAnonEvent("b2bSurveyAnonPageViewed", { pageId });
    } else {
      trackAnonEvent("b2bEnrollmentAnonPageViewed", { pageId });
    }
  };

  const trackIdentifiedPageViewed = async (pageId: string) => {
    const queryParamsWithPii = withPiiQueryParams(queryParams);
    trackIdentifiedEvent("b2bEnrollmentIdentifiedPageViewed", {
      pageId,
      leadSource: queryParamsWithPii.leadSource,
      utmCampaign: queryParamsWithPii.utm_campaign,
      utmContent: queryParamsWithPii.utm_content,
      utmMedium: queryParamsWithPii.utm_medium,
      utmSource: queryParamsWithPii.utm_source,
      utmTerm: queryParamsWithPii.utm_term,
    });
  };

  const linkB2BIdentity = async (
    accessCode: string,
    emailAddress?: string,
    upid?: string,
  ) => {
    const now = new Date(Date.now());
    const eventTimestamp = now.toISOString();

    const userIdObject = shouldUseGrowthUserId
      ? {
          growthUserId,
        }
      : {
          b2bUserId: b2bIdentifiedUserId,
        };

    const event: NoomEventProto = {
      b2bIdentityLinked: {
        accessCode,
        emailAddress,
        source: "b2b_enrollment",
        upid,
        ...userIdObject,
      },
      sharedProperties: {
        eventTimestamp,
        eventUuid: uuidv4(),
        experimentalProperties: {},
      },
    };
    await sendEvent(event, identifiedEventPartitionKey);
  };

  return {
    linkB2BIdentity,
    trackAnonEvent,
    trackAnonPageViewed,
    trackIdentifiedEvent,
    trackIdentifiedPageViewed,
    trackSurveyAnonEvent,
    trackSurveyIdentifiedEvent,
  };
};
