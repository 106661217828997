import React from "react";
import { Trans } from "react-i18next";

import {
  CompassColor,
  Image,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { shouldShowWomensHealthQuestions } from "@/utils/survey/womensHealth";

import { BaseInterstitial } from "../../core";

const QUESTION_ID = "womensHealth";

const WomensHealth: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
}) => (
  <BaseInterstitial
    backgroundColor={CompassColor.sky}
    onClickBack={onClickBack}
    onClickNext={onClickNext}
    questionId={QUESTION_ID}
    width="600px"
    ctaText="Next"
  >
    <Stack spacing={Spacing[8]} mb="16px" maxW="550px">
      <Image src="/assets/img/womensHealth.svg" m="0 auto" />
      <Text fontSize="18px" lineHeight="26px">
        <Trans i18nKey="womensHealth.message">
          While each body is unique, there are certain{" "}
          <strong>biological and hormonal changes</strong> associated with
          female physiology that can influence weight loss.
          <br />
          <br />
          We have a couple questions that let us customize your plan with{" "}
          <strong>science-backed, women&apos;s health tools</strong>.
        </Trans>
      </Text>
    </Stack>
  </BaseInterstitial>
);

export const Q_WOMENS_HEALTH: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: (state) => shouldShowWomensHealthQuestions(state),
  component: WomensHealth,
};
