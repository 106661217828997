import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  CompassColor,
  CompassLoader,
  Stack,
  StyleProps,
} from "@noom/wax-component-library";

import { useAppContext } from "@/contexts";
import { useGoto, useRetriageEligibility } from "@/hooks";
import { getAutologinData } from "@/utils/cookies";
import { captureMessage } from "@/utils/sentry";

const defaultStyles: StyleProps = {
  display: "flex",
  w: "100vw",
  h: "100vh",
  position: "relative",
  flexDir: "column",
  alignItems: "center",
  justifyContent: "center",
  color: CompassColor.black,
  fontFamily: "Untitled Sans",
  fontWeight: 500,
};

export const RetriageAuthView: React.FC = () => {
  const navigate = useNavigate();
  const goto = useGoto();
  const autologinData = getAutologinData();
  const { retriageEligibility } = useAppContext();
  useRetriageEligibility();

  useEffect(() => {
    if (!autologinData) {
      captureMessage("No retriage auth found", "fatal");
      goto.error();
    }
  }, [autologinData]);

  useEffect(() => {
    if (!retriageEligibility) {
      return;
    }
    if (!retriageEligibility.partnerSlug) {
      goto.error();
      return;
    }
    navigate(`/employer/${retriageEligibility.partnerSlug}/rt`);
  }, [retriageEligibility]);

  return (
    <Box {...defaultStyles}>
      <Stack>
        <CompassLoader color={CompassColor.black} size="lg" />
      </Stack>
    </Box>
  );
};
