import React from "react";
import { isMobile } from "react-device-detect";
import { Trans, useTranslation } from "react-i18next";

import {
  Button,
  List,
  ListItem,
  Spacing,
  Stack,
} from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { NoomMedOverviewSelection } from "@/constants";
import { useAppContext } from "@/contexts";

import { BaseQuestion } from "../core";

const QUESTION_ID_DPP = "confirmChangeProgramDpp";
const QUESTION_ID_MED = "confirmChangeProgramMed";

declare module "@/contexts" {
  interface SurveyAnswers {
    [QUESTION_ID_DPP]?: boolean;
    [QUESTION_ID_MED]?: boolean;
  }
}

type ConfirmChangeProgramProps = QuestionComponentProps & {
  questionId: string;
};

const ConfirmChangeProgram: React.FC<ConfirmChangeProgramProps> = ({
  onClickBack,
  onClickNext,
  questionId,
}) => {
  const { setSelectedProgram } = useAppContext();
  const { t } = useTranslation();

  const newProgram = questionId === QUESTION_ID_MED ? "MED" : "DPP";
  const programKey = newProgram.toLowerCase();

  const medValueProps = (
    <List>
      <ListItem marginLeft="20px">
        {t("confirmChangeProgram.valueProp1")}
      </ListItem>
      <ListItem marginLeft="20px">
        {t("confirmChangeProgram.valueProp2")}
      </ListItem>
    </List>
  );

  const imageProps = isMobile
    ? {
        headerImageSrc: `/assets/img/app-preview-${programKey}-mobile.svg`,
      }
    : {
        imageSrc: `/assets/img/app-preview-${programKey}-desktop.svg`,
      };

  const handleOptIn = (didOptIn: boolean) => {
    const updatedProgram = didOptIn ? newProgram : null;

    setSelectedProgram(updatedProgram);
    onClickNext(didOptIn, { updatedProgram });
  };

  return (
    <BaseQuestion
      {...imageProps}
      hideSurveyProgressBar
      onClickBack={onClickBack}
      questionId={questionId}
      questionText={t(`confirmChangeProgram.title.${programKey}`)}
    >
      <Stack spacing={Spacing[8]}>
        <Trans
          components={{ medValueProps }}
          i18nKey={`confirmChangeProgram.prompt.${programKey}`}
        />
        <Stack spacing={Spacing[4]} pt={Spacing[2]}>
          <Button
            colorScheme="primary"
            onClick={() => {
              handleOptIn(true);
            }}
            size="xl"
            type="submit"
          >
            {t(`confirmChangeProgram.continue.${programKey}`)}
          </Button>
          <Button
            onClick={() => {
              handleOptIn(false);
            }}
            size="xl"
            variant="outline"
          >
            {t("confirmChangeProgram.cancel")}
          </Button>
        </Stack>
      </Stack>
    </BaseQuestion>
  );
};

const ConfirmChangeProgramDpp: React.FC<QuestionComponentProps> = (props) => (
  <ConfirmChangeProgram {...props} questionId={QUESTION_ID_DPP} />
);

const ConfirmChangeProgramMed: React.FC<QuestionComponentProps> = (props) => (
  <ConfirmChangeProgram {...props} questionId={QUESTION_ID_MED} />
);

export const Q_CONFIRM_CHANGE_PROGRAM_DPP: QuestionDefinition = {
  id: QUESTION_ID_DPP,
  shouldShowQuestion: ({
    surveyAnswers: { dppEligible, confirmChangeProgramMed, medicareConsent },
  }) =>
    !(confirmChangeProgramMed === false || medicareConsent === "disagree") &&
    dppEligible === true,
  component: ConfirmChangeProgramDpp,
};

export const Q_CONFIRM_CHANGE_PROGRAM_MED: QuestionDefinition = {
  id: QUESTION_ID_MED,
  shouldShowQuestion: ({
    retriageEligibility,
    selectedProgram,
    surveyAnswers: { noomMedOverview },
  }) =>
    noomMedOverview === NoomMedOverviewSelection.NEXT &&
    selectedProgram === "MED" &&
    retriageEligibility?.currentProgram === "DPP",
  component: ConfirmChangeProgramMed,
};
