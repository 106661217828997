import React from "react";
import { useParams } from "react-router-dom";

import { DPP_PARTNERS } from "@/constants/Partners";
import { useAppContext } from "@/contexts";
import { getDisplayName } from "@/models";

import { BaseSideForm } from "../enrollment/BaseSideForm";

import { PasscodeInputForm } from "./PasscodeInputForm";
import { UpidInputForm } from "./UpidInputForm";

export const CodeInputForm = () => {
  const { partnerInfo } = useAppContext();
  const { employerSlug } = useParams<AppParams>();

  const isPasscodeEnrollment = !!partnerInfo?.configs?.ENROLL_PASSCODE;
  const isVpSponsor = !!partnerInfo?.configs?.ENROLL_VIRGIN_PULSE;

  // Partners without Passcode or Virgin Pulse enrollment are typically
  // using UPID enrollment but for the edge case when none are selected
  // it's suitable to use the same messaging as UPID enrollment.
  const enrollmentBlurb =
    !isPasscodeEnrollment && !isVpSponsor
      ? `Contact ${getDisplayName(partnerInfo)} for enrollment information.`
      : "Sign up below to get started.";

  const helperText =
    // eslint-disable-next-line no-nested-ternary
    partnerInfo?.id && DPP_PARTNERS.includes(partnerInfo.id)
      ? `Join Noom at no cost to you as a benefit from ${getDisplayName(
          partnerInfo,
        )}! Create a Noom account below to get started.`
      : partnerInfo?.configs?.HIDE_COST
        ? enrollmentBlurb
        : `Join Noom at no cost to you! ${enrollmentBlurb}`;

  return (
    <BaseSideForm helperText={helperText}>
      {employerSlug ? <PasscodeInputForm /> : <UpidInputForm />}
    </BaseSideForm>
  );
};
