import React from "react";
import { useTranslation } from "react-i18next";

import { CreateAccountForm, CreateAccountFormValues } from "@/components/forms";
import { useAppContext } from "@/contexts";
import { isBuyflowTraffic } from "@/utils/userSegment";

import { QuestionComponentProps, QuestionDefinition } from "../../Question";
import { BaseQuestion } from "../core";

const QUESTION_ID = "createAccount";
export const CREATE_ACCOUNT_QUESTION_ID = QUESTION_ID;

declare module "@/contexts" {
  interface SurveyAnswers {
    createAccount?: CreateAccountFormValues;
  }
}

const CreateAccountQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const {
    enrollmentInfo: { upfrontEligibilityEnforcement } = {},
    partnerInfo,
  } = useAppContext();

  const { t } = useTranslation();

  const onSubmit = (values: CreateAccountFormValues) => {
    onClickNext(values);
  };

  const firstName = surveyAnswers.eligibilityCheck?.firstName;

  const context =
    // eslint-disable-next-line no-nested-ternary
    upfrontEligibilityEnforcement !== "NONE"
      ? partnerInfo?.configs.HIDE_COST
        ? "upfrontEligibilityEnforcement_hideCost"
        : "upfrontEligibilityEnforcement"
      : "";

  return (
    <BaseQuestion
      onClickBack={onClickBack}
      questionHelperText={t("createAccount.questionHelperText")}
      questionId={QUESTION_ID}
      questionText={t("createAccount.questionText", {
        context,
        firstName,
      })}
    >
      <CreateAccountForm
        initialValues={surveyAnswers[QUESTION_ID]}
        onSubmit={onSubmit}
      />
    </BaseQuestion>
  );
};

export const Q_CREATE_ACCOUNT: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: () => {
    const queryParams = new URLSearchParams(window.location.search);
    return !queryParams.get("eligibilityId") || isBuyflowTraffic();
  },
  component: CreateAccountQuestion,
};
