import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Text } from "@noom/wax-component-library";

import { CheckboxOptions, CheckboxOption } from "@/components/survey/checkbox";

import { QuestionComponentProps } from "../../Question";

import { BaseQuestion } from "./BaseQuestion";

type CheckboxQuestionComponentProps = Omit<
  QuestionComponentProps,
  "surveyAnswers"
> & {
  options: CheckboxOption[];
  hiddenOptions?: CheckboxOption[];
  questionHelperText?: string;
  questionId: string;
  questionText: string;
  selectedItems: string[];
};

const isSpecialCheckbox = (optionId: string) =>
  optionId === "none" || optionId === "preferNotToSay";

export const CheckboxQuestion: React.FC<CheckboxQuestionComponentProps> = ({
  canProceedWithoutAnswer = false,
  onClickBack,
  onClickNext,
  options,
  hiddenOptions,
  questionHelperText,
  questionId,
  questionText,
  selectedItems,
}) => {
  const { t } = useTranslation();
  const [checkedItems, setCheckedItems] = useState(selectedItems);
  const [showHiddenItems, setShowHiddenItems] = useState(false);
  const selectedHiddenOptions =
    hiddenOptions?.filter((option) => selectedItems.includes(option.id)) ?? [];
  const notSelectedHiddenOptions =
    hiddenOptions?.filter((option) => !selectedItems.includes(option.id)) ?? [];

  const toggleCheckedState = (
    optionId: string,
    currentCheckedState: boolean,
  ) => {
    /**
     * If the item is currently checked: remove the item from the array.
     * If the item is currently not checked:
     * - if it is a special checkbox, make that the only checked item
     * - otherwise, remove existing special checkboxes and add the item to the
     *   array
     */
    let newCheckedItems: string[];
    if (currentCheckedState) {
      newCheckedItems = checkedItems.filter(
        (checkedItem) => checkedItem !== optionId,
      );
    } else if (isSpecialCheckbox(optionId)) {
      newCheckedItems = [optionId];
    } else {
      newCheckedItems = [
        ...checkedItems.filter(
          (checkedItem) => !isSpecialCheckbox(checkedItem),
        ),
        optionId,
      ];
    }
    setCheckedItems(newCheckedItems);
  };

  const submitCheckboxQuestion = () => {
    if (checkedItems.length === 0 && !canProceedWithoutAnswer) {
      return;
    }
    onClickNext(checkedItems);
  };

  return (
    <BaseQuestion
      onClickBack={onClickBack}
      questionHelperText={questionHelperText}
      questionId={questionId}
      questionText={questionText}
    >
      <CheckboxOptions
        checkedItems={checkedItems}
        options={
          showHiddenItems
            ? [
                ...options,
                ...selectedHiddenOptions,
                ...notSelectedHiddenOptions,
              ]
            : [...options, ...selectedHiddenOptions]
        }
        submitCheckboxQuestion={submitCheckboxQuestion}
        toggleCheckedState={toggleCheckedState}
      />
      {!!notSelectedHiddenOptions.length && !showHiddenItems && (
        <Button
          variant="unstyled"
          w="100%"
          onClick={() => setShowHiddenItems(true)}
        >
          <Text textDecoration="underline">
            {t("checkboxQuestion.showHiddenOptionsText")}
          </Text>
        </Button>
      )}
      <Button
        colorScheme="primary"
        disabled={!canProceedWithoutAnswer && checkedItems.length === 0}
        onClick={() => submitCheckboxQuestion()}
        size="xl"
      >
        {t("checkboxQuestion.ctaText")}
      </Button>
    </BaseQuestion>
  );
};
