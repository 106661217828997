import React from "react";
import { useTranslation } from "react-i18next";

import { qualifiesForNoomMed } from "@/utils/qualification";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "mainReason";

declare module "@/contexts" {
  interface SurveyAnswers {
    mainReason?: string;
  }
}

const ReasonForLosingWeightQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    {
      id: "physicalAppearance",
      text: t("reasonForLosingWeight.options.physicalAppearance"),
    },
    {
      id: "engageFamily",
      text: t("reasonForLosingWeight.options.engageFamily"),
    },
    { id: "healthier", text: t("reasonForLosingWeight.options.healthier") },
    { id: "feelBetter", text: t("reasonForLosingWeight.options.feelBetter") },
    { id: "other", text: t("reasonForLosingWeight.options.other") },
  ];

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionId={QUESTION_ID}
      questionText={t("reasonForLosingWeight.questionText")}
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_REASON_FOR_LOSING_WEIGHT: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: (state) =>
    state.selectedProgram !== "MOOD" && qualifiesForNoomMed(state),
  component: ReasonForLosingWeightQuestion,
};
