import React, { useContext, useEffect, useMemo, useState } from "react";

import type { QuestionSection } from "@/components/survey/Question";
import {
  ErrorState,
  type ProgramOffering,
  ScreenedOutReason,
  RetriageFinishedStatus,
} from "@/constants";
import type { SurveyProgressBarState } from "@/hooks";
import { useAnalyticsIds } from "@/hooks/useAnalyticsIds";
import type {
  EligibilityInfo,
  EnrollmentInformation,
  Partner,
  RetriageEligibility,
  User,
  UserProfile,
} from "@/models";
import { toSha256 } from "@/utils/cryptography";

type EnrollmentType = "INITIAL_ENROLLMENT" | "RETRIAGE";
type SurveyMetadata = {
  name: "RECURRING_OUTCOMES";
  instance: string | undefined;
};

export type SurveyAnswer =
  | boolean
  | number
  | string
  | string[]
  | Date
  | null
  | undefined
  | Record<
      string,
      boolean | number | string | string[] | Date | null | Record<string, any>
    >;

export interface SurveyAnswers {
  [key: string]: SurveyAnswer | undefined;
}

export type AppContextType = {
  b2bAnonymousUserId: string;
  b2bIdentifiedUserId: string;
  eligibilityInfo?: EligibilityInfo;
  enrollmentInfo?: EnrollmentInformation;
  enrollmentType?: EnrollmentType;
  errorState: ErrorState;
  hasB2cAccount?: boolean;
  hashedEmail?: string;
  maskedEmail?: string;
  partnerInfo: Partner | null;
  questionSet: QuestionSection[];
  retriageEligibility?: RetriageEligibility;
  retriageFinishedStatus?: RetriageFinishedStatus;
  screenedOutReason?: ScreenedOutReason;
  selectedProgram: ProgramOffering | null;
  ssTriageUuid: string | null;
  surveyAnswers: SurveyAnswers;
  surveyMetadata?: SurveyMetadata;
  surveyProgressBarState: SurveyProgressBarState;
  userData?: User;
  userProfile: UserProfile | null;
  telehealthEligibleStates: string[] | null;
  setEligibilityInfo: React.Dispatch<EligibilityInfo>;
  setEnrollmentInfo: React.Dispatch<EnrollmentInformation>;
  setEnrollmentType: React.Dispatch<EnrollmentType>;
  setErrorState: React.Dispatch<ErrorState>;
  setHasB2cAccount: React.Dispatch<boolean | undefined>;
  setMaskedEmail: React.Dispatch<string>;
  setPartnerInfo: React.Dispatch<Partner | null>;
  setQuestionSet: React.Dispatch<QuestionSection[]>;
  setRetriageEligibility: React.Dispatch<RetriageEligibility | undefined>;
  setRetriageFinishedStatus: React.Dispatch<RetriageFinishedStatus | undefined>;
  setScreenedOutReason: React.Dispatch<ScreenedOutReason>;
  setSelectedProgram: React.Dispatch<ProgramOffering | null>;
  setSsTriageUuid: React.Dispatch<string | null>;
  setSurveyAnswers: React.Dispatch<SurveyAnswers>;
  setSurveyMetadata: React.Dispatch<SurveyMetadata>;
  setSurveyProgressBarState: React.Dispatch<SurveyProgressBarState>;
  setUserData: React.Dispatch<User>;
  setUserProfile: React.Dispatch<UserProfile | null>;
  setTelehealthEligibleStates: React.Dispatch<string[]>;
};

export const AppContext = React.createContext<AppContextType | undefined>(
  undefined,
);

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const {
    anonymousId: b2bAnonymousUserId,
    identifiableId: b2bIdentifiedUserId,
    setUserDetails: setAnalyticsUserDetails,
  } = useAnalyticsIds();
  const [eligibilityInfo, setEligibilityInfo] = useState<EligibilityInfo>();
  const [enrollmentInfo, setEnrollmentInfo] = useState<EnrollmentInformation>();
  const [enrollmentType, setEnrollmentType] = useState<EnrollmentType>();
  const [errorState, setErrorState] = useState<ErrorState>(ErrorState.DEFAULT);
  const [hasB2cAccount, setHasB2cAccount] = useState<boolean | undefined>();
  const [hashedEmail, setHashedEmail] = useState<string>();
  const [maskedEmail, setMaskedEmail] = useState<string>();
  const [partnerInfo, setPartnerInfo] = useState<Partner | null>(null);
  const [questionSet, setQuestionSet] = useState<QuestionSection[]>([]);
  const [retriageEligibility, setRetriageEligibility] =
    useState<RetriageEligibility>();
  const [screenedOutReason, setScreenedOutReason] =
    useState<ScreenedOutReason>();
  const [selectedProgram, setSelectedProgram] =
    useState<ProgramOffering | null>(null);
  const [ssTriageUuid, setSsTriageUuid] = useState<string | null>(null);
  const [surveyAnswers, setSurveyAnswers] = useState<SurveyAnswers>({});
  const [surveyProgressBarState, setSurveyProgressBarState] =
    useState<SurveyProgressBarState>({
      currentProgress: 0,
      currentQuestionId: "",
      lastProgress: 0,
      currentSectionDescription: "",
      currentSectionIndex: 0,
      percentages: [],
    });
  const [surveyMetadata, setSurveyMetadata] = useState<SurveyMetadata>();
  const [userData, setUserData] = useState<User>();
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [retriageFinishedStatus, setRetriageFinishedStatus] =
    useState<RetriageFinishedStatus>();

  const [telehealthEligibleStates, setTelehealthEligibleStates] = useState<
    string[] | null
  >(null);

  const email = surveyAnswers?.createAccount?.email;

  useEffect(() => {
    if (email) {
      setAnalyticsUserDetails({ email });
      toSha256(email).then((sha) => setHashedEmail(sha));
    } else {
      setAnalyticsUserDetails(undefined);
    }
  }, [setAnalyticsUserDetails, email]);

  const value: AppContextType = useMemo(
    () => ({
      b2bAnonymousUserId,
      b2bIdentifiedUserId,
      eligibilityInfo,
      enrollmentInfo,
      enrollmentType,
      errorState,
      hasB2cAccount,
      hashedEmail,
      maskedEmail,
      partnerInfo,
      questionSet,
      retriageEligibility,
      retriageFinishedStatus,
      screenedOutReason,
      selectedProgram,
      ssTriageUuid,
      surveyAnswers,
      surveyMetadata,
      surveyProgressBarState,
      userData,
      userProfile,
      telehealthEligibleStates,
      setEligibilityInfo,
      setEnrollmentInfo,
      setEnrollmentType,
      setErrorState,
      setHasB2cAccount,
      setMaskedEmail,
      setPartnerInfo,
      setQuestionSet,
      setRetriageEligibility,
      setRetriageFinishedStatus,
      setScreenedOutReason,
      setSelectedProgram,
      setSsTriageUuid,
      setSurveyAnswers,
      setSurveyMetadata,
      setSurveyProgressBarState,
      setUserData,
      setUserProfile,
      setTelehealthEligibleStates,
    }),
    [
      b2bAnonymousUserId,
      b2bIdentifiedUserId,
      eligibilityInfo,
      enrollmentInfo,
      enrollmentType,
      errorState,
      hasB2cAccount,
      hashedEmail,
      maskedEmail,
      partnerInfo,
      questionSet,
      retriageEligibility,
      retriageFinishedStatus,
      screenedOutReason,
      selectedProgram,
      ssTriageUuid,
      surveyAnswers,
      surveyMetadata,
      surveyProgressBarState,
      userData,
      userProfile,
      telehealthEligibleStates,
    ],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error("useAppContext must be used within a AppContextProvider");
  }
  return context;
};
