
import type {
  PostUserPolicyOptionsV3RequestProto_Body,
  PostUserPolicyOptionsV3ResponseProto,
} from "@noom/noom-contracts/noom_contracts/backend/privacy/user_policy_options";
import { Api } from "@noom/noomscape";

import axios from "axios";

Api.register(
  "account.submitConsent",
  async (
    data: PostUserPolicyOptionsV3RequestProto_Body,
  ): Promise<PostUserPolicyOptionsV3ResponseProto> => {
    const response = await axios({
      baseURL: window.ENV.SERVER_URL,
      data,
      method: "POST",
      url: "/userData/v3/consent/create",
      urlWithPathParams: "/userData/v3/consent/create",
    });

    return response.data;
  },
);
