import React from "react";
import { useTranslation } from "react-i18next";

import { useAppContext } from "@/contexts";
import {
  shouldAskBaselineOutcomes,
  shouldAskQuarterlyQuestion,
} from "@/utils/survey/outcomes";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "hadA1cTested";

declare module "@/contexts" {
  interface SurveyAnswers {
    hadA1cTested?: string;
  }
}

const HadA1cTestedQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { surveyMetadata } = useAppContext();
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    { id: "yes", text: t("hadA1cTested.options.yes") },
    { id: "no", text: t("hadA1cTested.options.no") },
    { id: "notSure", text: t("hadA1cTested.options.notSure") },
  ];

  const questionText =
    surveyMetadata?.name === "RECURRING_OUTCOMES"
      ? t("hadA1cTested.questionText.recurringOutcomes")
      : t("hadA1cTested.questionText.enrollment");

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionHelperText={t("hadA1cTested.questionHelperText")}
      questionId={QUESTION_ID}
      questionText={questionText}
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_HAD_A1C_TESTED: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo, selectedProgram }) =>
    selectedProgram !== "MOOD" && shouldAskBaselineOutcomes(partnerInfo),
  component: HadA1cTestedQuestion,
};

export const Q_OUTCOMES_HAD_A1C_TESTED: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ userProfile }) =>
    userProfile?.currentProgram !== "MOOD" &&
    shouldAskQuarterlyQuestion(userProfile),
  component: HadA1cTestedQuestion,
};
