import React from "react";
import { useTranslation } from "react-i18next";

import { HealthGoal } from "@/constants";
import { partnerOffersPrograms } from "@/utils/programs";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "healthGoal";

declare module "@/contexts" {
  interface SurveyAnswers {
    healthGoal?: HealthGoal;
  }
}

const HealthGoalQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    {
      id: HealthGoal.REDUCING_STRESS_ANXIOUS_FEELINGS,
      text: t("healthGoal.options.reducingStressAnxiousFeelings"),
    },
    {
      id: HealthGoal.LOSING_WEIGHT,
      text: t("healthGoal.options.losingWeight"),
    },
  ];

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionHelperText={t("healthGoal.questionHelperText")}
      questionId={QUESTION_ID}
      questionText={t("healthGoal.questionText")}
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_HEALTH_GOAL: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ enrollmentInfo, partnerInfo }) =>
    partnerOffersPrograms(["MOOD", "WEIGHT"], enrollmentInfo, partnerInfo),
  component: HealthGoalQuestion,
};
