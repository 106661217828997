import { parse } from "date-fns";

import { CivilDateProto } from "@noom/noom-contracts/noom_contracts/lib/time/civil_time";

import type { EligibilityCheckFormValues } from "@/components/forms/EligibilityCheckForm";
import { RetriageEligibility } from "@/models";

export const getEligibilityCheckFormValues = (
  retriageEligibility: RetriageEligibility,
): EligibilityCheckFormValues | undefined => {
  if (!retriageEligibility.eligibilityProperties) {
    return undefined;
  }

  const { firstName, lastName, dateOfBirth, participantId } =
    retriageEligibility.eligibilityProperties;
  // month is [0-11]
  const { year, month, day } = dateOfBirth || {};
  return {
    dob: year && month && day ? new Date(year, month - 1, day) : null,
    employeeId: participantId,
    firstName: firstName || "",
    lastName: lastName || "",
  };
};

export const parseEligibilityFileDate = (
  date?: CivilDateProto,
): Date | undefined =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-extra-non-null-assertion
  date ? new Date(date.year!!, date.month!! - 1, date.day) : undefined;
