import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import { Api } from "@noom/noomscape";
import {
  Box,
  CompassColor,
  Image,
  Loader,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import { NoomHeader } from "@/components/noom-branding";
import { buildDeeplink } from "@/constants";
import { useAppContext } from "@/contexts";
import { useQueryParams, useTrackEvent } from "@/hooks";
import { Partner } from "@/models";
import { captureException } from "@/utils/sentry";

/**
 * Landing page for users that attempt to open deep links on desktop.
 */
export const DeeplinkView: React.FC = () => {
  const { enrollmentSlug } = useQueryParams();
  const { partnerInfo, setPartnerInfo } = useAppContext();
  const { trackIdentifiedPageViewed } = useTrackEvent();
  const { t } = useTranslation();

  useEffect(() => {
    trackIdentifiedPageViewed("deeplink");

    if (isMobile) {
      window.location.replace(buildDeeplink());
    }
  }, []);

  useEffect(() => {
    const getPartnerInfo = () => {
      Api.call("partner.getPartnerBySlug", Api.api.partner.getPartnerBySlug, {
        slug: enrollmentSlug,
      })
        .then((partnerInformation: Partner) =>
          setPartnerInfo(partnerInformation),
        )
        .catch((e: Error) => captureException(e));
    };

    if (!partnerInfo) {
      getPartnerInfo();
    }
  }, [partnerInfo, enrollmentSlug, setPartnerInfo]);

  return isMobile ? (
    <Box>
      <NoomHeader />
      <Stack height="calc(100vh - 64px)" align="center" justify="center">
        <Loader size="xl" colorScheme="primary" />
      </Stack>
    </Box>
  ) : (
    <Box backgroundColor={CompassColor.offWhite}>
      <NoomHeader />
      <Box display="flex">
        <Stack align="center" w="50%" spacing={0}>
          <Stack
            align="center"
            justify="center"
            spacing={Spacing[12]}
            fontSize="32px"
            fontWeight="500"
            fontFamily="Untitled Serif"
            textAlign="center"
            height="calc(70vh - 64px)"
          >
            <Text>{t("deeplink.openLink")}</Text>
            <Box display="flex" alignItems="center" w="200px">
              <Box border={`0.5px solid ${CompassColor.black}`} flexGrow={1} />
              <Text margin="0 8px" fontWeight="400" fontSize="20px">
                {t("deeplink.or")}
              </Text>
              <Box border={`0.5px solid ${CompassColor.black}`} flexGrow={1} />
            </Box>
            <Text>{t("deeplink.scanQRCode")}</Text>
          </Stack>
          <Image height="30vh" src="/assets/img/qrCode.png" />
        </Stack>
        <Image
          src="/assets/img/foodBowls.png"
          objectFit="cover"
          height="calc(100vh - 64px)"
          w="50%"
          borderTopLeftRadius="100px"
        />
      </Box>
    </Box>
  );
};
