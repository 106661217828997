import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Button,
  Heading,
  Image,
  StackItem,
  Text,
} from "@noom/wax-component-library";

import { NoomEmailAddressLink } from "@/components/noom-branding";
import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { useTrackEvent } from "@/hooks";

import { BaseInterstitial } from "../../core";

const QUESTION_ID = "transitionExplainer";

const BoldListItem = styled.li`
  font-weight: 500;
`;

const Bullet = styled.li`
  list-style-type: disc;
  margin-left: 20px;
`;

const TransitionExplainer: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { trackIdentifiedPageViewed } = useTrackEvent();
  const { t } = useTranslation();

  useEffect(() => {
    trackIdentifiedPageViewed("transitionExplainer");
  }, []);

  return (
    <BaseInterstitial
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      questionId={QUESTION_ID}
      showBackButton
      width="600px"
    >
      <Image
        alt=""
        margin="auto"
        src="/assets/img/bell.svg"
        width="fit-content"
      />
      <Heading fontSize="24px" fontWeight={500} lineHeight="32px">
        {t("transitionExplainer.heading")}
      </Heading>
      <ol style={{ marginLeft: "20px" }}>
        <BoldListItem>
          {t("transitionExplainer.listItem1.description")}
        </BoldListItem>
        <ul>
          <Bullet>{t("transitionExplainer.listItem1.bullet1")}</Bullet>
          <Bullet>{t("transitionExplainer.listItem1.bullet2")}</Bullet>
        </ul>
        <br />
        <BoldListItem>
          {t("transitionExplainer.listItem2.description")}
        </BoldListItem>
        <ul>
          <Bullet>{t("transitionExplainer.listItem2.bullet")}</Bullet>
        </ul>
      </ol>
      <Text>
        <Trans
          components={{ supportEmail: NoomEmailAddressLink }}
          i18nKey="transitionExplainer.disclaimer"
        />
      </Text>
      <StackItem display="flex" justifyContent="center">
        <Button
          colorScheme="primary"
          isLoading={isSubmitting}
          onClick={() => {
            setIsSubmitting(true);
            onClickNext(null);
          }}
          size="xl"
          type="submit"
        >
          {t("transitionExplainer.ctaText")}
        </Button>
      </StackItem>
    </BaseInterstitial>
  );
};

export const Q_TRANSITION_EXPLAINER: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: () => true,
  component: TransitionExplainer,
};
