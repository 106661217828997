import React from "react";
import { useTranslation } from "react-i18next";

import { partnerOffersPrograms } from "@/utils/programs";
import { shouldShowDppQualification } from "@/utils/survey/retriage";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "education";

declare module "@/contexts" {
  interface SurveyAnswers {
    education?: string;
  }
}

const EducationQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const optionIds = [
    "lessThanHighSchool",
    "highSchool",
    "collegeNoDegree",
    "collegeDegree",
    "preferNotToSay",
  ];

  const options: QuestionOption[] = optionIds.map((id) => ({
    id,
    text: t(`education.options.${id}`),
  }));

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionId={QUESTION_ID}
      questionText={t("education.questionText")}
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_EDUCATION: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    enrollmentInfo,
    partnerInfo,
    selectedProgram,
    surveyAnswers: { dppEligible },
  }) =>
    partnerOffersPrograms(["DPP"], enrollmentInfo, partnerInfo) &&
    selectedProgram !== "MED" &&
    selectedProgram !== "MOOD" &&
    selectedProgram !== "NMPBA" &&
    dppEligible === true,
  component: EducationQuestion,
};

export const Q_RETRIAGE_EDUCATION: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: (state) => shouldShowDppQualification(state),
  component: EducationQuestion,
};
