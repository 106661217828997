import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Survey } from "@/components/survey/Survey";
import QuestionSets from "@/components/survey/questionSets/questionSets";
import { ErrorState, EnrollmentSuccessType } from "@/constants";
import { useAppContext } from "@/contexts";
import {
  useGoto,
  useQueryParams,
  useTransitionB2CAccount,
  useStoreOutcomes,
  useUpdateLastTriageTimestamp,
} from "@/hooks";
import { captureException } from "@/utils/sentry";

export const TransitionView: React.FC = () => {
  const { enrollmentInfo, selectedProgram, setErrorState } = useAppContext();
  const goto = useGoto();
  const { storeBaselineOutcomesAnswers } = useStoreOutcomes();
  const { transitionB2CAccount } = useTransitionB2CAccount();
  const { updateLastTriageTimestamp } = useUpdateLastTriageTimestamp();
  const { search } = useLocation();
  const navigate = useNavigate();

  if (!enrollmentInfo) {
    navigate({
      pathname: "/",
      search,
    });
  }

  const { upid } = useQueryParams();

  if (!upid) {
    navigate("/error");
  }

  const onSurveyComplete = async () => {
    try {
      // TODO(eric): Technically, this could throw B2B_INELIGIBLE error, but shouldn't happen in practice
      // as eligibility should have already been checked. Currently, we don't have a standalone Ineligible ErrorState,
      // but we should handle this error and send there when we do.
      const accessCode = await transitionB2CAccount(selectedProgram);

      if (!accessCode) return;

      try {
        // If this fails for some reason don't break execution
        await storeBaselineOutcomesAnswers(accessCode);
      } catch (e) {
        captureException(e);
      }

      try {
        await updateLastTriageTimestamp(accessCode, "ASSIGNED_INITIAL_OFFERING");
      } catch (e) {
        captureException(e);
      }

      goto.success(EnrollmentSuccessType.TRANSITIONED);
    } catch (e) {
      captureException(e);
      setErrorState(ErrorState.DEFAULT);
      navigate("/error");
    }
  };

  return (
    <Survey
      baseRoute="/transition"
      onSurveyComplete={onSurveyComplete}
      questionSet={QuestionSets.TRANSITION_SURVEY}
    />
  );
};
