import React from "react";
import { useTranslation } from "react-i18next";

import { shouldAskSDOH } from "@/utils/survey/sdoh";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../../Question";
import { MultipleChoiceQuestion } from "../../core";

const QUESTION_ID = "culturalImpacts";

declare module "@/contexts" {
  interface SurveyAnswers {
    culturalImpacts?: "always" | "occasionally" | "never";
  }
}

const CulturalImpactsQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    { id: "always", text: t("culturalImpacts.options.always") },
    {
      id: "occasionally",
      text: t("culturalImpacts.options.occasionally"),
    },
    { id: "never", text: t("culturalImpacts.options.never") },
  ];

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionId={QUESTION_ID}
      questionText={t("culturalImpacts.questionText")}
      questionHelperText={t("culturalImpacts.questionHelperText")}
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_CULTURAL_IMPACTS: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo, selectedProgram }) => shouldAskSDOH(partnerInfo, selectedProgram),
  component: CulturalImpactsQuestion,
};
