import { Api } from "@noom/noomscape";

import axios from "axios";


type AlertUserCoachRequest = {
  accessCode: string;
};

Api.register(
  "dpp.alertUserCoach",
  async ({ accessCode }: AlertUserCoachRequest) => {
    await axios({
      baseURL: window.ENV.SERVER_URL,
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      url: `dpp/v1/alertUserCoach/${accessCode}`,
      urlWithPathParams: "dpp/v1/alertUserCoach/{accessCode}",
    });
  },
);
