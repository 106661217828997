import { Formik, FormikHelpers } from "formik";
import React, { ChangeEvent, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import {
  Button,
  CompassColor,
  Heading,
  Image,
  Input,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import { NAMESPACES } from "@/app/i18n";
import { NoomEmailAddressLink } from "@/components/noom-branding";
import { useAppContext } from "@/contexts";
import { useSubmitPasscode, useTrackEvent } from "@/hooks";

type PasscodeInputFormValues = {
  passcode: string;
};

const initialValues: PasscodeInputFormValues = {
  passcode: "",
};

export const PasscodeInputForm = () => {
  const { partnerInfo } = useAppContext();
  const { search } = useLocation();
  const navigate = useNavigate();
  const submitPasscode = useSubmitPasscode();
  const { trackIdentifiedPageViewed } = useTrackEvent();
  const { t } = useTranslation(NAMESPACES.employerLanding);

  const isVpSponsor = partnerInfo?.configs?.ENROLL_VIRGIN_PULSE || false;
  const isPasscodeEnrollment = partnerInfo?.configs?.ENROLL_PASSCODE || false;

  const MAX_LENGTH = 50;
  const validationSchema = Yup.object().shape({
    passcode: Yup.string()
      .max(
        MAX_LENGTH,
        t("form.errors.maxLength", {
          label: "Passcode",
          maxLength: MAX_LENGTH,
        }),
      )
      .required(t("form.passcode.errors.required")),
  });

  const onSubmitPasscode = async (
    values: PasscodeInputFormValues,
    actions: FormikHelpers<PasscodeInputFormValues>,
  ) => {
    try {
      await submitPasscode(values.passcode, "MANUAL");
    } catch {
      actions.setFieldError("passcode", t("form.passcode.errors.invalid"));
    }
  };

  useEffect(() => {
    trackIdentifiedPageViewed("passcodeInput");
  }, []);

  return (
    <Stack spacing={Spacing[8]}>
      {isVpSponsor && (
        <Stack spacing={Spacing[0]}>
          <Heading
            fontSize="20px"
            fontWeight={500}
            lineHeight="26px"
            marginBottom="var(--spacing-s)"
          >
            {t("passcode.personifyHealth.heading")}
          </Heading>
          <Text size="lg">{t("passcode.personifyHealth.description")}</Text>
          <Button
            colorScheme="primary"
            marginTop="var(--spacing-l)"
            onClick={() =>
              navigate({
                pathname: "/personify-health",
                search,
              })
            }
            size="xl"
          >
            <Text color={CompassColor.white}>
              {t("passcode.personifyHealth.ctaText")}
            </Text>
            <Image
              alt="Personify Health"
              height="24px"
              marginLeft="6px"
              src="/assets/img/personifyHealthLogo.png"
            />
          </Button>
        </Stack>
      )}
      {isPasscodeEnrollment && (
        <Stack spacing={Spacing[0]}>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmitPasscode}
            validateOnBlur={false}
            validationSchema={validationSchema}
          >
            {({
              errors,
              getFieldProps,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                {isVpSponsor ? (
                  <Heading
                    fontSize="20px"
                    fontWeight={500}
                    lineHeight="26px"
                    marginBottom="var(--spacing-m)"
                  >
                    {t("passcode.headingSecondary")}
                  </Heading>
                ) : (
                  <Heading
                    fontSize="24px"
                    fontWeight={500}
                    lineHeight="32px"
                    marginBottom="var(--spacing-l)"
                  >
                    {t("passcode.headingPrimary")}
                  </Heading>
                )}
                <Input
                  {...getFieldProps("passcode")}
                  autoFocus={!isVpSponsor}
                  error={touched.passcode && errors.passcode}
                  label={t("form.passcode.label")}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFieldValue("passcode", e.target.value.toUpperCase())
                  }
                  placeholder={t("form.passcode.placeholder")}
                  size="lg"
                />
                <Button
                  colorScheme="primary"
                  isLoading={isSubmitting}
                  marginTop={
                    isVpSponsor ? "var(--spacing-m)" : "var(--spacing-xl)"
                  }
                  size="xl"
                  type="submit"
                >
                  {t("passcode.ctaText")}
                </Button>
              </form>
            )}
          </Formik>
        </Stack>
      )}
      <Text>
        <Trans
          i18nKey="support.messages.haveQuestions"
          values={{
            email: t("support.contact.email"),
          }}
        >
          Have a question? Drop us a line at
          {NoomEmailAddressLink}
        </Trans>
      </Text>
    </Stack>
  );
};
