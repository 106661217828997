import React from "react";
import { useTranslation } from "react-i18next";

import { SurveyAnswer, useAppContext } from "@/contexts";
import { calculateDppRiskScore } from "@/utils/dpp";
import { partnerOffersPrograms } from "@/utils/programs";
import { shouldShowDppQualification } from "@/utils/survey/retriage";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "physicalActivity";

declare module "@/contexts" {
  interface SurveyAnswers {
    physicalActivity?: string;
    riskScore?: number;
    dppEligible?: boolean;
  }
}

const PhysicalActivityQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { retriageEligibility } = useAppContext();
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    { id: "yes", text: t("physicalActivity.options.yes") },
    { id: "no", text: t("physicalActivity.options.no") },
  ];

  const calculateDppRiskAndProceed = async (answer: SurveyAnswer) => {
    const riskScore = calculateDppRiskScore({
      retriageEligibility,
      surveyAnswers: {
        ...surveyAnswers,
        [QUESTION_ID]: answer as string,
      },
    });

    onClickNext(answer, {
      riskScore,
      dppEligible: surveyAnswers.dppEligible !== false && riskScore >= 5,
    });
  };

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={calculateDppRiskAndProceed}
      options={options}
      questionId={QUESTION_ID}
      questionText={t("physicalActivity.questionText")}
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_PHYSICAL_ACTIVITY: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    enrollmentInfo,
    partnerInfo,
    selectedProgram,
    surveyAnswers: { dppEligible },
  }) =>
    partnerOffersPrograms(["DPP"], enrollmentInfo, partnerInfo) &&
    selectedProgram !== "MED" &&
    selectedProgram !== "MOOD" &&
    selectedProgram !== "NMPBA" &&
    dppEligible !== false,
  component: PhysicalActivityQuestion,
};

export const Q_RETRIAGE_PHYSICAL_ACTIVITY: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: (state) => shouldShowDppQualification(state),
  component: PhysicalActivityQuestion,
};
