import React, { ReactNode } from "react";

import {
  CompassColor,
  Image,
  useMediaQuery,
} from "@noom/wax-component-library";

import { BaseInterstitial } from "@/components/survey/questionTypes/core/BaseInterstitial";

type InformationalScreenProps = {
  children: ReactNode;
  questionId: string;
  backgroundColor?: CompassColor;
};

export const InformationalScreen: React.FC<InformationalScreenProps> = ({
  children,
  questionId,
  backgroundColor,
}) => {
  const [isBrowser] = useMediaQuery("(min-width: 1160px)");

  return (
    <BaseInterstitial
      backgroundColor={backgroundColor || CompassColor.blueberry}
      color={CompassColor.white}
      onClickBack={() => null}
      onClickNext={() => Promise.resolve()}
      questionId={questionId}
      width={isBrowser ? "600px" : undefined}
    >
      <Image
        height={{ base: "48px", lg: "64px" }}
        margin="var(--spacing-l) 0px var(--spacing-s) 0px"
        src="/assets/img/diamond-tarocco.svg"
      />
      {children}
    </BaseInterstitial>
  );
};
