import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  Button,
  CompassColor,
  Image,
  Loader,
  useMediaQuery,
} from "@noom/wax-component-library";

import {
  defaultEligibilityCheckFormValues,
  EligibilityCheckForm,
  EligibilityCheckFormValues,
} from "@/components/forms/EligibilityCheckForm";
import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { DOB_FORMAT, RetriageFinishedStatus } from "@/constants";
import { useAppContext } from "@/contexts";
import {
  useGoto,
  useQueryParams,
  useRetriageEligibility,
  useTrackEvent,
} from "@/hooks";
import { getAutologinData } from "@/utils/cookies";
import { getEligibilityCheckFormValues } from "@/utils/eligibility";
import { withPiiQueryParams } from "@/utils/pixels/urlParamsPii";

import { BaseInterstitial, BaseOutline, BaseQuestion } from "../core";

const QUESTION_ID = "retriageEligibilityCheck";

declare module "@/contexts" {
  interface SurveyAnswers {
    retriageEligibilityCheck?: EligibilityCheckFormValues;
  }
}

const RetriageEligibilityCheckQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { partnerInfo, retriageEligibility } = useAppContext();
  const { retriageFinished } = useGoto();
  const [isBrowser] = useMediaQuery("(min-width: 1160px)");
  const partnerId = partnerInfo?.id;
  const {
    resetRetriageEligibility,
    verificationProperties,
    setVerificationProperties,
    isLoadingRetriageEligibility,
    checkingAutologinData,
  } = useRetriageEligibility(partnerId);
  const queryParams = useQueryParams();
  const { trackAnonEvent } = useTrackEvent();
  const [initialValues, setInitialValues] = useState(
    surveyAnswers[QUESTION_ID] || defaultEligibilityCheckFormValues,
  );
  const { t } = useTranslation();

  const autologindata = getAutologinData();

  const onSubmit = async (values: EligibilityCheckFormValues) => {
    if (isLoadingRetriageEligibility || !partnerId) {
      return;
    }
    setVerificationProperties({
      dateOfBirth: values?.dob
        ? format(values.dob as Date, DOB_FORMAT)
        : undefined,
      firstName: values.firstName,
      lastName: values.lastName,
      participantId: values?.employeeId,
    });
  };

  useEffect(() => {
    if (!retriageEligibility) {
      return;
    }

    const { currentProgram, eligibilityReason } = retriageEligibility;

    const isEligible = eligibilityReason === "ELIGIBLE";

    trackAnonEvent("b2bEnrollmentAnonEligibilityChecked", {
      eligibleForEnrollment: isEligible,
      eligibilityStatus: eligibilityReason,
      currentProgram: currentProgram || "PROGRAM_UNSPECIFIED",
    });

    if (currentProgram === "MED") {
      retriageFinished(RetriageFinishedStatus.ALREADY_MED);
      return;
    }

    if (eligibilityReason === "RECENTLY_RETRIAGED") {
      retriageFinished(RetriageFinishedStatus.RECENTLY_RETRIAGED);
      return;
    }

    if (isEligible) {
      // save form values in case user comes back
      onClickNext(
        getEligibilityCheckFormValues(retriageEligibility) || initialValues,
      );
    }
    // (osman): adding a dependency on trackAnonEvent() here is causing the effect
    // to run on every render. Perhaps trackAnonEvent needs to be cached with useMemo
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retriageEligibility]);

  useEffect(() => {
    // if we have autlogindata, do not reset the eligibliity
    // data, as it was automatically loaded from autologin data
    if (retriageEligibility && !autologindata) {
      resetRetriageEligibility();
    }
    const queryParamsWithPii = withPiiQueryParams(queryParams);
    trackAnonEvent("b2bEnrollmentAnonTriageFlowStarted", {
      leadSource: queryParamsWithPii.leadSource,
      utmSource: queryParamsWithPii.utm_source,
      utmMedium: queryParamsWithPii.utm_medium,
      utmCampaign: queryParamsWithPii.utm_campaign,
      utmTerm: queryParamsWithPii.utm_term,
      utmContent: queryParamsWithPii.utm_content,
    });
  }, []);

  if (
    retriageEligibility &&
    retriageEligibility.eligibilityReason !== "ELIGIBLE" &&
    // if we don't have verificationProperties, we verified using the autologin cookie set by the mobile app
    // and shouldn't show this error messaging
    verificationProperties
  ) {
    return (
      <BaseInterstitial
        backgroundColor={CompassColor.blueberry}
        color={CompassColor.white}
        onClickBack={() => null}
        onClickNext={() => Promise.resolve()}
        questionId={QUESTION_ID}
        width={isBrowser ? "600px" : undefined}
      >
        <Image
          height={{ base: "48px", lg: "64px" }}
          margin="var(--spacing-l) 0px var(--spacing-s) 0px"
          src="/assets/img/diamond-tarocco.svg"
        />
        <Trans i18nKey="retriageEligibilityCheck.ineligible">
          <strong>We can’t find an account matching that information.</strong>
          <br />
          <br />
          Please try again. If you still have trouble, contact
          partnersupport@noom.com with your name and the email address
          associated with your account.
        </Trans>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          marginTop="var(--spacing-xl)"
        >
          <Button
            colorScheme="primary"
            onClick={resetRetriageEligibility}
            size="xl"
          >
            {t("retriageEligibilityCheck.ctaText")}
          </Button>
        </Box>
      </BaseInterstitial>
    );
  }

  if (checkingAutologinData) {
    return (
      <BaseOutline>
        <Box m="auto">
          <Loader colorScheme="primary" />
        </Box>
      </BaseOutline>
    );
  }
  return (
    <BaseQuestion
      hideHeader
      onClickBack={onClickBack}
      questionId={QUESTION_ID}
      questionText={t("retriageEligibilityCheck.questionText")}
    >
      <EligibilityCheckForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        pageId="retriageEligibilityCheck"
        isLoading={isLoadingRetriageEligibility}
      />
    </BaseQuestion>
  );
};

export const Q_RETRIAGE_ELIGIBILITY_CHECK: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: () => true,
  component: RetriageEligibilityCheckQuestion,
};
