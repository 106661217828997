import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Trans } from "react-i18next";

import {
  Box,
  Button,
  Heading,
  Link,
  Spacing,
  Stack,
} from "@noom/wax-component-library";

import { InformationalScreen } from "@/components/survey/questionTypes/core";
import { RetriageFinishedStatus } from "@/constants";
import { useAppContext } from "@/contexts";
import { useGoto, useTrackEvent, useUpdateLastTriageTimestamp } from "@/hooks";
import { getAutologinData } from "@/utils/cookies";

const QUESTION_ID = "retriageFinished";

export const RetriageFinished: React.FC = () => {
  const { retriageEligibility: { accessCode } = {}, retriageFinishedStatus } =
    useAppContext();
  const autologinData = getAutologinData();
  const { notFound, openApp } = useGoto();
  const { updateLastTriageTimestamp } = useUpdateLastTriageTimestamp();
  const { trackAnonEvent } = useTrackEvent();

  useEffect(() => {
    if (
      !retriageFinishedStatus &&
      window.ENV.NOOM_ENVIRONMENT !== "development"
    ) {
      notFound();
      return;
    }

    if (retriageFinishedStatus) {
      trackAnonEvent("b2bEnrollmentAnonUserExcluded", {
        exclusionReason: retriageFinishedStatus,
      });
    }

    if (
      retriageFinishedStatus !== RetriageFinishedStatus.RECENTLY_RETRIAGED &&
      accessCode
    ) {
      updateLastTriageTimestamp(accessCode, "KEPT_SAME_OFFERING");
    }
  }, [retriageFinishedStatus]);

  const supportLink = (
    <Link colorScheme="info" href="mailto:partnersupport@noom.com">
      partnersupport@noom.com
    </Link>
  );

  return (
    <InformationalScreen questionId={QUESTION_ID}>
      <Stack gap={Spacing[5]}>
        <Heading fontSize="24px" fontWeight={500}>
          <Trans
            i18nKey="retriageFinished.title"
            context={retriageFinishedStatus || "ALREADY_MED"}
          />
        </Heading>
        <Box>
          <Trans
            i18nKey="retriageFinished.message"
            context={retriageFinishedStatus || "ALREADY_MED"}
            components={{
              supportLink,
            }}
          />
        </Box>
      </Stack>
      {isMobile && (
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          marginTop="var(--spacing-xl)"
        >
          <Button
            colorScheme="primary"
            onClick={() => openApp(autologinData)}
            padding="unset"
            size="xl"
            whiteSpace="unset"
          >
            <Trans i18nKey="retriageFinished.cta" />
          </Button>
        </Box>
      )}
    </InformationalScreen>
  );
};
