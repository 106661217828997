import {
  RetriageEligibilityByEligibilityInfoRequestProto_Body,
  RetriageEligibilityForAccessCodeRequestProto,
  RetriageEligibilityResponseProto,
} from "@noom/noom-contracts/noom_contracts/backend/partner/service/partner_eligibility_service";
import { Api } from "@noom/noomscape";

import axios from "axios";

Api.register(
  "partner.retriageEligibilityByEligibilityInfo",
  async (data: RetriageEligibilityByEligibilityInfoRequestProto_Body) => {
    const response = await axios({
      baseURL: window.ENV.SERVER_URL,
      data,
      method: "POST",
      url: "/partners/retriageEligibility",
      urlWithPathParams: "/partners/retriageEligibility",
    });

    return response.data as RetriageEligibilityResponseProto;
  },
);

Api.register(
  "partner.retriageEligibilityForAccessCode",
  async ({
    accessCode,
    accessToken,
  }: RetriageEligibilityForAccessCodeRequestProto & {
    accessToken: string;
  }) => {
    const response = await axios({
      baseURL: window.ENV.SERVER_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "GET",
      url: `/partners/retriageEligibility/${accessCode}`,
      urlWithPathParams: "/partners/retriageEligibility/{accessCode}",
    });

    return response.data as RetriageEligibilityResponseProto;
  },
);
