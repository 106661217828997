/**
 * Endpoint for validating address
 *
 * Copyright (C) 2022 Noom, Inc.
 * @author eric
 */


import { Api } from "@noom/noomscape";

import { Address } from "@/models";

import axios from "axios";

Api.register("address.validate", async ({ address }: { address: Address }) => {
  const response = await axios({
    url: "/billing/v2/billingAddress:validate",
    urlWithPathParams: "/billing/v2/billingAddress:validate",
    baseURL: window.ENV.SERVER_URL,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      // CoachServer expects an address to be passed under "billingAddress"
      billingAddress: address,
    },
  });

  return response.data as Address;
});
