import React from "react";
import { useTranslation } from "react-i18next";

import { useAppContext } from "@/contexts";
import { partnerOffersPrograms } from "@/utils/programs";
import { shouldShowDppQualification } from "@/utils/survey/retriage";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { CheckboxQuestion } from "../core";

const QUESTION_ID = "prediabetesHistory";

declare module "@/contexts" {
  interface SurveyAnswers {
    prediabetesHistory?: string[];
  }
}

const PrediabetesQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { partnerInfo } = useAppContext();
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    { id: "prediabetes", text: t("prediabetesHistory.options.prediabetes") },
    { id: "a1c", text: t("prediabetesHistory.options.a1c") },
    {
      id: "fastingBloodSugarLevel",
      text: t("prediabetesHistory.options.fastingBloodSugarLevel", {
        context: partnerInfo?.configs.MEDICARE_PARTNER ? "medicare" : "",
      }),
    },
    { id: "none", text: t("prediabetesHistory.options.none") },
  ];

  return (
    <CheckboxQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionId={QUESTION_ID}
      questionText={t("prediabetesHistory.questionText")}
      selectedItems={surveyAnswers[QUESTION_ID] || []}
    />
  );
};

export const Q_PREDIABETES_HISTORY: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    enrollmentInfo,
    partnerInfo,
    selectedProgram,
    surveyAnswers: { dppEligible },
  }) =>
    partnerOffersPrograms(["DPP"], enrollmentInfo, partnerInfo) &&
    selectedProgram !== "MED" &&
    selectedProgram !== "MOOD" &&
    selectedProgram !== "NMPBA" &&
    dppEligible !== false,
  component: PrediabetesQuestion,
};

export const Q_RETRIAGE_PREDIABETES_HISTORY: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: (state) => shouldShowDppQualification(state),
  component: PrediabetesQuestion,
};
