import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Link } from "@noom/wax-component-library";

import { buildDeeplink } from "@/constants";

type ContinueToDownloadButtonProps = {
  email?: string;
};

export const ContinueToDownloadButton: React.FC<
  ContinueToDownloadButtonProps
> = ({ email }) => {
  const { t } = useTranslation();

  return (
    <Link href={buildDeeplink(email)} isExternal>
      <Button as="a" colorScheme="primary" size="xl">
        {t("success.continueToDownload")}
      </Button>
    </Link>
  );
};
