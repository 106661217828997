import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Button, Spacing, Stack } from "@noom/wax-component-library";

import { InputWithSuffix } from "@/components/form";
import { enforcePositiveInt } from "@/components/form/utils";
import { shouldShowGlp1WeightQuestion } from "@/utils/survey/glp1";

import { QuestionComponentProps, QuestionDefinition } from "../../Question";
import { BaseQuestion } from "../core";

const QUESTION_ID = "glp1Weight";

declare module "@/contexts" {
  interface SurveyAnswers {
    glp1Weight?: number;
  }
}

type Glp1WeightFormValues = {
  glp1Weight?: number;
};

const Glp1WeightQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const initialValues: Glp1WeightFormValues = {
    glp1Weight: surveyAnswers.glp1Weight,
  };

  const validationSchema = Yup.object({
    glp1Weight: Yup.number()
      .required(t("form.weight.errors.required"))
      .positive(t("form.weight.errors.invalid"))
      .max(740, t("form.weight.errors.invalid")),
  });

  return (
    <BaseQuestion
      onClickBack={onClickBack}
      questionId={QUESTION_ID}
      questionText={t("glp1Weight.questionText")}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={({ glp1Weight }: Glp1WeightFormValues) => {
          if (glp1Weight === undefined) {
            return;
          }

          onClickNext(glp1Weight);
        }}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({ errors, getFieldProps, handleSubmit, touched }) => (
          <Form onSubmit={handleSubmit}>
            <Stack spacing={Spacing[8]}>
              <InputWithSuffix
                {...getFieldProps("glp1Weight")}
                autoFocus
                error={touched.glp1Weight && errors.glp1Weight}
                onKeyDown={enforcePositiveInt}
                suffix="lbs."
                type="number"
              />
              <Button colorScheme="primary" size="xl" type="submit">
                {t("glp1Weight.ctaText")}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </BaseQuestion>
  );
};

export const Q_GLP1_WEIGHT: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: (state) => shouldShowGlp1WeightQuestion(state),
  component: Glp1WeightQuestion,
};
