import { Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Spacing, Stack, Text } from "@noom/wax-component-library";

import { HeightWeightForm, HeightWeightSchema } from "@/components/forms";
import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { ScreenedOutReason } from "@/constants";
import { useAppContext } from "@/contexts";
// import { RetriageEligibility } from "@/models";
import { calculateBMI } from "@/utils/calculateBMI";
import { isCareFirstPartner } from "@/utils/partners";
// import { getWeightInLbs } from "@/utils/retriage";
import { shouldShowCardiometabolicQuestionForRetriage } from "@/utils/survey/retriage";

import { BaseQuestion } from "../core";

const QUESTION_ID = "heightWeight";

type FormValues = {
  heightFeetPortion?: number;
  heightInchesPortion?: number;
  weight?: number;
};

declare module "@/contexts" {
  interface SurveyAnswers {
    heightWeight?: FormValues;
  }
}

const getDefaultInitialValues = () // retriageEligibility?: RetriageEligibility,
: FormValues => {
  let weight: FormValues["weight"];

  // disabled until we can autopopulate height as well
  // see https://noomhq.atlassian.net/browse/B2B-2390
  // if (retriageEligibility?.userProfile) {
  //   weight = getWeightInLbs(retriageEligibility.userProfile);
  // }

  return {
    heightFeetPortion: undefined,
    heightInchesPortion: undefined,
    weight: weight ? Math.round(weight) : undefined,
  };
};

const HeightWeightQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { enrollmentType, partnerInfo, retriageEligibility } = useAppContext();
  const [shouldShowConfirmation, setShouldShowConfirmation] = useState(false);
  const { t } = useTranslation();

  const { liabilityWaiverEatingDisorder } = surveyAnswers;
  const hasAlreadySignedLiabilityWaiver = !!liabilityWaiverEatingDisorder;

  const handleAnswerBeforeProceeding = (values: FormValues) => {
    if (
      values.heightFeetPortion === undefined ||
      values.heightInchesPortion === undefined ||
      values.weight === undefined
    ) {
      return;
    }

    const bmi = calculateBMI(
      values.heightFeetPortion,
      values.heightInchesPortion,
      values.weight,
    );

    if (
      (bmi < 18.5 && enrollmentType !== "INITIAL_ENROLLMENT") ||
      (isCareFirstPartner(partnerInfo) && bmi < 23)
    ) {
      onClickNext(values, {}, ScreenedOutReason.BMI_TOO_LOW);
      return;
    }

    if (
      enrollmentType === "INITIAL_ENROLLMENT" &&
      bmi < 18.5 &&
      !hasAlreadySignedLiabilityWaiver &&
      !shouldShowConfirmation
    ) {
      setShouldShowConfirmation(true);
      return;
    }

    if (bmi < 23) {
      onClickNext(values, { dppEligible: false });
    } else {
      onClickNext(values);
    }
  };

  const initialValues = surveyAnswers[QUESTION_ID] || getDefaultInitialValues();

  const retriageQuestionTextContext =
    retriageEligibility?.didVerifyUsingAutologinData ? "firstTouch" : "";

  const questionText =
    enrollmentType === "RETRIAGE"
      ? t("heightWeight.questionText.retriage", {
          context: retriageQuestionTextContext,
        })
      : t("heightWeight.questionText.enrollment");

  let questionHelperText = "";
  if (enrollmentType === "RETRIAGE") {
    questionHelperText = t("heightWeight.questionHelperText.retriage");
  } else if (enrollmentType === "INITIAL_ENROLLMENT") {
    questionHelperText = t("heightWeight.questionHelperText.enrollment");
  }

  return (
    <BaseQuestion
      hideBackButton
      onClickBack={onClickBack}
      questionHelperText={questionHelperText}
      questionId={QUESTION_ID}
      questionText={questionText}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleAnswerBeforeProceeding(values)}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={HeightWeightSchema}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Stack spacing={Spacing[4]}>
              <HeightWeightForm focusHeightFeet />
              {shouldShowConfirmation && (
                <Text>{t("heightWeight.confirmation")}</Text>
              )}
              <Button colorScheme="primary" size="xl" type="submit">
                Next
              </Button>
            </Stack>
          </form>
        )}
      </Formik>
    </BaseQuestion>
  );
};

export const Q_HEIGHT_WEIGHT: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ selectedProgram }) => selectedProgram !== "MOOD",
  component: HeightWeightQuestion,
};

export const Q_RETRIAGE_HEIGHT_WEIGHT: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ retriageEligibility }) =>
    shouldShowCardiometabolicQuestionForRetriage(retriageEligibility),
  component: HeightWeightQuestion,
};
