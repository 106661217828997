
import type {
  GetUserPolicyOptionsV3RequestProto,
  GetUserPolicyOptionsV3ResponseProto,
} from "@noom/noom-contracts/noom_contracts/backend/privacy/user_policy_options";
import { Api } from "@noom/noomscape";

import axios from "axios";

Api.register(
  "account.getConsent",
  async (
    data: GetUserPolicyOptionsV3RequestProto,
  ): Promise<GetUserPolicyOptionsV3ResponseProto> => {
    const response = await axios({
      baseURL: window.ENV.SERVER_URL,
      data,
      method: "POST",
      url: "/userData/v3/consent/get",
      urlWithPathParams: "/userData/v3/consent/get",
    });

    return response.data;
  },
);
