/**
 * Endpoint for enrolling a B2B user in Med program
 */


import { Api } from "@noom/noomscape";

import { MedEnrollmentData } from "@/models";

import axios from "axios";

Api.register(
  "account.enrollMed",
  async ({ medEnrollmentData }: { medEnrollmentData: MedEnrollmentData }) =>
    axios({
      url: `/telehealth/${medEnrollmentData.accessCode}:enrollB2bUser?notifySupportOnFail=true`,
      urlWithPathParams: "/telehealth/{accessCode}:enrollB2bUser",
      baseURL: window.ENV.SERVER_URL,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: medEnrollmentData,
    }),
);
