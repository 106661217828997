import { Formik, FormikHelpers } from "formik";
import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { Api } from "@noom/noomscape";
import {
  Button,
  Heading,
  Input,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import { NAMESPACES } from "@/app/i18n";
import { NoomEmailAddressLink } from "@/components/noom-branding";
import { ErrorState } from "@/constants";
import { useAppContext } from "@/contexts";
import { useTrackEvent } from "@/hooks";
import { captureException } from "@/utils/sentry";

type UpidInputFormValues = {
  upid: string;
};

const initialValues: UpidInputFormValues = {
  upid: "",
};

export const UpidInputForm = () => {
  const { setErrorState } = useAppContext();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { trackIdentifiedPageViewed } = useTrackEvent();
  const { t } = useTranslation(NAMESPACES.employerLanding);

  const MAX_LENGTH = 150;
  const validationSchema = Yup.object().shape({
    upid: Yup.string()
      .max(
        MAX_LENGTH,
        t("form.errors.maxLength", {
          label: "UPID",
          maxLength: MAX_LENGTH,
        }),
      )
      .required(t("form.upid.errors.required")),
  });

  const onSubmitUpid = async (
    values: UpidInputFormValues,
    actions: FormikHelpers<UpidInputFormValues>,
  ) => {
    const { upid } = values;

    try {
      await Api.call(
        "upid.getEnrollmentInformation",
        Api.api.upid.getEnrollmentInformation,
        {
          upid,
        },
      );

      const qs = new URLSearchParams(search);
      qs.append("upid", upid);
      navigate(`/?${qs.toString()}`);
    } catch (e) {
      if ((e as Error).message === "Request failed with status code 404") {
        actions.setFieldError("upid", t("form.upid.errors.invalid"));
        return;
      }

      captureException(e);
      setErrorState(ErrorState.DEFAULT);
      navigate("/error");
    }
  };

  useEffect(() => {
    trackIdentifiedPageViewed("upidInput");
  }, []);

  return (
    <Stack spacing={Spacing[8]}>
      <Stack spacing={Spacing[0]}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitUpid}
          validateOnBlur={false}
          validationSchema={validationSchema}
        >
          {({ errors, getFieldProps, handleSubmit, isSubmitting, touched }) => (
            <form onSubmit={handleSubmit}>
              <Heading
                fontSize="24px"
                fontWeight={500}
                lineHeight="32px"
                marginBottom="var(--spacing-l)"
              >
                {t("upid.heading")}
              </Heading>
              <Input
                {...getFieldProps("upid")}
                autoFocus
                error={touched.upid && errors.upid}
                label={t("form.upid.label")}
                placeholder={t("form.upid.placeholder")}
                size="lg"
              />
              <Button
                colorScheme="primary"
                isLoading={isSubmitting}
                marginTop="var(--spacing-xl)"
                size="xl"
                type="submit"
              >
                {t("upid.ctaText")}
              </Button>
            </form>
          )}
        </Formik>
      </Stack>
      <Text>
        <Trans
          components={{ supportEmail: NoomEmailAddressLink }}
          i18nKey="upid.contactEmployerText"
          ns={NAMESPACES.employerLanding}
        />
      </Text>
    </Stack>
  );
};
