/* eslint-disable no-console */
import libphonenumber from "google-libphonenumber";

import { B2C_PARTNERS, CAREFIRST_PARTNER_ID } from "@/constants/Partners";
import type { Partner } from "@/models";

/**
 * Formats the partner's custom support link into a more user friendly string.
 * Supports https, mailto, and tel URIs
 *
 * Examples:
 *
 * "https://www.noom.com" -> "https://www.noom.com"
 *
 * "mailto:partnersupport@noom.com" -> "partnersupport@noom.com"
 *
 * "tel:+18882665071" -> "(888) 266-5071"
 *
 * @param supportLink custom support link for the partner
 * @returns formatted support link, or empty string
 */
export const formatCustomSupportLink = (supportLink: string) => {
  const parts = supportLink.split(":", 2);

  if (parts.length !== 2) {
    console.error(`Invalid custom support link: ${supportLink}`);
    return "";
  }

  const [scheme, value] = parts;

  if (scheme === "https") {
    return supportLink;
  }

  if (scheme === "mailto") {
    return value;
  }

  if (scheme === "tel") {
    const phoneNumberUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const PNF = libphonenumber.PhoneNumberFormat;

    try {
      const number = phoneNumberUtil.parse(supportLink, "US");
      return phoneNumberUtil.format(number, PNF.NATIONAL);
    } catch (error) {
      console.error(`Invalid phone number: ${supportLink}`, error);
      return "";
    }
  }

  console.error(`Unsupported URI scheme: ${supportLink}`);
  return "";
};

export const isB2CPartner = (partnerInfo: Partner | null) =>
  B2C_PARTNERS.includes(partnerInfo?.id as number);

export const isCareFirstPartner = (partnerInfo: Partner | null) =>
  partnerInfo?.id === CAREFIRST_PARTNER_ID;
