import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { B2BEnrollmentEnumsProto_AccountType } from "@noom/noom-contracts/noom_contracts/events/web/b2b/shared";

import { EnrollmentSuccessType } from "@/constants";
import { useAppContext } from "@/contexts";
import { useGoto, useTrackEvent } from "@/hooks";
import { clearAnalyticsCookies } from "@/hooks/useAnalyticsIds";

import { DownloadView } from "./DownloadView";
import { ProgressView } from "./ProgressView";

export const SuccessView: React.FC = () => {
  const { b2bIdentifiedUserId, selectedProgram } = useAppContext();
  const { search } = useLocation();
  const [isDoneShowingProgress, setIsDoneShowingProgress] = useState(false);
  const { trackIdentifiedEvent } = useTrackEvent();
  const goto = useGoto();

  const type =
    (new URLSearchParams(search).get("type") as EnrollmentSuccessType) ||
    EnrollmentSuccessType.CREATED;

  useEffect(() => {
    // Clear analytics cookies after successful enrollment
    // to avoid data issues if the session is re-used by different users.
    clearAnalyticsCookies();
  }, []);

  useEffect(() => {
    const accountTypeMap: Record<
      EnrollmentSuccessType,
      B2BEnrollmentEnumsProto_AccountType
    > = {
      [EnrollmentSuccessType.CREATED]: "CREATED",
      [EnrollmentSuccessType.TRANSITIONED]: "TRANSITIONED",
      [EnrollmentSuccessType.UPGRADED]: "UPGRADED",
    };

    trackIdentifiedEvent("b2bEnrollmentIdentifiedSuccessPageViewed", {
      accountType: accountTypeMap[type],
    });
  }, [type]);

  useEffect(() => {
    if (selectedProgram === "MED" && isDoneShowingProgress) {
      goto.medIntake(b2bIdentifiedUserId);
    }
  }, [isDoneShowingProgress]);

  return isDoneShowingProgress && selectedProgram !== "MED" ? (
    <DownloadView enrollmentSuccessType={type} />
  ) : (
    <ProgressView
      enrollmentSuccessType={type}
      selectedProgram={selectedProgram}
      setIsDoneShowingProgress={setIsDoneShowingProgress}
    />
  );
};
