import { defaultFieldStyles } from "../defaultFieldStyles";

export const Input = {
  defaultProps: {
    size: "lg",
    variant: "outline",
  },
  variants: {
    outline: {
      field: {
        ...defaultFieldStyles,
      },
    },
  },
};
