import React from "react";
import { useTranslation } from "react-i18next";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "@/components/survey/Question";
import { MultipleChoiceQuestion } from "@/components/survey/questionTypes/core";
import {
  BloodGlucoseTrackingSelection, HealthConditionOptionId, PrimaryCareProviderSelection
} from "@/constants";

const QUESTION_ID = "bloodGlucoseTracking";

declare module "@/contexts" {
  interface SurveyAnswers {
    bloodGlucoseTracking?: BloodGlucoseTrackingSelection;
  }
}

const BloodGlucoseTrackingQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    {
      id: BloodGlucoseTrackingSelection.YES,
      text: t("bloodGlucoseTracking.options.yes"),
    },
    {
      id: BloodGlucoseTrackingSelection.NO,
      text: t("bloodGlucoseTracking.options.no"),
    },
  ];

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionId={QUESTION_ID}
      questionText={t("bloodGlucoseTracking.questionText")}
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_BLOOD_GLUCOSE_TRACKING: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo, surveyAnswers: { primaryCareProvider, healthConditions } }) =>
    !!(partnerInfo?.configs.OFFERS_DIABETES_MANAGEMENT &&
      healthConditions?.includes(HealthConditionOptionId.TYPE2_DIABETES) &&
      primaryCareProvider === PrimaryCareProviderSelection.YES),
  component: BloodGlucoseTrackingQuestion,
};
