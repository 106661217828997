import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  CompassColor,
  Image,
  Link,
  Loader,
  Spacing,
  Text,
  useMediaQuery,
} from "@noom/wax-component-library";

import { QuestionDefinition } from "@/components/survey/Question";
import {
  BaseInterstitial,
  BaseOutline,
} from "@/components/survey/questionTypes/core";
import { HealthGoal, ScreenedOutReason } from "@/constants";
import { useAppContext } from "@/contexts";
import { useTrackEvent } from "@/hooks";
import { getDisplayName } from "@/models";
import {
  formatCustomSupportLink,
  isB2CPartner,
  isCareFirstPartner,
} from "@/utils/partners";
import { partnerOffersPrograms } from "@/utils/programs";

// This questions is only reachable via direct navigation. Use caution when updating this ID.
const QUESTION_ID = "ineligible";

type ScreenedOutReasonMoodWhitelist = Extract<
  ScreenedOutReason,
  ScreenedOutReason.PREGNANT
>;

type ScreenedOutReasonB2CWhitelist = Extract<
  ScreenedOutReason,
  | ScreenedOutReason.PREGNANT
  | ScreenedOutReason.EATING_DISORDER
  | ScreenedOutReason.BMI_TOO_LOW
>;

const supportButton = (
  <Button
    colorScheme="primary"
    size="md"
    borderRadius="8px"
    margin="var(--spacing-m) 0"
    _focus={{
      boxShadow: "none",
    }}
  />
);

export const ScreenedOut: React.FC = () => {
  const {
    enrollmentInfo,
    partnerInfo,
    screenedOutReason,
    surveyAnswers,
    setSelectedProgram,
    setSurveyAnswers,
  } = useAppContext();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [showNotInterestedInMood, setShowNotInterestedInMood] = useState(false);
  const { trackAnonEvent, trackIdentifiedEvent } = useTrackEvent();
  useTranslation();

  const [isBrowser] = useMediaQuery("(min-width: 1160px)");

  const isAccolade = partnerInfo?.configs?.ACCOLADE_PARTNER || false;
  const customSupportLink =
    partnerInfo?.configs?.CUSTOM_SUPPORT_LINK ||
    "mailto:partnersupport@noom.com";
  const formattedSupportLink = formatCustomSupportLink(customSupportLink);

  const partnerOffersMood = partnerOffersPrograms(
    ["MOOD"],
    enrollmentInfo,
    partnerInfo,
  );

  useEffect(() => {
    if (!screenedOutReason) return;
    trackAnonEvent("b2bEnrollmentAnonUserExcluded", {
      exclusionReason: screenedOutReason,
    });

    trackIdentifiedEvent("b2bEnrollmentIdentifiedUserExcluded", {});
  }, [screenedOutReason]);

  const proceedWithMood = () => {
    setSurveyAnswers({
      ...surveyAnswers,
      healthGoal: HealthGoal.REDUCING_STRESS_ANXIOUS_FEELINGS,
    });
    navigate({ pathname: "/enrollment/programOverview", search });
  };

  const partnerName = getDisplayName(partnerInfo);
  const supportLink = (
    <Link
      color={CompassColor.black}
      data-cy="support-link"
      href={customSupportLink}
      isExternal
      textDecoration="underline"
    />
  );

  const accoladeSnippet = isAccolade ? (
    <Trans
      components={{ supportLink }}
      i18nKey="screenedOut.accoladeSnippet"
      values={{ phoneNumber: formattedSupportLink }}
    />
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );

  const screenedOutTextConfigs: {
    [key in ScreenedOutReason]: React.ReactElement;
  } = {
    [ScreenedOutReason.BMI_TOO_LOW]: (
      <Trans
        components={{ accoladeSnippet }}
        context={isCareFirstPartner(partnerInfo) ? "carefirst" : ""}
        i18nKey="screenedOut.bmiTooLow"
        values={{
          partnerName,
        }}
      />
    ),
    [ScreenedOutReason.EATING_DISORDER]: (
      <Trans
        components={{ accoladeSnippet }}
        i18nKey="screenedOut.eatingDisorder"
      />
    ),
    [ScreenedOutReason.PREGNANT]: (
      <Trans components={{ accoladeSnippet }} i18nKey="screenedOut.pregnant" />
    ),
  };

  const screenedOutTextConfigsMoodOffered: {
    [key in ScreenedOutReasonMoodWhitelist]: React.ReactElement;
  } = {
    [ScreenedOutReason.PREGNANT]: (
      <Trans i18nKey="screenedOut.pregnant_moodOffered" />
    ),
  };

  const notInterestedInMoodTextConfigsMoodOffered: {
    [key in ScreenedOutReasonMoodWhitelist]: React.ReactElement;
  } = {
    [ScreenedOutReason.PREGNANT]: (
      <Trans i18nKey="screenedOut.pregnant_notInterestedInMood" />
    ),
  };

  const screenedOutTextConfigsB2C: {
    [key in ScreenedOutReasonB2CWhitelist]: React.ReactElement;
  } = {
    [ScreenedOutReason.BMI_TOO_LOW]: (
      <Trans
        i18nKey="screenedOut.bmiTooLow_b2c"
        values={{ partnerName }}
        components={{
          supportLink,
          supportButton,
        }}
      />
    ),
    [ScreenedOutReason.EATING_DISORDER]: (
      <Trans
        i18nKey="screenedOut.eatingDisorder_b2c"
        values={{ partnerName }}
        components={{
          supportLink,
          supportButton,
        }}
      />
    ),
    [ScreenedOutReason.PREGNANT]: (
      <Trans
        i18nKey="screenedOut.pregnant_b2c"
        values={{ partnerName }}
        components={{
          supportLink,
          supportButton,
        }}
      />
    ),
  };

  const notInterestedInMoodText: React.ReactElement | undefined =
    notInterestedInMoodTextConfigsMoodOffered[
      screenedOutReason as unknown as ScreenedOutReasonMoodWhitelist
    ];

  const showMoodCTAs: boolean =
    partnerOffersMood &&
    !!screenedOutTextConfigsMoodOffered[
      screenedOutReason as unknown as ScreenedOutReasonMoodWhitelist
    ] &&
    !showNotInterestedInMood;

  useEffect(() => {
    // if the user back navigates here and we show Mood CTAs, we can assume
    // that they were screened out of the metabolic path.
    // Restore state to what would be expected at this stage of the metabolic path
    // (nullify selectedProgram and restore healthGoal)
    if (showMoodCTAs) {
      setSelectedProgram(null);
      setSurveyAnswers({
        ...surveyAnswers,
        healthGoal: HealthGoal.LOSING_WEIGHT,
      });
    }
  }, [showMoodCTAs]);

  if (!screenedOutReason) {
    return (
      <BaseOutline>
        <Box m="auto">
          <Loader colorScheme="primary" />
        </Box>
      </BaseOutline>
    );
  }

  // Not all screened out reasons allow continuing with mood, so fallback if not.
  const screenedOutText: React.ReactElement =
    (partnerOffersMood &&
      screenedOutTextConfigsMoodOffered[
        screenedOutReason as unknown as ScreenedOutReasonMoodWhitelist
        ]) ||
    (isB2CPartner(partnerInfo) &&
      screenedOutTextConfigsB2C[
        screenedOutReason as unknown as ScreenedOutReasonB2CWhitelist
        ]) ||
    screenedOutTextConfigs[screenedOutReason];

  return (
    <BaseInterstitial
      backgroundColor={CompassColor.blueberry}
      onClickBack={() => null}
      onClickNext={() => Promise.resolve()}
      questionId={QUESTION_ID}
      width={isBrowser ? "600px" : undefined}
    >
      <Image
        height={{ base: "48px", lg: "64px" }}
        margin="var(--spacing-l) 0px var(--spacing-s) 0px"
        src="/assets/img/diamond-tarocco.svg"
      />
      <Text display="inline-block" color={CompassColor.white} fontFamily="Untitled Sans">
        {showNotInterestedInMood ? notInterestedInMoodText : screenedOutText}
      </Text>
      {showMoodCTAs && (
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          marginTop="var(--spacing-xl)"
        >
          <Button
            colorScheme="primary"
            onClick={proceedWithMood}
            padding="unset"
            size="xl"
            whiteSpace="unset"
            borderRadius="8px"
          >
            Yes, let&apos;s work on mental wellness
          </Button>
          <Button
            borderColor={CompassColor.white}
            color={CompassColor.white}
            _hover={{
              borderColor: CompassColor.grey1,
              color: CompassColor.grey1,
            }}
            _active={{
              borderColor: CompassColor.white,
              color: CompassColor.white,
              borderWidth: "1.5px",
            }}
            colorScheme="secondary"
            marginTop={Spacing[4]}
            onClick={() => setShowNotInterestedInMood(true)}
            padding="unset"
            size="xl"
            variant="outline"
            whiteSpace="unset"
            borderRadius="8px"
          >
            No thanks, I&apos;m not interested
          </Button>
        </Box>
      )}
    </BaseInterstitial>
  );
};

export const Q_SCREENED_OUT: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: () => false,
  component: ScreenedOut,
};
