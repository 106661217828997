import {
  TriageResultProto_Value
} from "@noom/noom-contracts/noom_contracts/backend/dpp/dpp_service";
import { Api } from "@noom/noomscape";

import axios from "axios";


export type UpdateLastTriageTimestampRequest = {
  accessCode: string;
  newLastTriageTimestamp: string;
  triageResult: TriageResultProto_Value;
  wasOfferedDpp: boolean;
  wasOfferedMed: boolean;
};

Api.register(
  "dpp.updateLastTriageTimestamp",
  (requestBody: UpdateLastTriageTimestampRequest) =>
    axios({
      baseURL: window.ENV.SERVER_URL,
      data: requestBody,
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      url: "/dpp/v1/updateLastTriageTimestamp",
      urlWithPathParams: "/dpp/v1/updateLastTriageTimestamp",
    }),
);
