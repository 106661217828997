import React from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  Button,
  CompassColor,
  Image,
  Link,
  Size,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import { QuestionComponentProps } from "@/components/survey/Question";

import { BaseQuestion } from "../../core";

type DppDescriptionForMedProps = Pick<
  QuestionComponentProps,
  "onClickBack" | "onClickNext"
> & {
  questionId: string;
  firstName: string;
};

export const DppDescriptionForMed: React.FC<DppDescriptionForMedProps> = ({
  onClickBack,
  onClickNext,
  questionId,
  firstName,
}) => {
  const { t } = useTranslation();
  const footer = (
    <Box>
      *{" "}
      <Link
        href="https://go.noom.com/bmj-study"
        target="_blank"
        fontSize={Size.sm}
        color={CompassColor.black}
        textDecoration="underline"
      >
        {t("dppInformation.studyLinkText", "See published study")}
      </Link>
    </Box>
  );
  return (
    <BaseQuestion
      footer={footer}
      footerPosition="static"
      onClickBack={onClickBack}
      questionId={questionId}
      questionText={t("programSet.dppDescriptionForMed.questionText", {
        firstName,
      })}
    >
      {/* This isn't using the prop in BaseQuestion cause of the font size */}
      <Text fontSize="18px" lineHeight="26px">
        <Trans i18nKey="programSet.dppDescriptionForMed.questionHelperText" />
      </Text>
      <Stack spacing={Spacing[6]}>
        {t("programSet.dppDescriptionForMed.listItems", {
          returnObjects: true,
        }).map((item: { icon: string; text: string }) => (
          <Box
            alignItems="flex-start"
            display="flex"
            gap="var(--spacing-m)"
            key={item.icon}
          >
            <Image alt="" src={item.icon} />
            <Text fontSize="18px" lineHeight="26px">
              <Trans>{item.text}</Trans>
            </Text>
          </Box>
        ))}
      </Stack>
      <Box display="flex" justifyContent="center">
        <Image
          alt="A graph representing how conventional diets can result in regaining weight vs. keeping it off with Noom"
          src="/assets/img/yoyo-graph.svg"
          width="100%"
        />
      </Box>
      <Button
        colorScheme="primary"
        onClick={() => onClickNext(null)}
        size="xl"
        type="submit"
      >
        {t("programSet.dppDescriptionForMed.ctaText")}
      </Button>
      <Box backgroundColor={CompassColor.offWhite} padding="12px">
        <Text>{t("programSet.dppDescriptionForMed.disclaimer")}</Text>
      </Box>
    </BaseQuestion>
  );
};
