import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Button,
  CompassColor,
  Heading,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { InformationalScreen } from "@/components/survey/questionTypes/core";

import { CheckboxButton } from "../../checkbox";

declare module "@/contexts" {
  interface SurveyAnswers {
    liabilityWaiverEatingDisorder?: boolean;
    liabilityWaiverBMI?: boolean;
    liabilityWaiverPregnant?: boolean;
  }
}

const LiabilityWaiver: (
  questionId: string,
) => React.FC<QuestionComponentProps> = (questionId) => {
  const Component: React.FC<QuestionComponentProps> = ({ onClickNext }) => {
    useTranslation();

    const [didAgree, setDidAgree] = useState(false);

    return (
      <InformationalScreen
        questionId={questionId}
        backgroundColor={CompassColor.white}
      >
        <Stack gap={Spacing[5]}>
          <Heading>
            <Text>
              <Trans i18nKey="liabilityWaiver.heading" />
            </Text>
          </Heading>
          <Text>
            <Trans i18nKey="liabilityWaiver.body" />
          </Text>
          <CheckboxButton
            checked={didAgree}
            invertLabelColorsOnChecked={false}
            onChange={() => setDidAgree(!didAgree)}
          >
            <Trans i18nKey="liabilityWaiver.agreeText" />
          </CheckboxButton>
          <Button
            colorScheme="primary"
            onClick={() => onClickNext(true, { dppEligible: false })}
            isDisabled={!didAgree}
            size="xl"
            display="flex"
            margin="auto"
            maxWidth="400px"
          >
            <Trans i18nKey="liabilityWaiver.cta" />
          </Button>
        </Stack>
      </InformationalScreen>
    );
  };

  return Component;
};

const QUESTION_ID_EATING_DISORDER = "liabilityWaiverEatingDisorder";
export const Q_LIABILITY_WAIVER_EATING_DISORDER: QuestionDefinition = {
  id: QUESTION_ID_EATING_DISORDER,
  shouldShowQuestion: ({
    surveyAnswers: { liabilityWaiverAlertEatingDisorder },
  }) => !!liabilityWaiverAlertEatingDisorder,
  component: LiabilityWaiver(QUESTION_ID_EATING_DISORDER),
};

const QUESTION_ID_BMI = "liabilityWaiverBMI";
export const Q_LIABILITY_WAIVER_BMI: QuestionDefinition = {
  id: QUESTION_ID_BMI,
  shouldShowQuestion: ({ surveyAnswers: { liabilityWaiverAlertBMI } }) =>
    !!liabilityWaiverAlertBMI,
  component: LiabilityWaiver(QUESTION_ID_BMI),
};

const QUESTION_ID_PREGNANT = "liabilityWaiverPregnant";
export const Q_LIABILITY_WAIVER_PREGNANT: QuestionDefinition = {
  id: QUESTION_ID_PREGNANT,
  shouldShowQuestion: ({ surveyAnswers: { liabilityWaiverAlertPregnant } }) =>
    !!liabilityWaiverAlertPregnant,
  component: LiabilityWaiver(QUESTION_ID_PREGNANT),
};
