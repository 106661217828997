import { useField } from "formik";
import i18next from "i18next";
import React, { lazy, Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  Box,
  CompassColor,
  Image,
  Input,
  InputProps,
  Spacing,
  Stack,
} from "@noom/wax-component-library";

const PasswordStrengthBar = lazy(() => import("react-password-strength-bar"));

const PASSWORD_MIN_LENGTH = 8;
const PASSWORD_MAX_LENGTH = 50;
const PASSWORD_MIN_SCORE = 3;

let passwordScore = 0;
const getPasswordScore = () => passwordScore;

export const PasswordInputWithoutStrengthValidationSchema = Yup.object({
  password: Yup.string()
    .min(PASSWORD_MIN_LENGTH, () => i18next.t("form.password.errors.length"))
    .max(PASSWORD_MAX_LENGTH, () => i18next.t("form.password.errors.length"))
    .required(() => i18next.t("form.password.errors.required")),
});

export const PasswordInputValidationSchema = Yup.object({
  password: Yup.string()
    .min(PASSWORD_MIN_LENGTH, () => i18next.t("form.password.errors.length"))
    .max(PASSWORD_MAX_LENGTH, () => i18next.t("form.password.errors.length"))
    .required(() => i18next.t("form.password.errors.required"))
    .test({
      message: () => i18next.t("form.password.errors.notStrong"),
      name: "is-password-strong",
      test: () => getPasswordScore() >= PASSWORD_MIN_SCORE,
    }),
});

type PasswordInputProps = InputProps & {
  name: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  showPasswordStrengthBar?: boolean;
  userInputs: string[];
};

export const PasswordInput: React.FC<PasswordInputProps> = ({
  name,
  onChange,
  showPasswordStrengthBar = true,
  userInputs,
}) => {
  const [field, { touched, error }] = useField({ name });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { t } = useTranslation();

  const togglePasswordVisibility = () => {
    setShowPassword((prevValue) => !prevValue);
  };

  const imgVisibilityOnPath = "/assets/img/visibility.svg";
  const imgVisibilityOffPath = "/assets/img/visibility_off.svg";
  // Preload the visibility off image so it's loaded when visibility is toggled
  const imgVisibilityOff = new window.Image();
  imgVisibilityOff.src = imgVisibilityOffPath;

  return (
    <Stack spacing={Spacing[2]}>
      <Input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        error={touched && error}
        label={t("form.password.label")}
        placeholder={t("form.password.placeholder")}
        onChange={onChange}
        rightElement={
          <Image
            alt="Toggle"
            cursor="pointer"
            onClick={togglePasswordVisibility}
            src={showPassword ? imgVisibilityOffPath : imgVisibilityOnPath}
          />
        }
        type={showPassword ? "text" : "password"}
      />
      {/* The fallback is just a div of height 12px, the height of the strength bar */}
      {showPasswordStrengthBar && (
        <Suspense fallback={<Box height="12px" />}>
          <PasswordStrengthBar
            barColors={[
              CompassColor.grey2,
              CompassColor.tarocco,
              CompassColor.yolk,
              CompassColor.lagoon,
              CompassColor.avocado,
            ]}
            minLength={PASSWORD_MIN_LENGTH}
            onChangeScore={(score) => {
              passwordScore = score;
            }}
            password={field.value}
            scoreWords={[
              t("form.password.scoreWords.weak"),
              t("form.password.scoreWords.weak"),
              t("form.password.scoreWords.okay"),
              t("form.password.scoreWords.strong"),
              t("form.password.scoreWords.veryStrong"),
            ]}
            shortScoreWord={
              field.value.length ? t("form.password.shortScoreWord") : ""
            }
            userInputs={userInputs}
          />
        </Suspense>
      )}
    </Stack>
  );
};
