import { parse } from "date-fns";
import { useEffect, useState } from "react";

import { RetriageEligibilityResponseProto } from "@noom/noom-contracts/noom_contracts/backend/partner/service/partner_eligibility_service";
import { Api } from "@noom/noomscape";

import { DOB_FORMAT } from "@/constants";
import { useAppContext } from "@/contexts";
import { RetriageEligibilityVerificationProperties } from "@/models";
import { getAutologinData } from "@/utils/cookies";
import {
  getProgramFromOffering,
  getEligibleProgramsFromEligibleOfferings,
} from "@/utils/programs";
import { createCivilDate } from "@/utils/proto";
import { captureException } from "@/utils/sentry";

import { useGoto } from "./useGoto";
import { useTrackEvent } from "./useTrackEvent";

export const useRetriageEligibility = (partnerId?: number) => {
  const { retriageEligibility, setRetriageEligibility } = useAppContext();
  const goto = useGoto();
  const [isLoadingRetriageEligibility, setIsLoadingRetriageEligibility] =
    useState(false);
  const [checkingAutologinData, setCheckingAutologinData] = useState(false);
  const [verificationProperties, setVerificationProperties] =
    useState<RetriageEligibilityVerificationProperties>();
  const { linkB2BIdentity } = useTrackEvent();

  const resetRetriageEligibility = () => {
    setRetriageEligibility(undefined);
    setVerificationProperties(undefined);
  };

  const handleRetriageEligibilityResponse = (
    response: RetriageEligibilityResponseProto,
    didVerifyUsingAutologinData = false,
  ) => {
    const {
      accessCode,
      currentOffering,
      eligibilityReason,
      eligibleRetriageOfferings,
      userProfile,
      partnerSlug,
      segmentId,
      segmentName,
    } = response;

    const currentProgram = getProgramFromOffering(currentOffering);
    const eligibleRetriagePrograms = getEligibleProgramsFromEligibleOfferings(
      eligibleRetriageOfferings,
    );

    setRetriageEligibility({
      accessCode,
      currentProgram,
      didVerifyUsingAutologinData,
      eligibilityReason,
      eligibleRetriagePrograms,
      userProfile,
      partnerSlug,
      segmentId,
      segmentName,
    });

    if (accessCode) {
      linkB2BIdentity(accessCode);
    }
  };

  useEffect(() => {
    if (retriageEligibility || !partnerId || !verificationProperties) {
      return () => undefined;
    }

    let ignore = false;
    async function fetch() {
      if (!verificationProperties) {
        return;
      }

      setIsLoadingRetriageEligibility(true);

      try {
        const { dateOfBirth, ...rest } = verificationProperties;

        const response: RetriageEligibilityResponseProto = await Api.call(
          "partner.retriageEligibilityByEligibilityInfo",
          Api.api.partner.retriageEligibilityByEligibilityInfo,
          {
            partnerId,
            ...rest,
            ...(dateOfBirth && {
              dateOfBirth: createCivilDate(
                parse(dateOfBirth, DOB_FORMAT, new Date()),
              ),
            }),
          },
        );

        if (ignore) {
          return;
        }

        handleRetriageEligibilityResponse(response);
      } catch (e) {
        if (ignore) {
          return;
        }

        goto.error();
      }
      setIsLoadingRetriageEligibility(false);
    }
    fetch();
    return () => {
      ignore = true;
    };
  }, [verificationProperties, partnerId, retriageEligibility]);

  useEffect(() => {
    const autologinData = getAutologinData();
    if (!autologinData) {
      return () => undefined;
    }

    const { accessCode, accessToken } = autologinData;

    if (retriageEligibility && retriageEligibility.accessCode === accessCode) {
      // already fetched the user's eligibility on a previous page
      return () => undefined;
    }

    let ignore = false;
    async function fetch() {
      setCheckingAutologinData(true);
      try {
        const response: RetriageEligibilityResponseProto = await Api.call(
          "partner.retriageEligibilityForAccessCode",
          Api.api.partner.retriageEligibilityForAccessCode,
          {
            accessCode,
            accessToken,
          },
        );

        if (ignore) {
          return;
        }

        handleRetriageEligibilityResponse(response, true);
      } catch (e) {
        if (ignore) {
          return;
        }
        captureException(e);
      }
      setCheckingAutologinData(false);
    }
    fetch();
    return () => {
      ignore = true;
    };
  }, []);

  return {
    verificationProperties,
    setVerificationProperties,
    resetRetriageEligibility,
    checkingAutologinData,
    isLoadingRetriageEligibility,
  };
};
