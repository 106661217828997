import React from "react";
import { useTranslation } from "react-i18next";

import { isB2CPartner } from "@/utils/partners";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "stressEating";

declare module "@/contexts" {
  interface SurveyAnswers {
    stressEating?: string;
  }
}

const StressEatingQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    {
      id: "stronglyDisagree",
      text: t("stressEating.options.stronglyDisagree"),
    },
    { id: "disagree", text: t("stressEating.options.disagree") },
    { id: "agree", text: t("stressEating.options.agree") },
    { id: "stronglyAgree", text: t("stressEating.options.stronglyAgree") },
  ];
  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionId={QUESTION_ID}
      questionDescription={t("stressEating.questionDescription")}
      questionText={t("stressEating.questionText")}
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_STRESS_EATING: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo, selectedProgram }) =>
    selectedProgram !== "MOOD" && !isB2CPartner(partnerInfo),
  component: StressEatingQuestion,
};
