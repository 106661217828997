import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { PregnantSelection, ScreenedOutReason } from "@/constants";
import { SurveyAnswer, useAppContext } from "@/contexts";
import { shouldShowCardiometabolicQuestionForRetriage } from "@/utils/survey/retriage";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "pregnant";

declare module "@/contexts" {
  interface SurveyAnswers {
    pregnant?: PregnantSelection;
  }
}

const PregnantQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { enrollmentType } = useAppContext();
  const [shouldShowConfirmation, setShouldShowConfirmation] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    surveyAnswers[QUESTION_ID] || "",
  );
  const { t } = useTranslation();

  const { liabilityWaiverBMI, liabilityWaiverEatingDisorder } = surveyAnswers;
  const hasAlreadySignedLiabilityWaiver =
    !!liabilityWaiverEatingDisorder || !!liabilityWaiverBMI;

  const options: QuestionOption[] = [
    { id: PregnantSelection.YES, text: t("pregnant.options.yes") },
    // In the future, we may want to include this option for weight loss pacing calculation
    // Currently, this question appears after pacing so it isn't useful.
    {
      id: PregnantSelection.NO_BUT_NURSING,
      text: t("pregnant.options.noButNursing"),
    },
    {
      id: PregnantSelection.NO_BUT_PLANNING,
      text: t("pregnant.options.noButPlanning"),
    },
    { id: PregnantSelection.NO, text: t("pregnant.options.no") },
  ];

  const handleAnswerBeforeProceeding = async (
    answer: SurveyAnswer,
    _additionalAnswers?: Record<string, SurveyAnswer>,
    _screenedOutReason?: ScreenedOutReason,
    source?: "button" | "option",
  ) => {
    setSelectedItem(answer as string);

    if (
      answer === PregnantSelection.YES &&
      enrollmentType !== "INITIAL_ENROLLMENT"
    ) {
      onClickNext(answer, {}, ScreenedOutReason.PREGNANT);
      return;
    }

    if (source === "button") {
      onClickNext(answer);
      return;
    }

    if (shouldShowConfirmation) {
      return;
    }

    if (
      enrollmentType === "INITIAL_ENROLLMENT" &&
      answer === PregnantSelection.YES &&
      !hasAlreadySignedLiabilityWaiver &&
      !shouldShowConfirmation
    ) {
      setShouldShowConfirmation(true);
      return;
    }

    onClickNext(answer);
  };

  return (
    <MultipleChoiceQuestion
      confirmationElement={
        shouldShowConfirmation ? (
          <Trans i18nKey="pregnant.confirmation" />
        ) : undefined
      }
      ctaText={shouldShowConfirmation ? t("pregnant.ctaText") : ""}
      onClickBack={onClickBack}
      onClickNext={handleAnswerBeforeProceeding}
      options={options}
      questionId={QUESTION_ID}
      questionText={t("pregnant.questionText")}
      selectedItem={selectedItem}
    />
  );
};

export const Q_PREGNANT: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ selectedProgram, surveyAnswers: { sex } }) =>
    selectedProgram !== "MOOD" &&
    (sex === "female" || sex === "intersex" || sex === "preferNotToSay"),
  component: PregnantQuestion,
};

export const Q_RETRIAGE_PREGNANT: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ retriageEligibility, surveyAnswers: { sex } }) =>
    shouldShowCardiometabolicQuestionForRetriage(retriageEligibility) &&
    (sex === "female" || sex === "intersex" || sex === "preferNotToSay"),
  component: PregnantQuestion,
};
