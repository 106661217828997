import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Box, Heading, Image, Text } from "@noom/wax-component-library";

import { TryAgainVideo } from "@/components";
import {
  defaultEligibilityCheckFormValues,
  EligibilityCheckForm,
  EligibilityCheckFormValues,
} from "@/components/forms/EligibilityCheckForm";
import { NoomEmailAddressLink } from "@/components/noom-branding";
import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { DOB_FORMAT } from "@/constants";
import { useAppContext } from "@/contexts";
import {
  EligibilityResult,
  useEligibility,
  useQueryParams,
  useTrackEvent,
} from "@/hooks";
import { getDisplayName } from "@/models";
import { isCareFirstPartner } from "@/utils/partners";
import { withPiiQueryParams } from "@/utils/pixels/urlParamsPii";
import { isBuyflowTraffic } from "@/utils/userSegment";

import { BaseOutline, BaseQuestion } from "../core";

const QUESTION_ID = "eligibilityCheck";
export const ELIGIBILITY_CHECK_QUESTION_ID = QUESTION_ID;

declare module "@/contexts" {
  interface SurveyAnswers {
    eligibilityCheck?: EligibilityCheckFormValues;
  }
}

const EligibilityCheckQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { eligibilityInfo, partnerInfo } = useAppContext();
  const {
    checkEligibility,
    hasEligibilityError,
    redirectToParticipantPartner,
    setHasEligibilityError,
  } = useEligibility();
  const { trackAnonEvent } = useTrackEvent();
  const queryParams = useQueryParams();
  const [initialValues, setInitialValues] = useState(
    surveyAnswers[QUESTION_ID] || defaultEligibilityCheckFormValues,
  );
  const [localEligibilityResult, setLocalEligibilityResult] = useState<
    EligibilityResult | undefined
  >();
  const [submittedValues, setSubmittedValues] = useState<
    EligibilityCheckFormValues | undefined
  >();
  const { t } = useTranslation();

  const partnerId = partnerInfo?.id;

  const getEligibilityResult = async (values: EligibilityCheckFormValues) => {
    if (!partnerId) {
      return;
    }

    const result = await checkEligibility(
      {
        dateOfBirth: values?.dob
          ? format(values.dob as Date, DOB_FORMAT)
          : undefined,
        firstName: values.firstName,
        lastName: values.lastName,
        participantId: values?.employeeId,
        policyId: values?.policyId,
      },
      partnerId,
      queryParams.leadSource,
    );
    setLocalEligibilityResult(result);
  };

  const onSubmit = async (values: EligibilityCheckFormValues) => {
    if (!localEligibilityResult) {
      return;
    }

    const {
      isEligible,
      isMedEligible,
      participantEligibilityId,
      participantPartnerId,
      upid,
    } = localEligibilityResult;

    if (!isEligible) {
      setHasEligibilityError(true);
      return;
    }

    if (partnerId !== participantPartnerId) {
      redirectToParticipantPartner(
        participantEligibilityId,
        participantPartnerId,
        upid,
      );
    } else {
      onClickNext({ ...values, isMedEligible });
    }
  };

  // Call getEligibilityResult after the form values are set in local state
  useEffect(() => {
    if (!submittedValues) {
      return;
    }
    getEligibilityResult(submittedValues);
  }, [submittedValues]);

  // Only call onSubmit after eligibilityInfo has been evaluated and updated in
  // app state through the checkEligibility call.
  useEffect(() => {
    if (!(eligibilityInfo && localEligibilityResult && submittedValues)) {
      return;
    }
    onSubmit(submittedValues);
  }, [eligibilityInfo, localEligibilityResult, submittedValues]);

  useEffect(() => {
    const queryParamsWithPii = withPiiQueryParams(queryParams);
    trackAnonEvent("b2bEnrollmentAnonTriageFlowStarted", {
      leadSource: queryParamsWithPii.leadSource,
      utmSource: queryParamsWithPii.utm_source,
      utmMedium: queryParamsWithPii.utm_medium,
      utmCampaign: queryParamsWithPii.utm_campaign,
      utmTerm: queryParamsWithPii.utm_term,
      utmContent: queryParamsWithPii.utm_content,
    });
  }, []);

  const partnerName = getDisplayName(partnerInfo);

  const hideNoomCostMention = partnerInfo?.configs?.HIDE_COST || false;

  const getQuestionHelperContext = () => {
    if (isCareFirstPartner(partnerInfo)) {
      return "carefirst";
    }

    return hideNoomCostMention ? "notFree" : "free";
  };

  const questionHelperText = t("eligibilityCheck.questionHelperText", {
    context: getQuestionHelperContext(),
    partnerName,
  });

  return hasEligibilityError ? (
    // TODO (@jason.pao): Integrate with existing error view
    <BaseOutline>
      <button type="button">
        <Image
          alt="Back"
          cursor="pointer"
          margin="var(--spacing-m)"
          onClick={() => setHasEligibilityError(false)}
          src="/assets/img/left-arrow.svg"
        />
      </button>
      <Box
        display="flex"
        flexDirection="column"
        margin={{ base: "0 var(--spacing-l)", lg: "auto" }}
        maxWidth="600px"
      >
        <TryAgainVideo />
        <Heading
          fontSize={{ base: "20px", lg: "24px" }}
          fontWeight={500}
          lineHeight={{ base: "26px", lg: "32px" }}
          marginTop="var(--spacing-l)"
        >
          {t("error.failedEligibilityCheck.errorMessage")}
        </Heading>
        <Text lineHeight="24px" marginTop="32px" size="l">
          <Trans i18nKey="error.failedEligibilityCheck.callToAction">
            We need a match in order to confirm your eligibility. Please go back
            and double check the details you provided.
            <br />
            <br />
            Still having issues? Email {NoomEmailAddressLink} with your name,
            email, and membership sponsor and our team will do our best to help
            you out.
            <br />
            <br />
            If you aren&apos;t eligible and still want to use Noom, you can sign
            up and pay for a membership at
            <a href="https://www.noom.com">noom.com</a>.
          </Trans>
        </Text>
      </Box>
    </BaseOutline>
  ) : (
    <BaseQuestion
      onClickBack={onClickBack}
      questionHelperText={questionHelperText}
      questionId={QUESTION_ID}
      questionText={t("eligibilityCheck.questionText.enrollment")}
    >
      <EligibilityCheckForm
        initialValues={initialValues}
        onSubmit={(values) => setSubmittedValues({ ...values })}
        pageId="eligibilityCheck"
        setInitialValues={setInitialValues}
      />
    </BaseQuestion>
  );
};

export const Q_ELIGIBILITY_CHECK: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: () => {
    const queryParams = new URLSearchParams(window.location.search);
    return isBuyflowTraffic() && !queryParams.get("eligibilityId");
  },
  component: EligibilityCheckQuestion,
};
