import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Api } from "@noom/noomscape";

import { Survey } from "@/components/survey/Survey";
import QuestionSets from "@/components/survey/questionSets/questionSets";
import {
  GENDER_QUESTION_ID,
  SEX_QUESTION_ID,
} from "@/components/survey/questionTypes/dpp-triage";
import { ErrorState } from "@/constants";
import { useAppContext } from "@/contexts";
import { useAnswerSurveyQuestion, useGoto } from "@/hooks";
import { Partner } from "@/models";
import { getAutologinData } from "@/utils/cookies";
import { captureException } from "@/utils/sentry";
import { GenderProtoMap, SexProtoMap } from "@/utils/survey/retriage";

export const RetriageView: React.FC = () => {
  const {
    partnerInfo,
    retriageEligibility,
    setErrorState,
    setPartnerInfo,
    setEnrollmentType,
    surveyAnswers,
  } = useAppContext();
  const { answerSurveyQuestion } = useAnswerSurveyQuestion();
  const { openApp } = useGoto();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { employerSlug } = useParams<AppParams>();

  const autologinData = getAutologinData();

  const onSurveyComplete = async () => {
    openApp(autologinData);
  };

  useEffect(() => {
    if (surveyAnswers.sex || surveyAnswers.gender) {
      return;
    }

    const { gender, sex } = retriageEligibility?.userProfile || {};

    if (!(gender && sex)) {
      return;
    }

    // Only pre-populate sex and gender if both values map cleanly to a respective question options.
    if (SexProtoMap[sex] && GenderProtoMap[gender]) {
      answerSurveyQuestion(
        SEX_QUESTION_ID,
        SexProtoMap[sex],
        {
          [GENDER_QUESTION_ID]: GenderProtoMap[gender],
        },
        true,
      );
    }
  }, [retriageEligibility, surveyAnswers]);

  useEffect(() => {
    setEnrollmentType("RETRIAGE");
  }, []);

  useEffect(() => {
    const getPartnerInfo = async () => {
      try {
        const partnerInformation: Partner = await Api.call(
          "partner.getPartnerBySlug",
          Api.api.partner.getPartnerBySlug,
          {
            slug: employerSlug,
          },
        );

        if (!partnerInformation.configs.OFFERS_RETRIAGE) {
          navigate({ pathname: `/employer/${employerSlug}`, search });
          return;
        }

        setPartnerInfo(partnerInformation);
      } catch (e) {
        captureException(e);
        setErrorState(ErrorState.DEFAULT);
        navigate("/error");
      }
    };

    getPartnerInfo();
  }, [employerSlug]);

  return (
    partnerInfo && (
      <Survey
        baseRoute={`/employer/${employerSlug}/rt`}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSurveyComplete={onSurveyComplete}
        questionSet={QuestionSets.RETRIAGE_SURVEY}
      />
    )
  );
};
