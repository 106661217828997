import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Api } from "@noom/noomscape";

import { ErrorState } from "@/constants";
import { useAppContext } from "@/contexts";
import { Partner } from "@/models";
import { captureException } from "@/utils/sentry";

import { AxiosError } from "axios";

import { useGoto } from "./useGoto";
import { useQueryParams } from "./useQueryParams";

const getPartnerBySlug = async (slug: string): Promise<Partner | null> => {
  try {
    const partner = await Api.call(
      "partner.getPartnerBySlug",
      Api.api.partner.getPartnerBySlug,
      {
        slug,
      },
    );

    return partner;
  } catch (e) {
    const { response } = e as AxiosError;
    if (response?.status === 404) {
      return null;
    }
    throw e;
  }
};

const getPartnerByUpid = async (upid: string): Promise<Partner | null> => {
  try {
    const partner: Partner = await Api.call(
      "partner.getPartnerByUpid",
      Api.api.partner.getPartnerByUpid,
      {
        upid,
      },
    );

    return partner;
  } catch (e) {
    const { response } = e as AxiosError;
    if (response?.status === 404) {
      return null;
    }
    throw e;
  }
};

export const usePartnerInfo = () => {
  const { partnerInfo, setPartnerInfo } = useAppContext();
  const goto = useGoto();
  const { employerSlug } = useParams<AppParams>();
  const { upid } = useQueryParams();
  const [isPartnerInfoLoading, setPartnerInfoLoading] = useState(false);
  const [hasPartnerError, setPartnerError] = useState(false);

  useEffect(() => {
    if (partnerInfo?.goLiveDate && partnerInfo.goLiveDate > new Date()) {
      goto.error(ErrorState.BEFORE_GOLIVE_DATE);
      return;
    }

    // (osman) this will not fetch a new partnerInfo if the upid is changed, but we don't need to handle
    // that use case right now
    if (partnerInfo || isPartnerInfoLoading || hasPartnerError) {
      return;
    }

    if (!employerSlug && !upid) {
      return;
    }

    const handlePartnerStateOrError = (partner: Partner | null) => {
      if (!partner) {
        goto.notFound();
        return;
      }

      setPartnerInfo(partner);
    };

    async function fetch() {
      setPartnerInfoLoading(true);

      try {
        let partner: Partner | null = null;

        if (employerSlug && upid) {
          const [partnerBySlug, partnerByUpid] = await Promise.all([
            getPartnerBySlug(employerSlug),
            getPartnerByUpid(upid),
          ]);

          if (partnerBySlug?.id !== partnerByUpid?.id) {
            goto.error();
            return;
          }

          partner = partnerByUpid;
        } else if (upid) {
          partner = await getPartnerByUpid(upid);
        } else if (employerSlug) {
          partner = await getPartnerBySlug(employerSlug);
        }

        handlePartnerStateOrError(partner);
      } catch (e) {
        captureException(e);
        setPartnerError(true);
      }

      setPartnerInfoLoading(false);
    }
    fetch();
  }, [
    employerSlug,
    goto,
    hasPartnerError,
    isPartnerInfoLoading,
    partnerInfo,
    setPartnerInfo,
    upid,
  ]);

  return {
    partnerInfo,
    hasPartnerError,
    isPartnerInfoLoading,
  };
};
