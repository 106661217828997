import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import {
  createTheme,
  TextField,
  TextFieldProps,
  ThemeProvider,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { differenceInYears, isFuture, isValid as isValidDate } from "date-fns";
import { useField, useFormikContext } from "formik";
import i18next from "i18next";
import React from "react";
import * as Yup from "yup";

import { CompassColor, InputProps, Spacing } from "@noom/wax-component-library";

import { defaultFieldStyles } from "@/app/themes/reskin/defaultFieldStyles";
import { DOB_FORMAT } from "@/constants";
import { MAX_AGE, MIN_AGE } from "@/constants/enrollment";

type DateInputProps = InputProps & {
  name: string;
};

export const DOBSchema = Yup.object({
  /**
   * Date validation in this form is complex because of the nature of the
   * custom date input field.
   *
   * The date input component (through @mui/x-date-pickers) parses the input
   * value as a Date, but since users can type in the input, partial input
   * would be treated as an invalid date (e.g. "12/", "12/31/", 12/3). Thus
   * we need to handle all input scenarios and validate them accordingly.
   *
   * 1) An empty date gets returned as null, and not the empty string "".
   *    This will be handled by the .required() check.
   * 2) Partial dates will be returned in a couple different ways.
   *    a) Anything without a year (e.g. "12/", "12/31") will be returned as
   *       an "Invalid Date", but as a Date object, not a string.
   *    b) Anything with a partial year (e.g. 12/31/20, 12/31/1) could be
   *       parsed. The Date constructor treats 12/31/1 as
   *       "Mon Dec 31 0001 00:00:00" which is a valid date, but we don't
   *       want to accept these dates.
   * 3) A valid date will be returned as is. It will be run through the two
   *    tests, the first to make sure the user is less than 110 years old and
   *    the second to make sure the user is at least 18 years old.
   *
   * The .transform in the beginning is necessary because passing the
   * "Invalid Date" object will throw a yup() validation error that we can't
   * modify. If we encounter an invalid date we will just pass "0001-01-01"
   * as a value, because it will be caught by the first date test to make
   * sure it is less than 110 years old.
   */
  dob: Yup.date()
    .transform((_, originalValue) => {
      // Pass null along to be caught by the .required() check.
      if (!originalValue) {
        return originalValue;
      }

      // If the date isn't valid, purposely pass "0001-01-01" as a Date.
      return isValidDate(originalValue)
        ? originalValue
        : new Date("0001-01-01");
    })
    .nullable()
    .required(() => i18next.t("form.dob.errors.required"))
    .test({
      message: () => i18next.t("form.dob.errors.invalid"),
      name: "is-valid-date",
      test: (date?: Date | null) => {
        if (
          !date ||
          // 110 is the max age that can be passed to account creation
          differenceInYears(new Date(), date) > MAX_AGE ||
          isFuture(date)
        ) {
          return false;
        }
        return true;
      },
    })
    .test({
      message: () => i18next.t("form.dob.errors.tooYoung"),
      name: "is-at-least-18",
      test: (date?: Date | null) =>
        // !date is just to satisfy the type definition - if it passes the
        // first date test it will be a valid date
        !!date && differenceInYears(new Date(), date) >= MIN_AGE,
    }),
});

const StyledTextField = styled(TextField)<TextFieldProps>(({ error }) => ({
  "& .MuiOutlinedInput-root": {
    ...defaultFieldStyles,
    fontFamily: "Untitled Sans",
    fontSize: "18px",
    padding: "0 var(--spacing-m)",

    "& fieldset": {
      borderColor: error ? CompassColor.cinnamon : CompassColor.grey2,
      boxShadow: error ? `0 0 0 1px ${CompassColor.cinnamon}` : "none",
      transitionDuration: "var(--chakra-transition-duration-normal)",
    },

    "& input": {
      padding: 0,
    },

    "&:hover fieldset": {
      borderColor: error ? CompassColor.cinnamon : CompassColor.lagoon,
    },

    "&.Mui-focused fieldset": {
      borderColor: CompassColor.lagoon,
      borderWidth: "1px",
      boxShadow: "none",
    },
  },
}));

export const DateInput: React.FC<DateInputProps> = ({
  isReadOnly,
  error,
  helper,
  label,
  name,
  placeholder,
}) => {
  const [field] = useField<Date | null>({ name });
  const { setFieldValue, submitCount } = useFormikContext();

  const isInvalid = typeof error === "string";

  return (
    <FormControl isInvalid={!!error}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      {/**
       * This <ThemeProvider/> component is to prevent a runtime error when
       * trying to access a theme property from a bug in the
       * @mui/x-date-pickers library.
       */}
      <ThemeProvider theme={createTheme()}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            disableOpenPicker
            inputFormat={DOB_FORMAT}
            onChange={(value: Date | null) =>
              // Hacky solution to only validate all fields after form
              // submission. If shouldValidate is just always set to true,
              // other fields would be validated here if they've been touched
              // previously - we want to delay this to after submission.
              setFieldValue(field.name, value, submitCount > 0)
            }
            readOnly={isReadOnly}
            renderInput={(params: TextFieldProps) => (
              <StyledTextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                error={!!error}
                fullWidth
                id={name}
                name={name}
                placeholder={placeholder}
              />
            )}
            value={field.value}
          />
        </LocalizationProvider>
      </ThemeProvider>
      {helper && !error && <FormHelperText>{helper}</FormHelperText>}
      {isInvalid && (
        <FormErrorMessage padding={Spacing[1]} position="relative" top="-5px">
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
