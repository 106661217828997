import { QuestionVisibilityState } from "@/components/survey/Question";
import { GLP1_WEIGHT_LOGIC_PARTNERS } from "@/constants/Partners";

import { meetsMedBmiAndHealthConditionsCriteria } from "../qualification";

export const shouldShowGlp1WeightQuestion = (
  state: QuestionVisibilityState,
): boolean => {
  const {
    partnerInfo,
    surveyAnswers: {
      healthConditions,
      heightWeight: { heightFeetPortion, heightInchesPortion, weight } = {},
      glp1Medication,
    },
  } = state;

  if (!GLP1_WEIGHT_LOGIC_PARTNERS.includes(partnerInfo?.slug || "")) {
    return false;
  }

  if (
    heightFeetPortion === undefined ||
    heightInchesPortion === undefined ||
    weight === undefined
  ) {
    return false;
  }

  return (
    (glp1Medication === "yes" ||
      glp1Medication === "prescriptionNotYetStarted") &&
    !meetsMedBmiAndHealthConditionsCriteria(
      healthConditions,
      heightFeetPortion,
      heightInchesPortion,
      weight,
    )
  );
};
