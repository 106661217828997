import React from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  Button,
  CompassColor,
  Heading,
  Image,
  Spacing,
  Stack,
} from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { shouldShowPersonalizationFlow } from "@/utils/survey/contentPersonalization";

import { BaseInterstitial } from "../../core";

const QUESTION_ID = "personalizationConfirmation";

const PersonalizationConfirmation: React.FC<QuestionComponentProps> = ({
  onClickNext,
}) => {
  const { t } = useTranslation();

  return (
    <BaseInterstitial
      backgroundColor={CompassColor.sky}
      onClickBack={() => null}
      onClickNext={() => Promise.resolve()}
      questionId={QUESTION_ID}
      width="400px"
    >
      <Stack spacing={Spacing[8]} alignItems="stretch">
        <Image
          alt="Checkmark with white circle"
          margin="auto"
          src="/assets/img/checkmark_green_white_circle.svg"
          mt="20px"
        />
        <Heading fontSize="24px" fontWeight={500} lineHeight="32px">
          {t("personalizationConfirmation.heading")}
        </Heading>
      </Stack>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        marginTop="var(--spacing-xl)"
      >
        <Button
          colorScheme="primary"
          onClick={() => onClickNext(null)}
          padding="unset"
          size="xl"
          whiteSpace="unset"
        >
          <Trans i18nKey="personalizationConfirmation.cta">Next</Trans>
        </Button>
      </Box>
    </BaseInterstitial>
  );
};

export const Q_PERSONALIZATION_CONFIRMATION: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo, selectedProgram, surveyAnswers }) =>
    shouldShowPersonalizationFlow(partnerInfo, selectedProgram, surveyAnswers),
  component: PersonalizationConfirmation,
};
