import React from "react";
import { useTranslation } from "react-i18next";

import { partnerOffersPrograms } from "@/utils/programs";
import { shouldShowDppQualification } from "@/utils/survey/retriage";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "familyHistory";

declare module "@/contexts" {
  interface SurveyAnswers {
    familyHistory?: string;
  }
}

const FamilyHistoryQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    { id: "yes", text: t("familyHistory.options.yes") },
    { id: "no", text: t("familyHistory.options.no") },
  ];

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionId={QUESTION_ID}
      questionText={t("familyHistory.questionText")}
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_FAMILY_HISTORY: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    enrollmentInfo,
    partnerInfo,
    selectedProgram,
    surveyAnswers: { dppEligible },
  }) =>
    partnerOffersPrograms(["DPP"], enrollmentInfo, partnerInfo) &&
    selectedProgram !== "MED" &&
    selectedProgram !== "MOOD" &&
    selectedProgram !== "NMPBA" &&
    dppEligible !== false,
  component: FamilyHistoryQuestion,
};

export const Q_RETRIAGE_FAMILY_HISTORY: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: (state) => shouldShowDppQualification(state),
  component: FamilyHistoryQuestion,
};
