import { differenceInYears } from "date-fns";

import { CoursePackSlug, ProgramOffering } from "@/constants";
import { type SurveyAnswers } from "@/contexts";
import { Partner } from "@/models";
import { captureException } from "@/utils/sentry";

import { isB2CPartner } from "../partners";

export const getRecommendedCoursePacks = (
  program: ProgramOffering | null,
  surveyAnswers: SurveyAnswers,
): CoursePackSlug[] => {
  const recommendedCoursePacks: CoursePackSlug[] = [];

  const { eligibilityCheck, glp1Medication, sleep, stressEating } =
    surveyAnswers;

  const { age, dob } = eligibilityCheck ?? {};

  const missingAnswers = Object.entries({
    ...(!dob && { age }),
    ...(!age && { dob }),
    glp1Medication,
    sleep,
    stressEating,
  })
    .filter(([, v]) => !v)
    .map(([k]) => k);

  if (missingAnswers.length) {
    captureException(
      Error(
        "Missing expected survey answer(s) when calculating recommended course packs.",
      ),
      { missingAnswers },
    );
    return [];
  }

  if (["frequently", "everyNight"].includes(sleep ?? "")) {
    recommendedCoursePacks.push("SLEEP");
  }

  if (
    glp1Medication === "yes" ||
    glp1Medication === "prescriptionNotYetStarted"
  ) {
    recommendedCoursePacks.push("GLP1");
  }

  if (["agree", "stronglyAgree"].includes(stressEating ?? "")) {
    recommendedCoursePacks.push("NW_PLUS");
  }

  const calculatedAge = dob
    ? differenceInYears(new Date(), dob as Date)
    : Number(age);
  if (calculatedAge >= 40) {
    recommendedCoursePacks.push("AGING_AND_METABOLISM");
  }

  return recommendedCoursePacks;
};

export const isPersonalizationEnabled = (
  partnerInfo: Partner | null,
  selectedProgram: ProgramOffering | null,
) =>
  ["MED", "NMPBA", "WEIGHT"].includes(selectedProgram ?? "") &&
  !isB2CPartner(partnerInfo);

export const shouldShowPersonalizationFlow = (
  partnerInfo: Partner | null,
  selectedProgram: ProgramOffering | null,
  surveyAnswers: SurveyAnswers,
) =>
  isPersonalizationEnabled(partnerInfo, selectedProgram) &&
  getRecommendedCoursePacks(selectedProgram, surveyAnswers).length > 0;
