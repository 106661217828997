import { QuestionVisibilityState } from "@/components/survey/Question";

import { isB2CPartner } from "../partners";

export function shouldShowWomensHealthQuestions({
  partnerInfo,
  selectedProgram,
  surveyAnswers: { sex },
}: QuestionVisibilityState) {
  return (
    ["female", "intersex"].includes(sex ?? "") &&
    selectedProgram !== "MOOD" &&
    !isB2CPartner(partnerInfo)
  );
}
