import React from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  CompassColor,
  Image,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { shouldAskBaselineOutcomes } from "@/utils/survey/outcomes";

import { BaseInterstitial } from "../../core";

const QUESTION_ID = "baselineHealth";

const BaselineHealth: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
}) => {
  const { t } = useTranslation();

  return (
    <BaseInterstitial
      backgroundColor={CompassColor.lagoon}
      ctaText={t("baselineHealth.ctaText")}
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      questionId={QUESTION_ID}
      width="600px"
    >
      <Stack spacing={Spacing[8]} mb="16px" maxW="550px">
        <Image src="/assets/img/partyHat.svg" m="0 auto" />
        <Text
          color={CompassColor.white}
          fontSize="28px"
          lineHeight="36px"
          fontWeight="500"
          fontFamily="Untitled Serif"
        >
          {t("baselineHealth.title")}
        </Text>
        <Text
          color={CompassColor.white}
          fontSize="18px"
          lineHeight="26px"
          fontWeight="400"
        >
          <Trans i18nKey="baselineHealth.description" />
        </Text>
      </Stack>
    </BaseInterstitial>
  );
};

export const Q_BASELINE_HEALTH: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo }) =>
    shouldAskBaselineOutcomes(partnerInfo),
  component: BaselineHealth,
};
