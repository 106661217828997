import React from "react";
import { useTranslation } from "react-i18next";

import { isB2CPartner } from "@/utils/partners";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "sleep";

declare module "@/contexts" {
  interface SurveyAnswers {
    sleep?: string;
  }
}

const SleepQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    { id: "never", text: t("sleep.options.never") },
    { id: "rarely", text: t("sleep.options.rarely") },
    { id: "occasionally", text: t("sleep.options.occasionally") },
    { id: "frequently", text: t("sleep.options.frequently") },
    { id: "everyNight", text: t("sleep.options.everyNight") },
  ];

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionId={QUESTION_ID}
      questionText={t("sleep.questionText")}
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_SLEEP: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo, selectedProgram }) =>
    selectedProgram !== "MOOD" && !isB2CPartner(partnerInfo),
  component: SleepQuestion,
};
