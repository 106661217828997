import { QuestionVisibilityState } from "@/components/survey/Question";
import {
  HealthConditionOptionId,
  NoomMedMoreOptionsSelection,
  PregnantSelection,
} from "@/constants";
import { STATE_FARM_PARTNERS } from "@/constants/Partners";

import { calculateBMI } from "./calculateBMI";

const ELIGIBLE_COMORBIDITIES = [
  HealthConditionOptionId.ABNORMAL_CHOLESTEROL,
  HealthConditionOptionId.LIVER_DISEASE,
  HealthConditionOptionId.LOW_TESTOSTERONE,
  HealthConditionOptionId.HEART_DISEASE,
  HealthConditionOptionId.HIGH_BLOOD_PRESSURE,
  HealthConditionOptionId.OSTEOARTHRITIS,
  HealthConditionOptionId.PCOS_OR_FERTILITY_PROBLEMS,
  HealthConditionOptionId.TYPE2_DIABETES,
];

/**
 * Returns whether or not a user would qualify for Med based on their BMI and
 * health conditions
 *
 * A user would not qualify if
 * - BMI < 27 or
 * - BMI is between 27-30 and user does not have an eligible comorbodity
 *
 * @param healthConditions the user's answers to the health conditions question
 * @param heightFeetPortion the feet portion of the user's height
 * @param heightInchesPortion the inches portion of the user's height
 * @param weight the user's weight
 * @returns true if the user would qualify for Med, false otherwise
 */
export const meetsMedBmiAndComorbityCriteria = (
  healthConditions: string[] | undefined,
  heightFeetPortion: number,
  heightInchesPortion: number,
  weight: number,
) => {
  const hasEligibleComorbidity = !!healthConditions?.some((hc) =>
    ELIGIBLE_COMORBIDITIES.includes(hc as HealthConditionOptionId),
  );

  const bmi = calculateBMI(heightFeetPortion, heightInchesPortion, weight);

  return bmi >= 30 || (bmi >= 27 && hasEligibleComorbidity);
};

/**
 * An extension of the meetsMedBmiAndComorbityCriteria function that also checks that the
 * user does not have an exclusionary health condition (e.g. type 1 diabetes)
 *
 * @param healthConditions the user's answers to the health conditions question
 * @param heightFeetPortion the feet portion of the user's height
 * @param heightInchesPortion the inches portion of the user's height
 * @param weight the user's weight
 * @returns true if the user would qualify for Med, false otherwise
 */
export const meetsMedBmiAndHealthConditionsCriteria = (
  healthConditions: string[] | undefined,
  heightFeetPortion: number,
  heightInchesPortion: number,
  weight: number,
) => {
  if (
    !!healthConditions &&
    healthConditions.includes(HealthConditionOptionId.TYPE1_DIABETES)
  ) {
    return false;
  }

  return meetsMedBmiAndComorbityCriteria(
    healthConditions,
    heightFeetPortion,
    heightInchesPortion,
    weight,
  );
};

/**
 * This function returns whether a user qualifies for Noom Med based on the
 * current visibility state. This is used to determine question visibility
 * so it does mean it will return true if some questions are not answered
 * (e.g. if a user does not answer the pregnant question - user is male, or has
 * not answered the state of residency question yet)
 *
 * @param {QuestionVisibilityState} state The entire visibility state
 * @returns {boolean} True if the user qualifies for Noom Med, false otherwise
 */
export const qualifiesForNoomMed = ({
  partnerInfo,
  retriageEligibility,
  surveyAnswers,
  telehealthEligibleStates,
}: QuestionVisibilityState): boolean => {
  const {
    eligibilityCheck,
    glp1Medication,
    glp1Weight,
    healthConditions,
    heightWeight: { heightFeetPortion, heightInchesPortion, weight } = {},
    liabilityWaiverBMI,
    liabilityWaiverEatingDisorder,
    liabilityWaiverPregnant,
    locationState,
    noomMedMoreOptions,
    pregnant,
    retriageEligibilityCheck,
  } = surveyAnswers;

  if (
    liabilityWaiverBMI ||
    liabilityWaiverEatingDisorder ||
    liabilityWaiverPregnant
  ) {
    return false;
  }

  let dob;
  if (retriageEligibility) {
    const { eligibleRetriagePrograms, userProfile: { dateOfBirth } = {} } =
      retriageEligibility;

    if (!eligibleRetriagePrograms.includes("MED")) {
      return false;
    }

    let userProfileDob;
    if (dateOfBirth?.year && dateOfBirth?.month && dateOfBirth?.day) {
      userProfileDob = new Date(
        dateOfBirth.year,
        // Date constructor takes month as an index in range [0,11]
        dateOfBirth.month - 1,
        dateOfBirth.day,
      );
    }

    dob = userProfileDob || retriageEligibilityCheck?.dob;
  } else {
    const { configs: { OFFERS_MED = false } = {} } = partnerInfo || {};

    if (!OFFERS_MED || !eligibilityCheck?.isMedEligible) {
      return false;
    }

    /**
     * https://noomhq.atlassian.net/browse/B2B-2158
     *
     * State Farm users qualify for Noom Med powered by Accolade and bypass
     * normal criteria if they are taking or have a prescription for a GLP-1.
     */
    if (
      STATE_FARM_PARTNERS.includes(partnerInfo?.id as number) &&
      (glp1Medication === "yes" ||
        glp1Medication === "prescriptionNotYetStarted")
    ) {
      return true;
    }

    dob = eligibilityCheck?.dob;
  }

  if (
    !dob ||
    heightFeetPortion === undefined ||
    heightInchesPortion === undefined ||
    weight === undefined
  ) {
    return false;
  }

  if (pregnant && pregnant !== PregnantSelection.NO) {
    return false;
  }

  if (
    locationState &&
    !(telehealthEligibleStates || []).includes(locationState)
  ) {
    return false;
  }

  /**
   * Currently the only users who will answer the GLP-1 weight question are
   * CareFirst users. If a user answers the GLP-1 weight question, they must
   * meet both of the following criteria to be eligible for Med:
   *
   * - The user must have BMI 30+ or 27+ with comorbidity when starting GLP-1
   * - The user must have lost at least 5% body weight
   *
   * https://www.figma.com/design/FAbvdVlekwEEUoVF9QvVx2/CareFirst%3A-Existing-GLP-1-users-who-don't-meed-med-criteria?node-id=2-15287&t=zAjFCAdal2WOeCoW-0
   */
  if (glp1Weight !== undefined) {
    return (
      meetsMedBmiAndComorbityCriteria(
        healthConditions,
        heightFeetPortion,
        heightInchesPortion,
        glp1Weight,
      ) && (glp1Weight - weight) / glp1Weight >= 0.05
    );
  }

  return (
    meetsMedBmiAndHealthConditionsCriteria(
      healthConditions,
      heightFeetPortion,
      heightInchesPortion,
      weight,
    ) &&
    (!noomMedMoreOptions ||
      noomMedMoreOptions === NoomMedMoreOptionsSelection.MED)
  );
};
