import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { ScreenedOutReason } from "@/constants";
import { SurveyAnswer, useAppContext } from "@/contexts";
import { shouldShowCardiometabolicQuestionForRetriage } from "@/utils/survey/retriage";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "eatingDisorder";

declare module "@/contexts" {
  interface SurveyAnswers {
    eatingDisorder?: string;
  }
}

const EatingDisorderQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { enrollmentType } = useAppContext();
  const [shouldShowConfirmation, setShouldShowConfirmation] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    surveyAnswers[QUESTION_ID] || "",
  );
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    { id: "yes", text: t("eatingDisorder.options.yes") },
    { id: "no", text: t("eatingDisorder.options.no") },
  ];

  const handleAnswerBeforeProceeding = async (
    answer: SurveyAnswer,
    _additionalAnswers?: Record<string, SurveyAnswer>,
    _screenedOutReason?: ScreenedOutReason,
    source?: "button" | "option",
  ) => {
    setSelectedItem(answer as string);

    if (answer === "yes" && enrollmentType !== "INITIAL_ENROLLMENT") {
      onClickNext(answer, {}, ScreenedOutReason.EATING_DISORDER);
      return;
    }

    if (source === "button") {
      onClickNext(answer);
      return;
    }

    if (shouldShowConfirmation) {
      return;
    }

    if (
      enrollmentType === "INITIAL_ENROLLMENT" &&
      answer === "yes" &&
      !shouldShowConfirmation
    ) {
      setShouldShowConfirmation(true);
      return;
    }

    onClickNext(answer);
  };

  return (
    <MultipleChoiceQuestion
      confirmationElement={
        shouldShowConfirmation ? (
          <Trans i18nKey="eatingDisorder.confirmation" />
        ) : undefined
      }
      ctaText={shouldShowConfirmation ? t("eatingDisorder.ctaText") : ""}
      onClickBack={onClickBack}
      onClickNext={handleAnswerBeforeProceeding}
      options={options}
      questionId={QUESTION_ID}
      questionText={t("eatingDisorder.questionText")}
      selectedItem={selectedItem}
    />
  );
};

export const Q_EATING_DISORDER: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ selectedProgram }) => selectedProgram !== "MOOD",
  component: EatingDisorderQuestion,
};

export const Q_RETRIAGE_EATING_DISORDER: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ retriageEligibility }) =>
    shouldShowCardiometabolicQuestionForRetriage(retriageEligibility),
  component: EatingDisorderQuestion,
};
