import { isMobile } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";

import {
  buildDeeplink,
  EnrollmentSuccessType,
  ErrorState,
  RetriageFinishedStatus,
  ScreenedOutReason,
} from "@/constants";
import { useAppContext } from "@/contexts";
import { AutologinData } from "@/utils/cookies";

export const useGoto = () => {
  const { setScreenedOutReason, setErrorState, setRetriageFinishedStatus } =
    useAppContext();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const success = (type: EnrollmentSuccessType) => {
    const qs = new URLSearchParams();
    qs.append("type", type);
    navigate({
      pathname: "/success",
      search: qs.toString(),
    });
  };

  const error = (errorState?: ErrorState) => {
    if (errorState) {
      setErrorState(errorState);
    }
    navigate({
      pathname: "/error",
      search,
    });
  };

  const notFound = () => {
    navigate({
      pathname: "/not-found",
    });
  };

  const retriageFinished = (status: RetriageFinishedStatus) => {
    setRetriageFinishedStatus(status);
    navigate({
      pathname: "/rt/finished",
    });
  };

  const medIntake = (userId: string) => {
    window.location.href = `${window.ENV.BUYFLOW_CLIENT_URL}/telehealth?userId=${userId}&isB2B=true#/steadyPatientIntake`;
  };

  const screenOut = (reason: ScreenedOutReason) => {
    setScreenedOutReason(reason);

    // This logic redirects to the screen out page by taking the current
    // pathname and replacing the last segment with the /ineligible route.
    // This is needed to support flows with different segment lengths.
    const segments = pathname.split("/");
    const baseRoute = segments.slice(0, segments.length - 1).join("/");
    navigate({ pathname: `${baseRoute}/ineligible`, search });
  };

  const openApp = (autologinData?: AutologinData, email?: string) => {
    if (autologinData) {
      window.location.href = "https://unknown.noom.com/?score=1&close=1";
    } else if (isMobile) {
      window.location.href = buildDeeplink(email);
    }
  };

  return {
    error,
    medIntake,
    notFound,
    openApp,
    retriageFinished,
    screenOut,
    success,
  };
};
