/**
 * Endpoint for getting an upid for a given Virgin Pulse / Personify Health authorization code
 */

import { Api } from "@noom/noomscape";

import axios from "axios";

export type VirginPulseGenerateUpidResponseError = {
  email: string;
};

Api.register(
  "upid.getVirginPulse",
  async ({
    authorizationCode,
    partnerId,
    redirectUri,
  }: {
    authorizationCode: string;
    partnerId?: number;
    redirectUri: string;
  }) => {
    const response = await axios({
      url: "/virginpulse/v1/users:generateUpid",
      urlWithPathParams: "/virginpulse/v1/users:generateUpid",
      baseURL: window.ENV.SERVER_URL,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        authorizationCode,
        partnerId,
        redirectUri,
      },
    });

    return response.data as string;
  },
);
