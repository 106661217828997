import { Api } from "@noom/noomscape";

import axios from "axios";

export type PartnerRedirectUrl = "personify-health" | "virgin-pulse";
type RequestParams = {
  partnerId: number;
  url?: PartnerRedirectUrl;
};

Api.register(
  "partner.getPartnerRedirect",
  async ({ partnerId, url }: RequestParams) => {
    const response = await axios({
      baseURL: window.ENV.SERVER_URL,
      method: "GET",
      url: `/personifyhealth/v3/partners/${partnerId}/redirect`,
      urlWithPathParams: "/personifyhealth/v3/partners/{partner_id}/redirect",
      params: { url },
    });
    if (response.status !== 200) {
      return null;
    }
    return response.data.redirectUri;
  },
);
