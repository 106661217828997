import React from "react";
import { useTranslation } from "react-i18next";

import { ScreenedOutReason } from "@/constants";
import { SurveyAnswer, useAppContext } from "@/contexts";
import { calculateBMI } from "@/utils/calculateBMI";
import { isCareFirstPartner } from "@/utils/partners";
import { partnerOffersPrograms } from "@/utils/programs";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { CheckboxQuestion } from "../core";

const QUESTION_ID = "ethnicity";
declare module "@/contexts" {
  interface SurveyAnswers {
    ethnicity?: string[];
  }
}

const EthnicityQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { partnerInfo } = useAppContext();
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    { id: "hispanic", text: t("ethnicity.options.hispanic") },
    { id: "white", text: t("ethnicity.options.white") },
    { id: "asian", text: t("ethnicity.options.asian") },
    { id: "americanIndian", text: t("ethnicity.options.americanIndian") },
    { id: "black", text: t("ethnicity.options.black") },
    { id: "nativeHawaiian", text: t("ethnicity.options.nativeHawaiian") },
    { id: "middleEastern", text: t("ethnicity.options.middleEastern") },
    { id: "other", text: t("ethnicity.options.other") },
    { id: "preferNotToSay", text: t("ethnicity.options.preferNotToSay") },
  ];

  const screenOutOrProceed = async (answer: SurveyAnswer) => {
    // Have to typecast "answer" as string[] since ethnicity question is of
    // type string[] but "answer" is generic
    const ethnicities = answer as string[];

    const { heightWeight } = surveyAnswers;

    const bmi = calculateBMI(
      heightWeight?.heightFeetPortion!,
      heightWeight?.heightInchesPortion!,
      heightWeight?.weight!,
    );

    if (
      bmi < 25 &&
      (!ethnicities.includes("asian") || ethnicities.includes("preferNotToSay"))
    ) {
      if (isCareFirstPartner(partnerInfo)) {
        onClickNext(answer, {}, ScreenedOutReason.BMI_TOO_LOW);
        return;
      }

      onClickNext(answer, { dppEligible: false });
      return;
    }

    onClickNext(answer);
  };

  return (
    <CheckboxQuestion
      onClickBack={onClickBack}
      onClickNext={screenOutOrProceed}
      options={options}
      questionHelperText={t("ethnicity.questionHelperText")}
      questionId={QUESTION_ID}
      questionText={t("ethnicity.questionText")}
      selectedItems={surveyAnswers[QUESTION_ID] || []}
    />
  );
};

export const Q_ETHNICITY: QuestionDefinition = {
  id: QUESTION_ID,
  /**
   * Ethnicity question will be shown if
   *   1) the user could be eligible for DPP
   * or
   *   2) the user has a BMI between 23-25
   *
   *
   * Users with BMI < 18.5 will be screened out.
   *
   * Users with BMI 18.5-23 will be screened out if CareFirst
   * and placed into HW otherwise, which is why we don't need to ask ethnicity
   */
  shouldShowQuestion: ({
    enrollmentInfo,
    partnerInfo,
    selectedProgram,
    surveyAnswers: { dppEligible, heightWeight },
  }) => {
    const bmi = calculateBMI(
      heightWeight?.heightFeetPortion!,
      heightWeight?.heightInchesPortion!,
      heightWeight?.weight!,
    );

    return (
      selectedProgram !== "MOOD" &&
      partnerOffersPrograms(["DPP"], enrollmentInfo, partnerInfo) &&
      (dppEligible !== false || (bmi >= 23 && bmi < 25))
    );
  },
  component: EthnicityQuestion,
};

export const Q_RETRIAGE_ETHNICITY: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    retriageEligibility: { currentProgram, eligibleRetriagePrograms = [] } = {},
    surveyAnswers: { dppEligible, heightWeight },
  }) => {
    const bmi = calculateBMI(
      heightWeight?.heightFeetPortion!,
      heightWeight?.heightInchesPortion!,
      heightWeight?.weight!,
    );

    return (
      currentProgram === "WEIGHT" &&
      (eligibleRetriagePrograms.includes("DPP") ||
        eligibleRetriagePrograms.includes("MED")) &&
      (dppEligible !== false || (bmi >= 23 && bmi < 25))
    );
  },
  component: EthnicityQuestion,
};
