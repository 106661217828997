import { format } from "date-fns";
import { omit } from "lodash";

import { SurveyAnswers } from "@/contexts";
import { RetriageUserProfile } from "@/models";

import { convertKgToLb } from "./UnitUtils";

export function sanitizeRetriageAnswers(surveyAnswers: SurveyAnswers): string {
  const QUESTION_BLOCKLIST = [
    "completeProfile",
    "medSignup",
    "existingPassword",
  ];
  let sanitizedSurveyAnswers: Record<string, any> = omit(
    surveyAnswers,
    QUESTION_BLOCKLIST,
  );

  // convert date to string (don't need/want time info)
  if (surveyAnswers.retriageEligibilityCheck?.dob) {
    sanitizedSurveyAnswers = {
      ...sanitizedSurveyAnswers,
      retriageEligibilityCheck: {
        ...surveyAnswers.retriageEligibilityCheck,
        dob: format(
          surveyAnswers.retriageEligibilityCheck.dob as Date,
          "yyyy-MM-dd",
        ),
      },
    };
  }
  return JSON.stringify(sanitizedSurveyAnswers);
}

export const getWeightInLbs = ({
  weightInKg,
  weightInLb,
}: RetriageUserProfile): number | undefined => {
  if (weightInLb) {
    return weightInLb;
  }
  if (weightInKg) {
    return convertKgToLb({
      mainUnitValue: weightInKg,
    }).mainUnitValue;
  }
  return undefined;
};
