import {
  CivilDateProto,
  CivilDateTimeProto,
} from "@noom/noom-contracts/noom_contracts/lib/time/civil_time";

export function createCivilDate(d: Date): CivilDateProto {
  return {
    day: d.getDate(),
    // CivilDateProto expects month in range [1, 12]
    month: d.getMonth() + 1,
    year: d.getFullYear(),
  };
}

export function createCivilDateTime(t: Date): CivilDateTimeProto {
  return {
    date: createCivilDate(t),
    time: {
      hour: t.getHours(),
      minute: t.getMinutes(),
      second: t.getSeconds(),
    },
  };
}
