import React from "react";

import { Box, CompassColor, useMediaQuery } from "@noom/wax-component-library";

import { NoomHeader } from "@/components/noom-branding";

type BaseOutlineProps = {
  backgroundColor?: CompassColor;
  children?: React.ReactNode;
};

export const BaseOutline: React.FC<BaseOutlineProps> = ({
  backgroundColor = CompassColor.white,
  children,
}) => {
  const [isBrowser] = useMediaQuery("(min-width: 1160px)");

  if (!isBrowser) {
    return (
      <Box>
        <NoomHeader />
        <Box
          backgroundColor={backgroundColor}
          display="flex"
          flexDirection="column"
          minHeight="calc(100vh - 64px)"
        >
          {children}
        </Box>
      </Box>
    );
  }

  return (
    <Box height="100vh">
      <NoomHeader />
      <Box
        backgroundColor="#F4F4F4"
        height="calc(100vh - 64px)"
        padding="var(--spacing-l) 314px"
      >
        {/*
          The question height is the content height (100vh - 64px) minus the
          padding on each side: var(--spacing-l) (24px)

          100vh - 64px - 24px - 24px = 100vh - 112px
        */}
        <Box
          backgroundColor={backgroundColor}
          display="flex"
          flexDirection="column"
          height="calc(100vh - 112px)"
          overflowY="auto"
          position="relative"
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
