/**
 * User model type
 *
 * Copyright (C) 2020A Noom, Inc.
 * @author nikola
 */
import { StatusEnumProto_Value } from "@noom/noom-contracts/noom_contracts/backend/privacy/user_policy_options";

import { CoursePackSlug, ProgramOffering } from "@/constants";
import type { SurveyAnswers } from "@/contexts";

import { Address } from "./Address";
import { EctExperiment } from "./experiments";

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  SELF_ID = "SELF_ID",
}

export type User = {
  coursePacks: CoursePackSlug[];
  email: string;
  name: string;
  password: string;
  phoneNumber?: string;
  weightInKg: number;
  heightInCm: number;
  gender?: Gender;
  age: number;
  locale: string;
  surveyAnswers?: SurveyAnswers;
  forceExperimentsToAssign?: EctExperiment[];
  extras: {
    address: Address;
    b2bUserId: string;
    consentSignature?: string;
    consentStatus?: StatusEnumProto_Value;
    dob?: string;
    dppTriageKey?: string;
    employeeId?: string;
    enrolledInMedicare?: string;
    experimentAssignments?: Record<string, string>;
    firstName?: string;
    lastName?: string;
    liabilityWaiverConsent?: string;
    medicareConsent?: string;
    mhmdaOptIn?: boolean;
    orderScale?: boolean;
    partnerName?: string;
    phoneNumber?: string;
    policyId?: string;
    program: ProgramOffering;
    shippingAddress?: Address;
  };
};
