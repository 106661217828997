import type { TOptions } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  CompassColor,
  Image,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import {
  NoomMedMoreOptionsSelection,
  NoomMedOverviewSelection,
  type ProgramOffering,
} from "@/constants";
import { useAppContext } from "@/contexts";
import { partnerOffersPrograms } from "@/utils/programs";
import { qualifiesForNoomMed } from "@/utils/qualification";

import { BaseQuestion } from "../core";

const QUESTION_ID = "noomMedMoreOptions";

declare module "@/contexts" {
  interface SurveyAnswers {
    noomMedMoreOptions?: string;
  }
}

type CardProps = {
  isSelected?: boolean;
  onClick?: () => void;
};

const Card: React.FC<CardProps> = ({ children, isSelected, onClick }) => (
  <Box
    backgroundColor={isSelected ? CompassColor.sky : CompassColor.offWhite}
    border={
      isSelected
        ? `1px solid ${CompassColor.lagoon}`
        : `1px solid ${CompassColor.sand01}`
    }
    borderRadius="8px"
    cursor="pointer"
    onClick={onClick}
    _hover={{ border: `1px solid ${CompassColor.lagoon}` }}
  >
    {children}
  </Box>
);

const NoomMedMoreOptionsQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
}) => {
  const {
    enrollmentInfo,
    partnerInfo,
    retriageEligibility: { eligibleRetriagePrograms = [] } = {},
    setSelectedProgram,
    setSurveyAnswers,
    surveyAnswers,
  } = useAppContext();
  const [selectedCard, setSelectedCard] =
    useState<NoomMedMoreOptionsSelection>();
  const { t } = useTranslation();

  useEffect(() => {
    // if the user back navigates through this question we should delete the answer
    // as its used to determine med qualification and in question visibility
    const newSurveyAnswers = { ...surveyAnswers };
    delete newSurveyAnswers[QUESTION_ID];
    setSurveyAnswers(newSurveyAnswers);
  }, []);

  const isAccolade = partnerInfo?.configs?.ACCOLADE_PARTNER || false;
  const partnerOffersDpp = eligibleRetriagePrograms.length
    ? eligibleRetriagePrograms.includes("DPP")
    : partnerOffersPrograms(["DPP", "WEIGHT"], enrollmentInfo, partnerInfo);

  const accoladeTranslationOptions: TOptions = {
    context: isAccolade ? "accolade" : "",
  };
  const otherCardTranslationOptions: TOptions = {
    context: partnerOffersDpp ? "offersDpp" : "",
  };
  const otherCardTitle = t(
    "noomMedMoreOptions.otherCard.title",
    otherCardTranslationOptions,
  );
  const otherCardDescription = t(
    "noomMedMoreOptions.otherCard.description",
    otherCardTranslationOptions,
  );
  const questionText = t("noomMedMoreOptions.questionText", {
    context: partnerOffersDpp ? "offersDpp" : "",
  });

  return (
    <BaseQuestion
      onClickBack={onClickBack}
      questionId={QUESTION_ID}
      questionText={questionText}
    >
      <Stack spacing={Spacing[4]}>
        <Card
          isSelected={selectedCard === NoomMedMoreOptionsSelection.MED}
          onClick={() => setSelectedCard(NoomMedMoreOptionsSelection.MED)}
        >
          <Stack padding="var(--spacing-m)" spacing={Spacing[4]}>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
            >
              <Text
                fontFamily="Brown LL"
                fontSize="18px"
                fontWeight={500}
                lineHeight="21px"
                letterSpacing="0.03em"
                textTransform="uppercase"
              >
                {t(
                  "noomMedMoreOptions.medCard.title",
                  accoladeTranslationOptions,
                )}
              </Text>
              <Box
                backgroundColor={CompassColor.lagoon}
                borderRadius="20px"
                padding="var(--spacing-xs) var(--spacing-m)"
              >
                <Text color={CompassColor.white} fontWeight={500}>
                  {t("noomMedMoreOptions.medCard.recommended")}
                </Text>
              </Box>
            </Box>
            <Box display="flex">
              <Text fontSize="14px" lineHeight="20px">
                {t(
                  "noomMedMoreOptions.medCard.description",
                  accoladeTranslationOptions,
                )}
              </Text>
            </Box>
            <Box alignItems="flex-start" display="flex" gap="var(--spacing-m)">
              <Image alt="Group Feed" src="/assets/img/icon-group-feed.svg" />
              <Text fontSize="14px" lineHeight="20px">
                {t("noomMedMoreOptions.medCard.valueProp1")}
              </Text>
            </Box>
            <Box alignItems="flex-start" display="flex" gap="var(--spacing-m)">
              <Image alt="Meds" src="/assets/img/icon-meds.svg" />
              <Text fontSize="14px" lineHeight="20px">
                {t("noomMedMoreOptions.medCard.valueProp2")}
              </Text>
            </Box>
          </Stack>
        </Card>
        <Card
          isSelected={selectedCard === NoomMedMoreOptionsSelection.OTHER}
          onClick={() => setSelectedCard(NoomMedMoreOptionsSelection.OTHER)}
        >
          <Stack padding="var(--spacing-m)" spacing={Spacing[4]}>
            <Text
              fontFamily="Brown LL"
              fontSize="18px"
              fontWeight={500}
              lineHeight="21px"
              letterSpacing="0.03em"
              textTransform="uppercase"
            >
              {otherCardTitle}
            </Text>
            <Box alignItems="flex-start" display="flex" gap="var(--spacing-m)">
              <Image alt="Scale" src="/assets/img/icon-scale.svg" />
              <Text fontSize="14px" lineHeight="20px">
                {otherCardDescription}
              </Text>
            </Box>
          </Stack>
        </Card>
      </Stack>
      <Button
        colorScheme="primary"
        isDisabled={!selectedCard}
        onClick={() => {
          if (!selectedCard) {
            return;
          }

          const medProgram: ProgramOffering = isAccolade ? "NMPBA" : "MED";

          const updatedProgram =
            selectedCard === NoomMedMoreOptionsSelection.MED
              ? medProgram
              : null;

          setSelectedProgram(updatedProgram);
          onClickNext(selectedCard, { updatedProgram });
        }}
        size="xl"
        type="submit"
      >
        {t("noomMedMoreOptions.ctaText")}
      </Button>
      <Text color={CompassColor.grey3} fontSize="14px" lineHeight="20px">
        {t("noomMedMoreOptions.disclaimer", accoladeTranslationOptions)}
      </Text>
    </BaseQuestion>
  );
};

export const Q_NOOM_MED_MORE_OPTIONS: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: (state) =>
    state.selectedProgram !== "MOOD" &&
    qualifiesForNoomMed(state) &&
    state.surveyAnswers.noomMedOverview ===
      NoomMedOverviewSelection.SEE_MORE_OPTIONS,
  component: NoomMedMoreOptionsQuestion,
};
