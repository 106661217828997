import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Survey } from "@/components/survey/Survey";
import QuestionSets from "@/components/survey/questionSets/questionSets";
import { ErrorState } from "@/constants";
import { SurveyAnswers, useAppContext } from "@/contexts";
import {
  useCreateAccount,
  useExperiment,
  useQueryParams,
  useSaveTriageData,
  useStoreOutcomes,
  useUpdateLastTriageTimestamp,
} from "@/hooks";
import { Gender } from "@/models";
import type { SignupFormData } from "@/models/SignupFormData";
import {
  formatAsUserData,
  getCoursePacksToAssign,
} from "@/utils/formatAsUserData";
import { captureException } from "@/utils/sentry";

export const DppTriageView: React.FC = () => {
  const {
    b2bIdentifiedUserId,
    eligibilityInfo,
    enrollmentInfo,
    hasB2cAccount,
    partnerInfo,
    selectedProgram,
    setErrorState,
    setEnrollmentType,
  } = useAppContext();
  const { createAccount } = useCreateAccount();
  const { getExperimentAssignments } = useExperiment();
  const { saveTriageData } = useSaveTriageData(partnerInfo?.id);
  const { storeBaselineOutcomesAnswers } = useStoreOutcomes();
  // state associated with a particular navigation event / entry on the browser history stack
  // see: https://developer.mozilla.org/en-US/docs/Web/API/History/state
  const { search, state: historyState } = useLocation();
  const { resetOnOpen } = historyState ?? {};
  const navigate = useNavigate();
  const { upid: rawUpid } = useQueryParams();
  const { updateLastTriageTimestamp } = useUpdateLastTriageTimestamp();
  const upid = rawUpid ?? "";

  if (!enrollmentInfo) {
    navigate({
      pathname: "/",
      search,
    });
  }

  if (!upid) {
    navigate("/error");
  }

  useEffect(() => {
    setEnrollmentType("INITIAL_ENROLLMENT");
  }, []);

  const onSurveyComplete = async (surveyAnswers: SurveyAnswers) => {
    const sexToGenderMap: { [key: string]: Gender } = {
      female: Gender.FEMALE,
      male: Gender.MALE,
      intersex: Gender.SELF_ID,
      preferNotToSay: Gender.SELF_ID,
    };

    const {
      completeProfile,
      createAccount: createAccountAnswer,
      createPassword,
      existingPassword,
      eligibilityCheck,
      scaleOptIn,
      sex,
      heightWeight,
    } = surveyAnswers;

    const password = hasB2cAccount ? existingPassword : createPassword;

    if (
      !completeProfile?.address ||
      !createAccountAnswer?.email ||
      !eligibilityCheck?.firstName ||
      !eligibilityCheck?.lastName ||
      !password
    ) {
      setErrorState(ErrorState.DEFAULT);
      navigate("/error");
      return;
    }

    const employeeId =
      eligibilityCheck?.employeeId || completeProfile?.employeeId;

    const formValues: SignupFormData = {
      age: eligibilityCheck?.age,
      address: completeProfile?.address,
      consentSignature: completeProfile?.consentSignature,
      consentStatus: completeProfile?.consentStatus,
      dob: eligibilityCheck?.dob,
      email: createAccountAnswer?.email,
      employeeId,
      firstName: eligibilityCheck?.firstName,
      gender: sexToGenderMap[sex as keyof typeof sexToGenderMap],
      heightFeetPortion: heightWeight?.heightFeetPortion,
      heightInchesPortion: heightWeight?.heightInchesPortion,
      lastName: eligibilityCheck?.lastName,
      locale: completeProfile?.locale || "en",
      mhmdaOptIn: createAccountAnswer?.mhmdaOptIn,
      orderScale: scaleOptIn === true,
      password,
      phoneNumber: completeProfile?.phoneNumber,
      shippingAddress: completeProfile.shippingAddress,
      weight: heightWeight?.weight,
    };

    try {
      if (!selectedProgram) {
        throw new Error("No selected program");
      }
      const key = await saveTriageData(surveyAnswers, "COMPLETE");
      const coursePacks = getCoursePacksToAssign(
        selectedProgram,
        surveyAnswers,
        partnerInfo,
      );

      const userData = formatAsUserData({
        surveyAnswers,
        coursePacks,
        b2bUserId: b2bIdentifiedUserId,
        dppTriageKey: key,
        formData: formValues,
        policyId: eligibilityInfo?.policyId || eligibilityCheck?.policyId,
        program: selectedProgram,
        experiments: getExperimentAssignments(),
      });

      const accessCode = await createAccount(upid, userData, selectedProgram);

      if (!accessCode) {
        return;
      }
      updateLastTriageTimestamp(accessCode, "ASSIGNED_INITIAL_OFFERING");

      // If this fails for some reason don't break execution
      storeBaselineOutcomesAnswers(accessCode).catch((e) =>
        captureException(e),
      );
    } catch (e) {
      captureException(e);
      setErrorState(ErrorState.DEFAULT);
      navigate("/error");
    }
  };

  return (
    <Survey
      baseRoute="/enrollment"
      onSurveyComplete={onSurveyComplete}
      questionSet={QuestionSets.DPP_SURVEY}
      resetOnOpen={resetOnOpen ?? true}
    />
  );
};
