import React from "react";
import { useTranslation } from "react-i18next";

import { shouldAskSDOH } from "@/utils/survey/sdoh";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../../Question";
import { CheckboxQuestion } from "../../core";

const QUESTION_ID = "eatingMovingAffordably";

type EatingMovingAnswer =
  | "affordingHealthyFood"
  | "timeToPrepare"
  | "makingFoodLast"
  | "fewStores"
  | "fewHealthyOptions"
  | "transportion"
  | "none";

declare module "@/contexts" {
  interface SurveyAnswers {
    eatingMovingAffordably?: EatingMovingAnswer[];
  }
}

const EatingMovingAffordablyQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    {
      id: "affordingHealthyFood",
      text: t("eatingMovingAffordably.options.affordingHealthyFood"),
    },
    {
      id: "timeToPrepare",
      text: t("eatingMovingAffordably.options.timeToPrepare"),
    },
    {
      id: "makingFoodLast",
      text: t("eatingMovingAffordably.options.makingFoodLast"),
    },
    { id: "fewStores", text: t("eatingMovingAffordably.options.fewStores") },
    {
      id: "fewHealthyOptions",
      text: t("eatingMovingAffordably.options.fewHealthyOptions"),
    },
    {
      id: "transportion",
      text: t("eatingMovingAffordably.options.transportion"),
    },
    { id: "none", text: t("eatingMovingAffordably.options.none") },
  ];

  return (
    <CheckboxQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionId={QUESTION_ID}
      questionText={t("eatingMovingAffordably.questionText")}
      questionHelperText={t("eatingMovingAffordably.questionHelperText")}
      selectedItems={surveyAnswers[QUESTION_ID] || []}
    />
  );
};

export const Q_EATING_MOVING_AFFORDABLY: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo, selectedProgram }) => shouldAskSDOH(partnerInfo, selectedProgram),
  component: EatingMovingAffordablyQuestion,
};
