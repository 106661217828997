import { differenceInYears } from "date-fns";

import { AppContextType } from "@/contexts";

export function getDateOfBirth({
  surveyAnswers,
  retriageEligibility,
}: Pick<AppContextType, "surveyAnswers" | "retriageEligibility">):
  | Date
  | undefined {
  if (surveyAnswers.eligibilityCheck?.dob) {
    return surveyAnswers.eligibilityCheck.dob;
  }
  if (retriageEligibility?.userProfile?.dateOfBirth) {
    const dob = retriageEligibility.userProfile.dateOfBirth;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return new Date(dob.year!, dob.month! - 1, dob.day!);
  }
  return undefined;
}

export function getAge({
  surveyAnswers,
  retriageEligibility,
}: Pick<AppContextType, "surveyAnswers" | "retriageEligibility">) {
  const { eligibilityCheck } = surveyAnswers;
  if (eligibilityCheck?.age) {
    return eligibilityCheck.age;
  }
  const dob = getDateOfBirth({ surveyAnswers, retriageEligibility });
  if (dob) {
    return differenceInYears(new Date(), dob);
  }
  return undefined;
}
