
import type {
  GetTelehealthEligibleStatesResponseProto,
  TelehealthUserProgramEnumProto_Value,
} from "@noom/noom-contracts/noom_contracts/healthcare/eligible_states_service";
import { Api } from "@noom/noomscape";

import axios, { AxiosResponse } from "axios";

Api.register("telehealth.getEligibleTelehealthStates", async () => {
  const telehealthUserProgramValue: TelehealthUserProgramEnumProto_Value =
    "B2B";

  const response: AxiosResponse<GetTelehealthEligibleStatesResponseProto> =
    await axios({
      baseURL: window.ENV.GROWTH_API_DOMAIN,
      params: {
        telehealthUserProgram: telehealthUserProgramValue,
      },
      method: "GET",
      url: "/api/insurance/v1/eligibility/eligible_states/",
      urlWithPathParams: "/api/insurance/v1/eligibility/eligible_states/",
    });

  return response.data;
});
