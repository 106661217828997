import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Trans } from "react-i18next";

import { Api } from "@noom/noomscape";
import {
  Box,
  Button,
  Heading,
  Spacing,
  Stack,
} from "@noom/wax-component-library";

import type {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { InformationalScreen } from "@/components/survey/questionTypes/core";
import { useAppContext } from "@/contexts";
import { useTrackEvent, useUpdateLastTriageTimestamp } from "@/hooks";

// This question is only reachable via direct navigation. Use caution when updating this ID.
const QUESTION_ID = "ineligible";

export const RetriageExclusion: React.FC<QuestionComponentProps> = ({
  onClickNext,
}) => {
  const {
    retriageEligibility: {
      accessCode,
      currentProgram,
      eligibleRetriagePrograms = [],
    } = {},
    screenedOutReason,
  } = useAppContext();
  const { trackAnonEvent } = useTrackEvent();
  const { updateLastTriageTimestamp } = useUpdateLastTriageTimestamp();

  const bodyContext = currentProgram === "WEIGHT" ? "weight" : "dpp";

  const canRetriageIntoDpp = eligibleRetriagePrograms.includes("DPP");
  const canRetriageIntoMed = eligibleRetriagePrograms.includes("MED");

  let eligibilityTextContext;
  if (canRetriageIntoDpp && canRetriageIntoMed) {
    eligibilityTextContext = "can_retriage_dpp_and_med";
  } else if (canRetriageIntoDpp) {
    eligibilityTextContext = "can_retriage_dpp";
  } else if (canRetriageIntoMed) {
    eligibilityTextContext = "can_retriage_med";
  }

  useEffect(() => {
    if (!accessCode) {
      return;
    }
    updateLastTriageTimestamp(accessCode, "KEPT_SAME_OFFERING");
    Api.call("dpp.alertUserCoach", Api.api.dpp.alertUserCoach, {
      accessCode,
    });
  }, [accessCode]);

  useEffect(() => {
    if (!screenedOutReason) {
      return;
    }

    trackAnonEvent("b2bEnrollmentAnonUserExcluded", {
      exclusionReason: screenedOutReason,
    });
  }, [screenedOutReason]);

  return (
    <InformationalScreen questionId={QUESTION_ID}>
      <Stack gap={Spacing[4]}>
        <Heading fontSize="24px" fontWeight={500} lineHeight="32px">
          <Trans i18nKey="retriageExclusion.heading" />
        </Heading>
        {currentProgram === "WEIGHT" && (
          <Trans
            context={eligibilityTextContext}
            i18nKey="retriageExclusion.eligibilityText"
          />
        )}
        <Trans context={bodyContext} i18nKey="retriageExclusion.body" />
        {isMobile && (
          <Box>
            <Button
              colorScheme="primary"
              onClick={() => onClickNext(null)}
              size="xl"
              whiteSpace="unset"
            >
              Return to app
            </Button>
          </Box>
        )}
      </Stack>
    </InformationalScreen>
  );
};

export const Q_RETRIAGE_EXCLUSION: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: () => false,
  component: RetriageExclusion,
};
