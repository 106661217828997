import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Api } from "@noom/noomscape";

import { PartnerRedirectUrl } from "@/api/partner/getPartnerRedirect";
import { VirginPulseGenerateUpidResponseError } from "@/api/upid/getVirginPulse";
import { ErrorState } from "@/constants";
import { useAppContext } from "@/contexts";
import { captureException, captureMessage } from "@/utils/sentry";

import { AxiosError } from "axios";

const DEFAULT_STATE = "vp-7Nf7uAwTcKrs9WFRk5ZD";
const REDIRECT_PATH_SUFFIX =
  "/protocol/openid-connect/auth?client_id=pif-noom&client_secret=77f50d80-b2f3-4b95-9fcb-6992ee1c78fd&scope=events%20offline_access&state=:state&response_type=code&redirect_uri=:callbackURL";

function buildCallbackUrl(partnerId: number) {
  return `${window.location.protocol}//${window.location.host}${window.location.pathname}?partnerId=${partnerId}`;
}

async function buildVpLoginUrl(
  partnerId: number,
  url: PartnerRedirectUrl | undefined,
  state: string = DEFAULT_STATE,
) {
  const partnerRedirect = await Api.call(
    "partner.getPartnerRedirect",
    Api.api.partner.getPartnerRedirect,
    {
      partnerId,
      url,
    },
  );

  if (!partnerRedirect) {
    return null;
  }

  return (
    partnerRedirect +
    REDIRECT_PATH_SUFFIX.replace(
      ":callbackURL",
      encodeURIComponent(buildCallbackUrl(partnerId)),
    ).replace(":state", encodeURIComponent(state))
  );
}

export const VirginPulseRedirect: React.FC<{
  redirectType: PartnerRedirectUrl;
}> = ({ redirectType }) => {
  const { setErrorState, setMaskedEmail, partnerInfo } = useAppContext();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const code = queryParams.get("code");
  const state = queryParams.get("state");
  const callbackError = queryParams.get("error");
  const partnerId = Number(queryParams.get("partnerId")); // this comes in when we get the callback from Personify Health - partnerInfo will be empty

  const handleAuthorizationCodeValidation = async (
    authorizationCode: string,
  ) => {
    try {
      if (Number.isNaN(partnerId)) {
        captureMessage(
          `Partner id "${partnerId}" from queryParams is not a number`,
        );
        // there should always be a partner id in the query parameters we get back from VP
        setErrorState(ErrorState.DEFAULT);
        navigate("/error");
        return;
      }
      const { upid } = await Api.call(
        "upid.getVirginPulse",
        Api.api.upid.getVirginPulse,
        {
          authorizationCode,
          partnerId,
          redirectUri: buildCallbackUrl(partnerId),
        },
      );

      if (!upid) {
        throw new Error("Missing UPID");
      }

      const qs = new URLSearchParams({ upid });
      navigate({
        pathname: "/",
        search: qs.toString(),
      });
    } catch (e: unknown) {
      const userAlreadyAssigned = (e as AxiosError)?.response?.status === 409;

      if (userAlreadyAssigned) {
        const maskedEmail =
          (e as AxiosError<VirginPulseGenerateUpidResponseError>)?.response
            ?.data?.email || "";
        setMaskedEmail(maskedEmail);
        setErrorState(ErrorState.EXISTING_VIRGIN_PULSE_USER);
      } else {
        captureException(e);
        setErrorState(ErrorState.DEFAULT);
      }

      navigate("/error");
    }
  };

  useEffect(() => {
    if (callbackError) {
      captureMessage(
        `Received error callback from Personify Health portal: ${callbackError}`,
        "error",
      );
      navigate("/error");
    } else if (code) {
      handleAuthorizationCodeValidation(code);
    } else {
      const doNavigate = async () => {
        // We may not have partnerInfo if the user just navigated straight to /virgin-pulse.
        // Use 0 for the partnerId and the backend will return the legacy VP login URI
        const pId = partnerInfo?.id ?? 0;
        const url = await buildVpLoginUrl(
          pId,
          pId === 0 ? redirectType : undefined,
        );
        if (url) {
          window.location.replace(url);
        } else {
          captureMessage(
            `Failed to get personify health redirect url for partner ${pId}`,
            "error",
          );
          navigate("/error");
        }
      };
      doNavigate();
    }
  }, [code, state, callbackError]);

  return null;
};
