import { sha256 } from "@noble/hashes/sha2";
import { bytesToHex as toHex } from "@noble/hashes/utils";

import { useAppContext } from "@/contexts";
import {
  Context,
  ContextValue,
  EctExperiment,
  Experiment,
  EXPERIMENT_CONFIG,
  ExperimentAssignments,
  Operator,
  TargetingValue,
} from "@/models/experiments";
import { getPartnerProgramOfferings } from "@/utils/programs";
import { captureException } from "@/utils/sentry";

/**
 * Evaluates a targeting condition against a context value using various operators
 * @param operator - The comparison operator to use for evaluation
 * @param value - The target value to compare against (single value or array)
 * @param contextValue - The current context value to evaluate
 * @returns boolean indicating if the condition is met
 * @example
 * evaluateCondition("IN", ["1", "2"], "1") // returns true
 * evaluateCondition("CONTAINS", "DPP", ["DPP", "Other"]) // returns true
 */
export const evaluateCondition = <T extends Operator>(
  operator: T,
  value: TargetingValue<T>,
  contextValue: ContextValue<T>,
): boolean => {
  const conditions: {
    [K in Operator]: (v: TargetingValue<K>, cv: ContextValue<K>) => boolean;
  } = {
    EQUALS: (v, cv) => cv === v,
    "NOT EQUALS": (v, cv) => cv !== v,
    IN: (v, cv) => v.includes(cv),
    "NOT IN": (v, cv) => !v.includes(cv),
    CONTAINS: (v, cv) => cv.includes(v),
    "NOT CONTAINS": (v, cv) => !cv.includes(v),
  };
  return conditions[operator](value, contextValue);
};

/**
 * Determines if an experiment should be active based on its targeting conditions
 * @param experiment - The experiment configuration to evaluate
 * @param context - The current user/session context
 * @returns boolean indicating if the experiment is eligible
 */
export const isEligibleForExperiment = (
  experiment: Experiment,
  context: Context,
): boolean => {
  // If no targeting rules, experiment is always eligible
  if (!experiment.targeting) return true;

  return experiment.targeting.every((condition) => {
    const contextValue = context[condition.key];
    return (
      contextValue !== null &&
      evaluateCondition(condition.operator, condition.value, contextValue)
    );
  });
};

/**
 * Assigns a variation to a user based on their ID and variation weights
 * Uses a deterministic hashing approach to ensure consistent assignment
 * @param experiment - The experiment configuration containing variations
 * @param userId - The user's ID used for consistent assignment
 * @returns The name of the assigned variation
 */
export const assignVariation = (
  experiment: Experiment,
  userId: string,
): string => {
  // Calculate total weight for normalization
  const totalWeight = experiment.variations.reduce(
    (sum, variation) => sum + variation.weight,
    0,
  );

  // Combine userId and experiment name such that assignments are deterministic but independent across experiments
  const hex = toHex(sha256(`${userId}-${experiment.name}`));
  // Convert hash to a number within the weight range
  const weightedIndex = Number(BigInt(`0x${hex}`) % BigInt(totalWeight));

  // Find the variation that corresponds to the weighted index
  let cumulativeWeight = 0;
  for (let i = 0; i < experiment.variations.length; i += 1) {
    cumulativeWeight += experiment.variations[i].weight;
    if (weightedIndex < cumulativeWeight) {
      return experiment.variations[i].name;
    }
  }

  // Alert and default to the first variation if none selected (should never happen)
  captureException(
    Error(`No variation selected for experiment ${experiment.name}`),
  );
  return experiment.variations[0].name;
};

/**
 * Retrieves all ECT experiments associated with the current experiment assignments
 * @param experiments - Map of experiment names to their assigned variations
 * @returns Array of enabled ECT experiments
 * @example
 * const assignments = { "pricingTest": "variantA" };
 * const ectExperiments = getEctExperiments(assignments);
 * // Returns: [{ name: "ectExp1", variations: "control" }, ...]
 */
export const getEctExperiments = (experiments: ExperimentAssignments): EctExperiment[] =>
  Object.entries(experiments).flatMap(([name, variation]) => {
    const experiment = EXPERIMENT_CONFIG.find((exp) => exp.name === name);
    const assignedVariation = experiment?.variations.find(
      (v) => v.name === variation,
    );
    return assignedVariation?.ectExperiments ?? [];
  });

export const useExperiment = () => {
  const { b2bAnonymousUserId, partnerInfo, selectedProgram, enrollmentInfo } =
    useAppContext();

  const context: Context = {
    partnerId: String(partnerInfo?.id),
    selectedProgram,
    partnerOfferings: getPartnerProgramOfferings(enrollmentInfo, partnerInfo),
  };

  /**
   * Gets the current experiment assignments for the user
   * For exclusive experiments, only one will be assigned
   * For non-exclusive experiments, all eligible ones will be assigned
   * @returns Record of experiment names to their assigned variations
   */
  function getExperimentAssignments(): ExperimentAssignments {
    const eligibleExperiments = EXPERIMENT_CONFIG.filter((experiment) =>
      isEligibleForExperiment(experiment, context),
    );

    if (eligibleExperiments.length === 0) {
      return {};
    }

    // Deterministically select an experiment based on user ID
    const randomExperiment =
      eligibleExperiments[
        Number(
          BigInt(`0x${b2bAnonymousUserId}`) %
            BigInt(eligibleExperiments.length),
        )
      ];

    // If exclusive, only return the selected experiment
    if (randomExperiment.isExclusive) {
      return {
        [randomExperiment.name]: assignVariation(
          randomExperiment,
          b2bAnonymousUserId,
        ),
      };
    }

    // Otherwise, return all non-exclusive experiments
    return eligibleExperiments
      .filter((experiment) => !experiment.isExclusive)
      .reduce(
        (assignments, experiment) => ({
          ...assignments,
          [experiment.name]: assignVariation(experiment, b2bAnonymousUserId),
        }),
        {},
      );
  }

  return { getExperimentAssignments };
};
