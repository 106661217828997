import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Trans } from "react-i18next";

import {
  Box,
  Button,
  CompassColor,
  CompassLoader,
  Heading,
  Size,
  Stack,
} from "@noom/wax-component-library";

import { ConfettiBox } from "@/components/ConfettiBox";
import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { useAppContext } from "@/contexts";
import { useGoto, useTrackEvent, useUpdateLastTriageTimestamp } from "@/hooks";
import { useMigrateUser } from "@/hooks/useMigrateUser";
import { captureException, captureMessage } from "@/utils/sentry";

import { BaseOutline } from "../core";

const QUESTION_ID = "retriageTransition";

const RetriageTransition: React.FC<QuestionComponentProps> = ({
  onClickNext,
}) => {
  const [hasMinTimeElapsed, setHasMinTimeElapsed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    b2bIdentifiedUserId,
    retriageEligibility: { accessCode, currentProgram } = {},
    selectedProgram,
  } = useAppContext();
  const { migrateUser, apiLoading, apiError } = useMigrateUser();
  const goto = useGoto();
  const { updateLastTriageTimestamp } = useUpdateLastTriageTimestamp();
  const { trackAnonEvent } = useTrackEvent();

  useEffect(() => {
    if (!apiLoading && !apiError && hasMinTimeElapsed) {
      setIsSuccess(true);
    }
  }, [apiLoading, apiError, hasMinTimeElapsed]);

  useEffect(() => {
    if (!isSuccess || !accessCode) {
      return;
    }
    updateLastTriageTimestamp(accessCode, "CHANGED_OFFERING");
    if (selectedProgram === "MED") {
      setTimeout(() => goto.medIntake(b2bIdentifiedUserId), 2500);
    }
  }, [isSuccess, accessCode, selectedProgram]);

  useEffect(() => {
    if (selectedProgram) {
      trackAnonEvent("b2bEnrollmentAnonRetriageNewProgramSelected", {
        currentProgram: currentProgram || "PROGRAM_UNSPECIFIED",
        newProgram: selectedProgram,
      });
      setTimeout(() => setHasMinTimeElapsed(true), 2500);
      migrateUser();
    } else {
      captureMessage("RetriageTransition was displayed when it shouldn't be");
      goto.error();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasMinTimeElapsed && apiError) {
      captureException(apiError);
      goto.error();
    }
  }, [hasMinTimeElapsed, apiError, goto]);

  return (
    <BaseOutline>
      <Stack alignItems="center" pt={{ base: "40px", md: "100px" }}>
        {isSuccess ? (
          <Stack alignItems="center">
            <ConfettiBox />
            <Heading textAlign="center" mt="30" size={Size.lg} maxWidth="20em">
              <Trans
                i18nKey="retriageTransition.success"
                context={selectedProgram || "DPP"}
              />
            </Heading>
            {isMobile && selectedProgram !== "MED" && (
              <Box pt="30px">
                <Button
                  colorScheme="primary"
                  onClick={() => onClickNext(null)}
                  size="md"
                  whiteSpace="unset"
                >
                  Return to App
                </Button>
              </Box>
            )}
          </Stack>
        ) : (
          <Stack alignItems="center">
            <CompassLoader color={CompassColor.black} size="lg" />
            <Heading mt={{ base: "30px", md: "50px" }}>
              Updating your program...
            </Heading>
          </Stack>
        )}
      </Stack>
    </BaseOutline>
  );
};

export const Q_RETRIAGE_TRANSITION: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    selectedProgram,
    surveyAnswers: { medicareConsent },
  }) =>
    ((selectedProgram === "MED" || selectedProgram === "NMPBA") &&
      medicareConsent !== "disagree") ||
    selectedProgram === "DPP",
  component: RetriageTransition,
};
