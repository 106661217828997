/**
 * Endpoint for updating the curriculum associated with a given upid
 *
 * Copyright (C) 2022 Noom, Inc.
 * @author eric
 */


import { Api } from "@noom/noomscape";

import { Curriculum } from "@/constants";

import axios from "axios";

Api.register(
  "upid.updateUpidCurriculumMapping",
  async ({ upid, curriculum }: { upid: string; curriculum: Curriculum }) => {
    const response = await axios({
      url: `/programs/upid/${upid}:updateUpidCurriculumMapping`,
      urlWithPathParams: "/programs/upid/{upid}:updateUpidCurriculumMapping",
      baseURL: window.ENV.SERVER_URL,
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
      },
      data: curriculum,
    });

    return response.data;
  },
);
