import type { TOptions } from "i18next";
import React, { ReactElement, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  Button,
  CompassColor,
  Image,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { NoomMedOverviewSelection, ProgramOffering } from "@/constants";
import { useAppContext } from "@/contexts";
import { useTrackEvent } from "@/hooks";
import { qualifiesForNoomMed } from "@/utils/qualification";

import { BaseQuestion } from "../core";

const QUESTION_ID = "noomMedOverview";

declare module "@/contexts" {
  interface SurveyAnswers {
    noomMedOverview?: NoomMedOverviewSelection;
    updatedProgram?: ProgramOffering | null;
    wasOfferedMed?: boolean;
  }
}

type ValueProp = {
  caption: ReactElement;
  url: string;
};

const VALUE_PROPS: ValueProp[] = [
  {
    caption: (
      <Trans i18nKey="noomMedOverview.valueProp1">
        <Text fontWeight={500}>Get tested</Text>
        <Text> for conditions that contribute to weight gain</Text>
      </Trans>
    ),
    url: "/assets/img/icon-recipes.svg",
  },
  {
    caption: (
      <Trans i18nKey="noomMedOverview.valueProp2">
        <Text>Get 1:1 virtual support from a </Text>
        <Text fontWeight={500}>specially-trained clinician*</Text>
      </Trans>
    ),
    url: "/assets/img/icon-group-feed.svg",
  },
  {
    caption: (
      <Trans i18nKey="noomMedOverview.valueProp3">
        <Text>Learn about </Text>
        <Text fontWeight={500}>medication options</Text>
        <Text> that can support your weight loss</Text>
      </Trans>
    ),
    url: "/assets/img/icon-meds.svg",
  },
];

const NoomMedOverviewQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const {
    enrollmentType,
    retriageEligibility,
    partnerInfo,
    setSelectedProgram,
    setSurveyAnswers,
  } = useAppContext();
  const { t } = useTranslation();
  const { trackAnonEvent } = useTrackEvent();

  const currentProgram =
    enrollmentType === "RETRIAGE"
      ? retriageEligibility?.currentProgram
      : undefined;

  const isAccolade = partnerInfo?.configs?.ACCOLADE_PARTNER || false;
  const accoladeTranslationOptions: TOptions = {
    context: isAccolade ? "accolade" : "",
  };

  const handleJoinMed = (selection: NoomMedOverviewSelection) => {
    const updatedProgram: ProgramOffering = isAccolade ? "NMPBA" : "MED";

    setSelectedProgram(updatedProgram);
    onClickNext(selection, {
      updatedProgram,
      wasOfferedMed: true,
    });
  };

  useEffect(() => {
    // 1 of 2 places we assign selectedProgram to MED is in this question.
    // if the user back navigates through this question we should nullify selectedProgram
    setSelectedProgram(null);
    // also delete updatedProgram
    const newSurveyAnswers = { ...surveyAnswers };
    delete newSurveyAnswers.updatedProgram;
    setSurveyAnswers(newSurveyAnswers);

    if (currentProgram) {
      trackAnonEvent("b2bEnrollmentAnonRetriageResultPresented", {
        currentProgram,
        eligibleProgram: isAccolade ? "NMPBA" : "MED",
      });
    }
  }, []);

  return (
    <BaseQuestion
      onClickBack={onClickBack}
      questionHelperText={t("noomMedOverview.questionHelperText")}
      questionId={QUESTION_ID}
      questionText={t(
        "noomMedOverview.questionText",
        accoladeTranslationOptions,
      )}
    >
      <Stack spacing={Spacing[4]}>
        {VALUE_PROPS.map(({ caption, url }) => (
          <Box
            alignItems="flex-start"
            display="flex"
            gap="var(--spacing-m)"
            key={url}
          >
            <Image alt="" src={url} />
            <Text fontSize="18px" lineHeight="26px">
              {caption}
            </Text>
          </Box>
        ))}
      </Stack>
      <Box
        border={`2px solid ${CompassColor.grey1}`}
        borderRadius="8px"
        padding="var(--spacing-l)"
      >
        <Image
          alt="Line graph depicting weight loss data from a study where participants lost more weight with a lifestyle change and medication over a lifestyle change alone"
          src="/assets/img/noom-med-graph.png"
        />
        <Box marginTop="var(--spacing-l)">
          <Text color={CompassColor.grey3} fontSize="14px" lineHeight="20px">
            {t("noomMedOverview.graphDisclaimer")}
          </Text>
        </Box>
      </Box>
      {currentProgram === "DPP" ? (
        <Button
          colorScheme="primary"
          onClick={() => handleJoinMed(NoomMedOverviewSelection.NEXT)}
          size="xl"
          type="submit"
        >
          {t("noomMedOverview.ctaText.next")}
        </Button>
      ) : (
        <Stack spacing={Spacing[4]}>
          <Button
            colorScheme="primary"
            onClick={() => handleJoinMed(NoomMedOverviewSelection.JOIN)}
            size="xl"
            type="submit"
          >
            {t("noomMedOverview.ctaText.join", accoladeTranslationOptions)}
          </Button>
          <Button
            onClick={() =>
              onClickNext(
                NoomMedOverviewSelection.SEE_MORE_OPTIONS,
                { wasOfferedMed: true }
              )
            }
            size="xl"
            type="submit"
            variant="outline"
          >
            {t("noomMedOverview.ctaText.seeMoreOptions")}
          </Button>
        </Stack>
      )}
      <Text color={CompassColor.grey3} fontSize="14px" lineHeight="20px">
        {t("noomMedOverview.disclaimer", accoladeTranslationOptions)}
      </Text>
    </BaseQuestion>
  );
};

export const Q_NOOM_MED_OVERVIEW: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: (state) =>
    state.selectedProgram !== "MOOD" && qualifiesForNoomMed(state),
  component: NoomMedOverviewQuestion,
};
