import React from "react";
import { isBrowser } from "react-device-detect";
import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  CompassColor,
  Heading,
  Image,
  Spacing,
  Stack,
  Text,
  useMediaQuery,
} from "@noom/wax-component-library";

import { ContinueToDownloadButton } from "@/components";
import {
  NoomEmailAddressLink,
  NoomHeader,
  NoomVirtualAssistantLink,
} from "@/components/noom-branding";
import { EnrollmentSuccessType } from "@/constants";
import { useAppContext } from "@/contexts";
import { isB2CPartner } from "@/utils/partners";

type DownloadStep = {
  description: string;
  stepNumber: number;
};

type ListMarkerProps = {
  stepNumber: number;
};

type Texts = {
  downloadSteps: DownloadStep[];
  headline: string;
  success?: string;
};

const ListMarker = ({ stepNumber }: ListMarkerProps) => (
  <Box
    alignItems="center"
    backgroundColor={CompassColor.sky}
    borderRadius="50%"
    display="flex"
    height="32px"
    justifyContent="center"
    minWidth="32px"
  >
    <Heading fontSize="20px" fontWeight={500} lineHeight="26px">
      {stepNumber}
    </Heading>
  </Box>
);

export const DownloadView: React.FC<{
  enrollmentSuccessType: EnrollmentSuccessType;
}> = ({ enrollmentSuccessType }) => {
  const { userData, partnerInfo } = useAppContext();
  const [showDesktopStyle] = useMediaQuery("(min-width: 1160px)");
  const { t } = useTranslation();

  const { email } = userData || {};

  const enrollmentSuccessTypeTextMap: Record<EnrollmentSuccessType, Texts> = {
    [EnrollmentSuccessType.TRANSITIONED]: {
      headline: t("success.download.heading.transitioned"),
      downloadSteps: [
        {
          description: t("success.download.downloadSteps.step1.transitioned"),
          stepNumber: 1,
        },
        {
          description: t("success.download.downloadSteps.step2.transitioned"),
          stepNumber: 2,
        },
        {
          description: t("success.download.downloadSteps.step3.transitioned"),
          stepNumber: 3,
        },
      ],
    },
    [EnrollmentSuccessType.CREATED]: {
      headline: t("success.download.heading.created"),
      downloadSteps: [
        {
          description: t("success.download.downloadSteps.step1.created", {
            context: isBrowser ? "desktop" : "mobile",
          }),
          stepNumber: 1,
        },
        {
          description: t("success.download.downloadSteps.step2.created"),
          stepNumber: 2,
        },
        {
          description: t("success.download.downloadSteps.step3.created", {
            context: email ? "hasEmail" : undefined,
            email,
          }),
          stepNumber: 3,
        },
      ],
      success: t("success.download.success"),
    },
    [EnrollmentSuccessType.UPGRADED]: {
      headline: t("success.download.heading.created"),
      downloadSteps: [
        {
          description: t("success.download.downloadSteps.step1.created", {
            context: isBrowser ? "desktop" : "mobile",
          }),
          stepNumber: 1,
        },
        {
          description: t("success.download.downloadSteps.step2.created"),
          stepNumber: 2,
        },
        {
          description: t("success.download.downloadSteps.step3.created", {
            context: email ? "hasEmail" : undefined,
            email,
          }),
          stepNumber: 3,
        },
      ],
      success: t("success.download.success"),
    },
  };

  const textsForEnrollmentSuccessType =
    enrollmentSuccessTypeTextMap[enrollmentSuccessType];

  return (
    <Box>
      <NoomHeader />
      <Box
        display="flex"
        gap={showDesktopStyle ? "120px" : "0px"}
        justifyContent="center"
        flexDirection={showDesktopStyle ? "row" : "column"}
      >
        <Box>
          {showDesktopStyle && (
            <Image
              alt="Example app screens"
              src="/assets/img/appScreens.png"
              width="600px"
            />
          )}
        </Box>
        <Stack
          maxWidth="600px"
          margin={{
            base: "0px var(--spacing-l)",
            lg: "var(--spacing-xl) 0px",
          }}
          spacing={Spacing[8]}
        >
          {textsForEnrollmentSuccessType.success && (
            <Box
              backgroundColor="#F4F4F4"
              display="flex"
              gap="12px"
              padding="12px"
              width="fit-content"
            >
              <Image alt="" src="/assets/img/small-checkmark.svg" />
              <Text size="lg">{textsForEnrollmentSuccessType.success}</Text>
            </Box>
          )}
          <Heading
            fontSize={{ base: "24px", lg: "28px" }}
            fontWeight={500}
            lineHeight={{ base: "32px", lg: "36px" }}
          >
            {textsForEnrollmentSuccessType.headline}
          </Heading>
          <Stack spacing={Spacing[6]}>
            {textsForEnrollmentSuccessType.downloadSteps.map(
              ({ description, stepNumber }) => (
                <Box
                  alignItems="baseline"
                  display="flex"
                  key={stepNumber}
                  gap="12px"
                >
                  <ListMarker stepNumber={stepNumber} />
                  <Text size="xl" wordBreak="break-word">
                    {description}
                  </Text>
                </Box>
              ),
            )}
          </Stack>
          {enrollmentSuccessType !== EnrollmentSuccessType.TRANSITIONED &&
            (isBrowser ? (
              <Stack spacing={Spacing[2]}>
                <Heading fontSize="24px" fontWeight={500} lineHeight="32px">
                  {t("success.download.dontSeeText.header")}
                </Heading>
                <Text size="xl">
                  {t("success.download.dontSeeText.description", {
                    context: email ? "hasEmail" : undefined,
                    email,
                  })}
                </Text>
              </Stack>
            ) : (
              <ContinueToDownloadButton email={email} />
            ))}
          <Text size={showDesktopStyle ? "xl" : "lg"}>
            {isB2CPartner(partnerInfo) ? (
              <Trans i18nKey="support.messages.haveQuestions_b2c">
                Have a question? Drop us a line
                <NoomVirtualAssistantLink>here</NoomVirtualAssistantLink>
              </Trans>
            ) : (
              <Trans
                i18nKey="support.messages.haveQuestions"
                values={{
                  email: t("support.contact.email"),
                }}
              >
                Have a question? Drop us a line at
                {NoomEmailAddressLink}
              </Trans>
            )}
          </Text>
          {!showDesktopStyle && (
            <Image
              alt="Example screens of the Noom app"
              src="/assets/img/appScreens.png"
              width="600px"
            />
          )}
        </Stack>
      </Box>
    </Box>
  );
};
