import React from "react";
import { useTranslation } from "react-i18next";

import { ScreenedOutReason } from "@/constants";
import {
  shouldShowCardiometabolicQuestionForRetriage,
  areSexAndGenderPrePopulated,
} from "@/utils/survey/retriage";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "gender";
export const GENDER_QUESTION_ID = QUESTION_ID;

declare module "@/contexts" {
  interface SurveyAnswers {
    gender?: string;
  }
}

const GenderQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    { id: "male", text: t("gender.options.male") },
    { id: "female", text: t("gender.options.female") },
    { id: "transgender", text: t("gender.options.transgender") },
    { id: "other", text: t("gender.options.other") },
    { id: "preferNotToSay", text: t("gender.options.preferNotToSay") },
  ];

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionId={QUESTION_ID}
      questionText={t("gender.questionText")}
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_GENDER: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    screenedOutReason,
    selectedProgram,
    surveyAnswers: { gender },
  }) =>
    // Hide question if the user answered in the metabolic path and was screened out.
    !(
      gender &&
      selectedProgram === "MOOD" &&
      // Should include its own ScreenedOutReason (if applicable) and any that follow this question.
      [ScreenedOutReason.PREGNANT].includes(
        screenedOutReason as ScreenedOutReason,
      )
    ),
  component: GenderQuestion,
};

export const Q_RETRIAGE_GENDER: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ retriageEligibility, surveyAnswers }) =>
    !areSexAndGenderPrePopulated(surveyAnswers, retriageEligibility) &&
    shouldShowCardiometabolicQuestionForRetriage(retriageEligibility),
  component: GenderQuestion,
};
