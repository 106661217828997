/**
 * Endpoint for storing outcome question responses
 */


import { StoreOutcomesSurveyRequestProto_StoreOutcomesSurveyRequestBodyProto } from "@noom/noom-contracts/noom_contracts/backend/outcomes/service/outcomes_service";
import { DynamicSurveyCompletedProto_SurveyCompleted_SurveyAnswer } from "@noom/noom-contracts/noom_contracts/events/app/dynamic_survey/dynamic_survey_completed";
import { Api } from "@noom/noomscape";

import axios from "axios";

Api.register(
  "outcomes.store",
  ({
    accessCode,
    accessToken,
    surveyAnswers,
    surveyId,
    surveyExperienceSlug,
  }: {
    accessCode: string;
    accessToken: string;
    surveyAnswers: DynamicSurveyCompletedProto_SurveyCompleted_SurveyAnswer[];
    surveyId: string;
    surveyExperienceSlug: string;
  }) => {
    const data: StoreOutcomesSurveyRequestProto_StoreOutcomesSurveyRequestBodyProto =
      {
        survey: {
          surveysCompleted: [
            {
              surveyId,
              surveyExperienceSlug,
              surveyAnswers,
            },
          ],
        },
      };
    return axios({
      url: `/outcomes/v1/outcomes/${accessCode}`,
      urlWithPathParams: "/outcomes/v1/outcomes/{accessCode}",
      baseURL: window.ENV.SERVER_URL,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data,
    });
  },
);
