import { Partner } from "@/models";

const CAREFIRST_PARTNER_ID_PROD = 11;
const CAREFIRST_PARTNER_ID_TEST = 20;
const CARRINGTON_PARTNER_ID_PROD = 207;
const CARRINGTON_PARTNER_ID_TEST = 114;
const DEMO_DPP_PARTNER_ID_PROD = 95;
const JASON_PARTNER_ID_TEST = 58;
const PENDULUM_PARTNER_ID_PROD = 266;
const PENDULUM_PARTNER_ID_TEST = 788;
const NOOM_TEST_PARTNER_ID_PROD = 268;
const NOOM_TEST_PARTNER_ID_TEST = 898;
const EXTERNAL_COACH_PARTNER_ID_PROD = 283;
const EXTERNAL_COACH_PARTNER_ID_TEST = 1590;
const BCBS_NC_PARTNER_ID_PROD = 18;
const BCBS_NC_PARTNER_ID_TEST = 0; // TODO (osman): Update with correct test partner id
const ACCOLADE_PARTNER_ID_PROD = 307;
const ACCOLADE_PARTNER_ID_TEST = 2487;
const STATE_FARM_PARTNER_ID_PROD = 302;
const STATE_FARM_PARTNER_ID_TEST = 2488;
const CYPRESS_STATE_FARM_PARTNER_ID_TEST = 0;

const BAPTIST_HEALTH_SLUG = "baptisthealth";
const CAREFIRST_SLUG = "carefirst";
const KENT_COUNTY_SLUG = "kentcounty";
const PENDULUM_SLUG = "pendulum";
const STAGING_CF_SLUG = "cfy2";

const PROD_DPP_PARTNERS = [
  CAREFIRST_PARTNER_ID_PROD,
  CARRINGTON_PARTNER_ID_PROD,
  BCBS_NC_PARTNER_ID_PROD,
  DEMO_DPP_PARTNER_ID_PROD,
];
const TEST_DPP_PARTNERS = [
  CAREFIRST_PARTNER_ID_TEST,
  CARRINGTON_PARTNER_ID_TEST,
  JASON_PARTNER_ID_TEST,
];

const PROD_B2C_PARTNERS = [
  PENDULUM_PARTNER_ID_PROD,
  NOOM_TEST_PARTNER_ID_PROD,
  EXTERNAL_COACH_PARTNER_ID_PROD,
];

const TEST_B2C_PARTNERS = [
  PENDULUM_PARTNER_ID_TEST,
  NOOM_TEST_PARTNER_ID_TEST,
  EXTERNAL_COACH_PARTNER_ID_TEST,
];

const PROD_STATE_FARM_PARTNERS = [STATE_FARM_PARTNER_ID_PROD];
const TEST_STATE_FARM_PARTNERS = [
  CYPRESS_STATE_FARM_PARTNER_ID_TEST,
  STATE_FARM_PARTNER_ID_TEST,
];

export const CAREFIRST_PARTNER_ID =
  window.ENV.NOOM_ENVIRONMENT === "production"
    ? CAREFIRST_PARTNER_ID_PROD
    : CAREFIRST_PARTNER_ID_TEST;

export const BCBS_NC_PARTNER_ID =
  window.ENV.NOOM_ENVIRONMENT === "production"
    ? BCBS_NC_PARTNER_ID_PROD
    : BCBS_NC_PARTNER_ID_TEST;

export const DPP_PARTNERS =
  window.ENV.NOOM_ENVIRONMENT === "production"
    ? PROD_DPP_PARTNERS
    : TEST_DPP_PARTNERS;

export const B2C_PARTNERS =
  window.ENV.NOOM_ENVIRONMENT === "production"
    ? PROD_B2C_PARTNERS
    : TEST_B2C_PARTNERS;

export const PENDULUM_PARTNER_ID =
  window.ENV.NOOM_ENVIRONMENT === "production"
    ? PENDULUM_PARTNER_ID_PROD
    : PENDULUM_PARTNER_ID_TEST;

export const NOOM_TEST_PARTNER_ID =
  window.ENV.NOOM_ENVIRONMENT === "production"
    ? NOOM_TEST_PARTNER_ID_PROD
    : NOOM_TEST_PARTNER_ID_TEST;

export const EXTERNAL_COACH_PARTNER_ID =
  window.ENV.NOOM_ENVIRONMENT === "production"
    ? EXTERNAL_COACH_PARTNER_ID_PROD
    : EXTERNAL_COACH_PARTNER_ID_TEST;

export const ACCOLADE_PARTNER_ID =
  window.ENV.NOOM_ENVIRONMENT === "production"
    ? ACCOLADE_PARTNER_ID_PROD
    : ACCOLADE_PARTNER_ID_TEST;

export const STATE_FARM_PARTNERS =
  window.ENV.NOOM_ENVIRONMENT === "production"
    ? PROD_STATE_FARM_PARTNERS
    : TEST_STATE_FARM_PARTNERS;

/**
 * List of partners that should see the GLP-1 weight question.
 */
export const GLP1_WEIGHT_LOGIC_PARTNERS = [CAREFIRST_SLUG, KENT_COUNTY_SLUG];

/**
 * List of partners that should not display the health data consent form.
 */
export const HEALTH_DATA_CONSENT_DISABLED_PARTNERS = [
  BAPTIST_HEALTH_SLUG,
  CAREFIRST_SLUG,
  PENDULUM_SLUG,
  STAGING_CF_SLUG,
];

/**
 * List of partners that should not display the ability to opt out.
 */
export const OPT_OUT_DISABLED_PARTNERS = [
  BAPTIST_HEALTH_SLUG,
  CAREFIRST_SLUG,
  STAGING_CF_SLUG,
];

const DO_NOT_TRACK_PARTNERS = [
  BAPTIST_HEALTH_SLUG,
  CAREFIRST_SLUG,
  STAGING_CF_SLUG,
];

export const isDoNotTrackPartner = (partnerInfo: Partner) =>
  DPP_PARTNERS.includes(partnerInfo.id) ||
  DO_NOT_TRACK_PARTNERS.includes(partnerInfo.slug);
