import { differenceInYears, format } from "date-fns";

import {
  CoursePackSlug,
  DOB_FORMAT,
  HealthConditionOptionId,
  PrimaryCareProviderSelection,
  ProgramOffering,
} from "@/constants";
import { SurveyAnswers } from "@/contexts";
import { getEctExperiments } from "@/hooks";
import { Partner, User } from "@/models";
import type { SignupFormData } from "@/models/SignupFormData";
import { ExperimentAssignments } from "@/models/experiments";

import { isPersonalizationEnabled } from "./survey/contentPersonalization";

// Height and weight are required to create an account, but we don't show
// Noom Mood enrollees these fields and pass default values instead.
// The actual values aren't important.
const DEFAULT_HEIGHT_FT = 5;
const DEFAULT_HEIGHT_IN = 7;
const DEFAULT_WEIGHT_LB = 150;
const HEIGHT_MULTIPLIER_IN_TO_CM = 2.54;
const WEIGHT_MULTIPLIER_LB_TO_KG = 0.453592;

export const getCoursePacksToAssign = (
  program: ProgramOffering,
  surveyAnswers: SurveyAnswers,
  partnerInfo: Partner | null,
): CoursePackSlug[] => {
  const {
    healthConditions,
    primaryCareProvider,
    coursePackSelection = [],
    nutritionBasics,
    eatingMovingAffordably,
    culturalImpacts,
  } = surveyAnswers;

  const coursePacks: CoursePackSlug[] = [];

  if (
    healthConditions?.includes(HealthConditionOptionId.TYPE2_DIABETES) &&
    primaryCareProvider === PrimaryCareProviderSelection.YES
  ) {
    coursePacks.push("DIABETES_SUPPORT");
  }

  if (program === "WEIGHT" || program === "MED" || program === "NMPBA") {
    if (
      nutritionBasics === "notConfident" ||
      nutritionBasics === "somewhatConfident"
    ) {
      coursePacks.push("NUTRITION_BASICS");
    }
    if (eatingMovingAffordably && !eatingMovingAffordably.includes("none")) {
      coursePacks.push("EAT_AND_MOVE");
    }
    if (culturalImpacts === "always" || culturalImpacts === "occasionally") {
      coursePacks.push("NUTRITION_AND_CULTURE");
    }
  }

  if (isPersonalizationEnabled(partnerInfo, program)) {
    return [...coursePackSelection, ...coursePacks];
  }
  // DPP currently isn't shown content personalization.
  if (program === "DPP" && surveyAnswers.glp1Medication === "yes") {
    return ["GLP1"];
  }
  return coursePacks;
};

export const formatAsUserData = ({
  formData,
  b2bUserId,
  program,
  dppTriageKey,
  surveyAnswers,
  policyId,
  coursePacks,
  experiments,
}: {
  formData: SignupFormData;
  b2bUserId: string;
  program: ProgramOffering;
  dppTriageKey?: string;
  surveyAnswers: SurveyAnswers;
  policyId?: string;
  coursePacks: CoursePackSlug[];
  experiments: ExperimentAssignments;
}): User => {
  const {
    address,
    age,
    consentSignature,
    consentStatus,
    dob,
    email,
    employeeId,
    firstName,
    gender,
    heightFeetPortion = DEFAULT_HEIGHT_FT,
    heightInchesPortion = DEFAULT_HEIGHT_IN,
    lastName,
    locale,
    orderScale,
    password,
    phoneNumber: { dialCode, value },
    shippingAddress,
    weight = DEFAULT_WEIGHT_LB,
    mhmdaOptIn,
  } = formData;

  const formattedPhoneNumber = `+${dialCode}${value}`;

  const calculatedAge = dob
    ? differenceInYears(new Date(), dob as Date)
    : (age as number);

  const userData: User = {
    coursePacks,
    gender,
    locale,
    password,
    surveyAnswers,
    age: calculatedAge,
    email: email.trim(),
    heightInCm:
      (heightFeetPortion * 12 + heightInchesPortion) *
      HEIGHT_MULTIPLIER_IN_TO_CM,
    name: firstName.trim(),
    phoneNumber: formattedPhoneNumber,
    weightInKg: weight * WEIGHT_MULTIPLIER_LB_TO_KG,
    extras: {
      address,
      b2bUserId,
      consentStatus,
      mhmdaOptIn,
      orderScale,
      program,
      consentSignature: consentSignature?.trim(),
      employeeId: employeeId?.trim(),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      phoneNumber: formattedPhoneNumber,
    },
  };

  if (dob) {
    userData.extras = {
      ...userData.extras,
      dob: format(dob as Date, DOB_FORMAT),
    };
  }

  if (dppTriageKey) {
    userData.extras = {
      ...userData.extras,
      dppTriageKey,
    };
  }

  if (orderScale) {
    userData.extras = {
      ...userData.extras,
      shippingAddress: { ...(shippingAddress || address) },
    };
  }

  if (policyId) {
    userData.extras = {
      ...userData.extras,
      policyId,
    };
  }

  /**
   * Only store the medicare question answers if the user stays in Med and is
   * 65+ - we don't need to store these if the user opts out of Med
   *
   * Context: https://noom.slack.com/archives/C06T19HJWD9/p1715291960358249
   */
  if ((program === "MED" || program === "NMPBA") && calculatedAge > 64) {
    userData.extras = {
      ...userData.extras,
      enrolledInMedicare: surveyAnswers?.enrolledInMedicare,
      medicareConsent: surveyAnswers?.medicareConsent,
    };
  }

  if (
    surveyAnswers.liabilityWaiverBMI ||
    surveyAnswers.liabilityWaiverEatingDisorder ||
    surveyAnswers.liabilityWaiverPregnant
  ) {
    userData.extras = {
      ...userData.extras,
      liabilityWaiverConsent: "AGREE",
    };
  }

  const ectExperiments = getEctExperiments(experiments);
  if (ectExperiments.length) {
    userData.forceExperimentsToAssign = ectExperiments;
  }

  return userData;
};
