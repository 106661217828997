import i18next from "i18next";
import React from "react";

import { Select } from "@noom/wax-component-library";

import {
  NOOM_SUPPORTED_ENGLISH_LOCALES,
  NOOM_SUPPORTED_LANGUAGES,
} from "@/constants";

type DropdownOption = {
  label: string;
  value: string;
};

type PreferredLanguageSelectorProps = {
  helper?: string;
  label?: string;
  name?: string;
  supportedLocales: string[];
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

export const getDefaultLanguageValue = (): string => {
  const browserLanguages = window.navigator.languages;

  for (let i = 0; i < browserLanguages.length; i += 1) {
    const browserLanguage = browserLanguages[i];
    if (NOOM_SUPPORTED_LANGUAGES.includes(browserLanguage)) {
      return browserLanguage;
    }

    if (NOOM_SUPPORTED_LANGUAGES.includes(browserLanguage.slice(0, 2))) {
      return browserLanguage.slice(0, 2);
    }
  }

  return "en";
};

export const getAllLanguageOptions = (): DropdownOption[] => {
  const supportedLocalizedEnglishVersion =
    window.navigator.languages.find((browserLanguage) =>
      NOOM_SUPPORTED_ENGLISH_LOCALES.includes(browserLanguage),
    ) || "en";

  return [
    {
      label: i18next.t("form.preferredLanguageSelector.options.english"),
      value: supportedLocalizedEnglishVersion,
    },
    {
      label: i18next.t("form.preferredLanguageSelector.options.spanish"),
      value: "es",
    },
    {
      label: i18next.t("form.preferredLanguageSelector.options.german"),
      value: "de",
    },
    {
      label: i18next.t("form.preferredLanguageSelector.options.korean"),
      value: "ko",
    },
  ];
};

export const PreferredLanguageSelector: React.FC<
  PreferredLanguageSelectorProps
> = ({ supportedLocales, ...rest }) => {
  const languageOptions: DropdownOption[] = getAllLanguageOptions().filter(
    ({ value }) =>
      supportedLocales.find((language) => language === value.slice(0, 2)) ||
      false,
  );

  return (
    <Select
      helper={i18next.t("form.preferredLanguageSelector.helper")}
      label={i18next.t("form.preferredLanguageSelector.label")}
      {...rest}
    >
      {languageOptions.map(({ label, value }: DropdownOption) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </Select>
  );
};
