import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  CompassColor,
  CompassLoader,
  Heading,
  Stack,
  useMediaQuery,
} from "@noom/wax-component-library";

import { ConfettiBox } from "@/components/ConfettiBox";
import { NoomHeader } from "@/components/noom-branding";
import { EnrollmentSuccessType, ProgramOffering } from "@/constants";

type ProgressViewProps = {
  setIsDoneShowingProgress: React.Dispatch<React.SetStateAction<boolean>>;
  selectedProgram: ProgramOffering | null;
  enrollmentSuccessType: EnrollmentSuccessType;
};

type ProgressTexts = {
  inProgress: string;
  complete: string;
};

export const ProgressView: React.FC<ProgressViewProps> = ({
  setIsDoneShowingProgress,
  selectedProgram,
  enrollmentSuccessType,
}) => {
  const [isBrowser] = useMediaQuery("(min-width: 1160px)");
  const [isDoneSpinning, setIsDoneSpinning] = useState(false);
  const { t } = useTranslation();

  const enrollmentSuccessTypeProgressTextsMap: Record<
    EnrollmentSuccessType,
    ProgressTexts
  > = {
    [EnrollmentSuccessType.TRANSITIONED]: {
      inProgress: t("success.progress.inProgress.transitioned"),
      complete: t("success.progress.complete.transitioned"),
    },
    [EnrollmentSuccessType.CREATED]: {
      inProgress: t("success.progress.inProgress.created"),
      complete: t("success.progress.complete.created"),
    },
    [EnrollmentSuccessType.UPGRADED]: {
      inProgress: t("success.progress.inProgress.created"),
      complete: t("success.progress.complete.created"),
    },
  };

  const texts = enrollmentSuccessTypeProgressTextsMap[enrollmentSuccessType];
  const inProgressText = texts.inProgress;
  const completeText =
    selectedProgram === "MED"
      ? t("success.progress.complete.med")
      : texts.complete;

  useEffect(() => {
    // The default CompassLoader finishes spinning in 2.5s.
    // The setTimeout for 3.75s will allow it to spin for 1.5 rotations.
    window.setTimeout(() => setIsDoneSpinning(true), 3750);
  }, []);

  useEffect(() => {
    if (!isDoneSpinning) {
      return;
    }

    // This will display the confetti gif for 3s after the loader has finished
    // spinning before proceeding to the Download screen.
    window.setTimeout(() => setIsDoneShowingProgress(true), 3000);
  }, [isDoneSpinning]);

  return (
    <Box>
      {isBrowser && <NoomHeader />}
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        gap={{ base: "var(--spacing-xxxl)", lg: "var(--spacing-xxh)" }}
        marginTop={{ base: "var(--spacing-xh)", lg: "0px" }}
      >
        {isDoneSpinning && selectedProgram !== "MED" ? (
          <ConfettiBox />
        ) : (
          <Stack alignItems="center">
            <CompassLoader color={CompassColor.black} size="lg" />
          </Stack>
        )}
        <Heading
          fontSize={{ base: "24px", lg: "28px" }}
          fontWeight={500}
          lineHeight={{ base: "32px", lg: "36px" }}
        >
          {!isDoneSpinning ? inProgressText : completeText}
        </Heading>
      </Box>
    </Box>
  );
};
