import React from "react";

import { Button } from "@noom/wax-component-library";

import { MultipleChoiceOptions } from "@/components/survey/multipleChoice";

import { QuestionComponentProps, QuestionOption } from "../../Question";

import { BaseQuestion } from "./BaseQuestion";

type MultipleChoiceQuestionProps = Omit<
  QuestionComponentProps,
  "surveyAnswers"
> & {
  confirmationElement?: React.ReactElement;
  ctaText?: string;
  options: QuestionOption[];
  questionDescription?: string;
  questionHelperText?: string;
  questionId: string;
  questionText: string;
  selectedItem: string;
};

export const MultipleChoiceQuestion: React.FC<MultipleChoiceQuestionProps> = ({
  confirmationElement,
  ctaText,
  onClickBack,
  onClickNext,
  options,
  questionDescription,
  questionHelperText,
  questionId,
  questionText,
  selectedItem,
}) => (
  <BaseQuestion
    onClickBack={onClickBack}
    questionDescription={questionDescription}
    questionHelperText={questionHelperText}
    questionId={questionId}
    questionText={questionText}
  >
    <MultipleChoiceOptions
      selectedItem={selectedItem}
      options={options}
      onClick={(optionId: string) =>
        onClickNext(optionId, undefined, undefined, "option")
      }
    />
    {confirmationElement}
    {ctaText && (
      <Button
        colorScheme="primary"
        onClick={() =>
          onClickNext(selectedItem, undefined, undefined, "button")
        }
        size="xl"
      >
        {ctaText}
      </Button>
    )}
  </BaseQuestion>
);
