
import { Api } from "@noom/noomscape";

import { Partner } from "@/models";

import axios from "axios";

type RequestParams = {
  upid: string;
};

Api.register("partner.getPartnerByUpid", async ({ upid }: RequestParams) => {
  const response = await axios({
    baseURL: window.ENV.SERVER_URL,
    method: "GET",
    params: {
      upid,
    },
    url: "/partners/v1/getByUpid",
    urlWithPathParams: "/partners/v1/getByUpid",
  });
  const partnerInfo: Partner = response.data;
  if (partnerInfo.goLiveDate) {
    partnerInfo.goLiveDate = new Date(partnerInfo.goLiveDate);
  }
  return partnerInfo;
});
