import { Formik } from "formik";
import i18next, { TFunction } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { GetB2bEmailAvailabilityInformationResponseProto } from "@noom/noom-contracts/noom_contracts/backend/enrollment/b2b/enrollment_information";
import { Api } from "@noom/noomscape";
import {
  Input,
  Spacing,
  Stack,
  Button,
  Checkbox,
} from "@noom/wax-component-library";

import { GenericConsent } from "@/components/noom-branding";
import { ErrorState } from "@/constants";
import { useAppContext } from "@/contexts";
import { useGeolocation, useGoto, useQueryParams } from "@/hooks";
import { isB2CPartner } from "@/utils/partners";
import { captureException } from "@/utils/sentry";

const EMAIL_MAX_LENGTH = 190; // Account table supports emails of 191 characters

export type CreateAccountFormValues = {
  email: string;
  mhmdaOptIn?: boolean;
};

export const defaultInitialValues: CreateAccountFormValues = {
  email: "",
};

export const defaultInitialValuesMHMDA: CreateAccountFormValues = {
  ...defaultInitialValues,
  mhmdaOptIn: false,
};

const getValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .max(
        EMAIL_MAX_LENGTH,
        t("form.errors.maxLength", {
          label: t("form.email.label"),
          maxLength: EMAIL_MAX_LENGTH,
        }),
      )
      .email(t("form.email.errors.invalid"))
      .required(t("form.email.errors.required")),
  });

export const CreateAccountForm: React.FC<{
  initialValues?: CreateAccountFormValues;
  onSubmit: (values: CreateAccountFormValues) => void;
}> = ({ onSubmit, initialValues = defaultInitialValues }) => {
  const { hasB2cAccount, partnerInfo, setHasB2cAccount } = useAppContext();
  const { canOptOutMHMDA } = useGeolocation();
  const goto = useGoto();
  const { eligibilityId } = useQueryParams();
  const [submittedValues, setSubmittedValues] = useState<
    CreateAccountFormValues | undefined
  >();
  const { t } = useTranslation();

  const showMhmdaCheckBox = isB2CPartner(partnerInfo) && canOptOutMHMDA();

  async function getB2bEmailAvailabilityInformation(
    values: CreateAccountFormValues,
  ) {
    try {
      const {
        availabilityResult,
      }: GetB2bEmailAvailabilityInformationResponseProto = await Api.call(
        "account.getB2bEmailAvailabilityInformation",
        Api.api.account.getB2bEmailAvailabilityInformation,
        {
          email: values.email,
        },
      );

      if (availabilityResult === "HAS_B2B_ACCOUNT") {
        goto.error(ErrorState.ACCOUNT_EXISTS);
        return;
      }

      setHasB2cAccount(availabilityResult === "HAS_B2C_ACCOUNT");
    } catch (e) {
      captureException(e);
      goto.error(ErrorState.DEFAULT);
    }
  }

  // getB2bEmailAvailabilityInformation after the form values are set in local state
  useEffect(() => {
    if (submittedValues) {
      getB2bEmailAvailabilityInformation(submittedValues);
    }
  }, [submittedValues]);

  // Clear hasB2cAccount on mount so an effect can react to it.
  // If we were to leave it as a previously set boolean value, we don't know if its been re-evaluated.
  useEffect(() => {
    setHasB2cAccount(undefined);
  }, []);

  // only call onSubmit after hasB2cAccount has been evaluated and updated in app state.
  useEffect(() => {
    if (hasB2cAccount === undefined || submittedValues === undefined) {
      return;
    }
    onSubmit(submittedValues);
  }, [hasB2cAccount, submittedValues]);

  return (
    <Formik
      initialValues={
        showMhmdaCheckBox ? defaultInitialValuesMHMDA : initialValues
      }
      onSubmit={(values) => setSubmittedValues({ ...values })}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={getValidationSchema(t)}
    >
      {({
        errors,
        getFieldProps,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        validateField,
        values,
      }) => {
        const handleInputChange = async (
          e: React.ChangeEvent<HTMLInputElement>,
          fieldName: keyof CreateAccountFormValues,
        ) => {
          // This is a hacky method to get each field to validate on change
          // individually.
          await handleChange(e);
          validateField(fieldName);
        };

        return (
          <form onSubmit={handleSubmit}>
            <Stack spacing={Spacing[8]}>
              <Input
                {...getFieldProps("email")}
                autoFocus
                error={touched.email && errors.email}
                isReadOnly={Boolean(
                  eligibilityId &&
                    initialValues.email &&
                    partnerInfo?.configs?.UPFRONT_ELIGIBILITY_ENFORCEMENT ===
                      "EMAIL",
                )}
                label={t("form.email.label")}
                onChange={(e) => handleInputChange(e, "email")}
                placeholder={t("form.email.placeholder")}
                type="email"
                helper={
                  i18next.exists(
                    `form.email.helper.partners.${partnerInfo?.slug}`,
                  )
                    ? t(`form.email.helper.partners.${partnerInfo?.slug}`)
                    : undefined
                }
              />
              {showMhmdaCheckBox && (
                <Checkbox
                  {...getFieldProps("mhmdaOptIn")}
                  isChecked={values.mhmdaOptIn === true}
                >
                  {t("form.mhmdaOptIn.label")}
                </Checkbox>
              )}
              <GenericConsent ctaText={t("createAccount.ctaText")} />
              <Button
                colorScheme="primary"
                isLoading={isSubmitting}
                size="xl"
                type="submit"
              >
                {t("createAccount.ctaText")}
              </Button>
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};
