import { TriageResultProto_Value } from "@noom/noom-contracts/noom_contracts/backend/dpp/dpp_service";
import { Api } from "@noom/noomscape";

import { UpdateLastTriageTimestampRequest } from "@/api/dpp/updateLastTriageTimestamp";
import { useAppContext } from "@/contexts";
import { isB2CPartner } from "@/utils/partners";
import { captureException } from "@/utils/sentry";

export const useUpdateLastTriageTimestamp = () => {
  const { partnerInfo, surveyAnswers } = useAppContext();
  const updateLastTriageTimestamp = async (
    accessCode: string,
    triageResult: TriageResultProto_Value,
  ) => {
    if (isB2CPartner(partnerInfo)) {
      return null;
    }

    const request: UpdateLastTriageTimestampRequest = {
      accessCode,
      newLastTriageTimestamp: new Date().toISOString(),
      triageResult,
      wasOfferedDpp: !!surveyAnswers.wasOfferedDpp,
      wasOfferedMed: !!surveyAnswers.wasOfferedMed,
    };
    return Api.call(
      "dpp.updateLastTriageTimestamp",
      Api.api.dpp.updateLastTriageTimestamp,
      request,
    ).catch((e: unknown) => {
      captureException(e);
    });
  };

  return { updateLastTriageTimestamp };
};
