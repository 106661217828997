import type {
  GetB2bEmailAvailabilityInformationRequestProto_Body,
  GetB2bEmailAvailabilityInformationResponseProto,
} from "@noom/noom-contracts/noom_contracts/backend/enrollment/b2b/enrollment_information";
import { Api } from "@noom/noomscape";

import axios from "axios";

Api.register(
  "account.getB2bEmailAvailabilityInformation",
  async (
    data: GetB2bEmailAvailabilityInformationRequestProto_Body,
  ): Promise<GetB2bEmailAvailabilityInformationResponseProto> => {
    const response = await axios({
      data,
      baseURL: window.ENV.SERVER_URL,
      method: "POST",
      url: "/b2b-enrollment/getB2bEmailAvailabilityInformation",
      urlWithPathParams: "/b2b-enrollment/getB2bEmailAvailabilityInformation",
    });

    return response.data;
  },
);
