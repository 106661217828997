import React, { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  Button,
  CompassColor,
  Image,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { NoomMedMoreOptionsSelection, PrimaryCareProviderSelection, ProgramOffering } from "@/constants";
import { useAppContext } from "@/contexts";
import { partnerOffersPrograms } from "@/utils/programs";

import { BaseInterstitial, BaseQuestion } from "../../core";

import { DppDescriptionForMed } from "./DppDescriptionForMed";

const QUESTION_ID = "programSet";

type ProgramSetConfig = {
  backgroundColor?: CompassColor;
  ctaText: string;
  footerText: string;
  headerText: string;
  helperText: string;
  imageAlt: string;
  imageSrc: string;
};

type ValueProp = {
  caption: ReactElement;
  url: string;
};

// Mood program does not show this question
type WeightLossProgram = Exclude<ProgramOffering, "MOOD">;

const VALUE_PROPS: ValueProp[] = [
  {
    caption: <Trans i18nKey="programSet.weightDescriptionForMed.valueProp1" />,
    url: "/assets/img/icon-trophy.svg",
  },
  {
    caption: <Trans i18nKey="programSet.weightDescriptionForMed.valueProp2" />,
    url: "/assets/img/icon-graph.svg",
  },
  {
    caption: <Trans i18nKey="programSet.weightDescriptionForMed.valueProp3" />,
    url: "/assets/img/icon-fire.svg",
  },
];

const WeightDescriptionForMedQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const { eligibilityCheck, noomMedMoreOptions } = surveyAnswers;

  return (
    <BaseQuestion
      footer={t("programSet.weightDescriptionForMed.footerText")}
      footerPosition="static"
      onClickBack={onClickBack}
      questionId={QUESTION_ID}
      questionText={t("programSet.weightDescriptionForMed.questionText", {
        firstName: eligibilityCheck?.firstName,
      })}
    >
      {/* This isn't using the prop in BaseQuestion cause of the font size */}
      <Text fontSize="18px" lineHeight="26px">
        {t("programSet.weightDescriptionForMed.questionHelperText")}
      </Text>
      <Stack spacing={Spacing[6]}>
        {VALUE_PROPS.map(({ caption, url }) => (
          <Box
            alignItems="flex-start"
            display="flex"
            gap="var(--spacing-m)"
            key={url}
          >
            <Image alt="" src={url} />
            <Text fontSize="18px" lineHeight="26px">
              {caption}
            </Text>
          </Box>
        ))}
      </Stack>
      <Box display="flex" justifyContent="center">
        <Image
          alt="A graph representing how conventional diets can result in regaining weight vs. keeping it off with Noom"
          src="/assets/img/yoyo-graph.svg"
          width="100%"
        />
      </Box>
      <Button
        colorScheme="primary"
        onClick={() => onClickNext(null)}
        size="xl"
        type="submit"
      >
        {t("programSet.weightDescriptionForMed.ctaText")}
      </Button>
      {noomMedMoreOptions !== NoomMedMoreOptionsSelection.OTHER && (
        <Box
          backgroundColor={CompassColor.offWhite}
          marginTop="var(--spacing-m)"
          padding="12px"
        >
          <Text>{t("programSet.weightDescriptionForMed.disclaimer")}</Text>
        </Box>
      )}
    </BaseQuestion>
  );
};

const ProgramSet: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { enrollmentInfo, partnerInfo, selectedProgram } = useAppContext();
  const { primaryCareProvider, diabetesSupport } = surveyAnswers
  const { t } = useTranslation();

  const { firstName = "" } = surveyAnswers.eligibilityCheck || {};

  const programSetConfigMap: Record<WeightLossProgram, ProgramSetConfig> = {
    DPP: {
      ctaText: t("programSet.baseInterstitial.ctaText.dpp"),
      footerText: t("programSet.baseInterstitial.footerText.dpp"),
      headerText: t("programSet.baseInterstitial.headerText.dpp", {
        firstName,
      }),
      helperText: t("programSet.baseInterstitial.helperText.dpp"),
      imageAlt:
        "A graph representing how conventional diets can result in regaining weight vs. keeping it off with Noom",
      imageSrc: "/assets/img/yoyo-graph.svg",
    },
    MED: {
      backgroundColor: CompassColor.salmon,
      ctaText: t("programSet.baseInterstitial.ctaText.med"),
      footerText: t("programSet.baseInterstitial.footerText.med"),
      headerText: t("programSet.baseInterstitial.headerText.med"),
      helperText: t("programSet.baseInterstitial.helperText.med"),
      imageAlt: "A big thumb with two people climbing up it",
      imageSrc: "/assets/img/big-thumb-mountain.webp",
    },
    NMPBA: {
      backgroundColor: CompassColor.salmon,
      ctaText: t("programSet.baseInterstitial.ctaText.med"),
      footerText: t("programSet.baseInterstitial.footerText.med"),
      headerText: t("programSet.baseInterstitial.headerText.med"),
      helperText: t("programSet.baseInterstitial.helperText.med", {
        context: "accolade",
      }),
      imageAlt: "A big thumb with two people climbing up it",
      imageSrc: "/assets/img/big-thumb-mountain.webp",
    },
    WEIGHT: {
      ctaText: t("programSet.baseInterstitial.ctaText.weight"),
      footerText: t("programSet.baseInterstitial.footerText.weight"),
      headerText: t("programSet.baseInterstitial.headerText.weight", {
        firstName,
      }),
      helperText: t("programSet.baseInterstitial.helperText.weight"),
      imageAlt:
        "A graph representing how conventional diets can result in regaining weight vs. keeping it off with Noom",
      imageSrc: "/assets/img/yoyo-graph.svg",
    },
  };

  if (partnerOffersPrograms(["MED"], enrollmentInfo, partnerInfo)) {
    if (selectedProgram === "DPP") {
      return (
        <DppDescriptionForMed
          onClickBack={onClickBack}
          onClickNext={onClickNext}
          firstName={surveyAnswers?.eligibilityCheck?.firstName || ""}
          questionId={QUESTION_ID}
        />
      );
    }
    if (selectedProgram === "WEIGHT") {
      return (
        <WeightDescriptionForMedQuestion
          onClickBack={onClickBack}
          onClickNext={onClickNext}
          surveyAnswers={surveyAnswers}
        />
      );
    }
  }

  const interstitialProps =
    programSetConfigMap[selectedProgram as WeightLossProgram];

  /* TODO: The condition for diabetes support should be updated after the requirements are finalized.
      Current condition added for E2E testing purposes. */
  if (primaryCareProvider === PrimaryCareProviderSelection.YES && diabetesSupport) {
    return (
      <BaseInterstitial
        {...interstitialProps}
        helperText={t("programSet.baseInterstitial.helperText.diabetes")}
        onClickBack={onClickBack}
        onClickNext={onClickNext}
        questionId={QUESTION_ID}
        width="600px"
      />
    );
  }

  return (
    <BaseInterstitial
      {...interstitialProps}
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      questionId={QUESTION_ID}
      width="600px"
    />
  );
};

export const Q_PROGRAM_SET: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ selectedProgram }) => selectedProgram !== "MOOD",
  component: ProgramSet,
};
