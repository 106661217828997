import React from "react";
import { useTranslation } from "react-i18next";

import { partnerOffersPrograms } from "@/utils/programs";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "gestationalDiabetes";

declare module "@/contexts" {
  interface SurveyAnswers {
    gestationalDiabetes?: string;
  }
}

const GestationalDiabetesQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    { id: "yes", text: t("gestationalDiabetes.options.yes") },
    { id: "no", text: t("gestationalDiabetes.options.no") },
  ];

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionId={QUESTION_ID}
      questionText={t("gestationalDiabetes.questionText")}
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_GESTATIONAL_DIABETES: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    enrollmentInfo,
    partnerInfo,
    selectedProgram,
    surveyAnswers: { dppEligible, sex },
  }) =>
    partnerOffersPrograms(["DPP"], enrollmentInfo, partnerInfo) &&
    selectedProgram !== "MOOD" &&
    dppEligible !== false &&
    (sex === "female" || sex === "intersex" || sex === "preferNotToSay"),
  component: GestationalDiabetesQuestion,
};

export const Q_RETRIAGE_GESTATIONAL_DIABETES: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    retriageEligibility: { currentProgram, eligibleRetriagePrograms = [] } = {},
    surveyAnswers: { dppEligible, sex },
  }) =>
    currentProgram === "WEIGHT" &&
    eligibleRetriagePrograms.includes("DPP") &&
    dppEligible !== false &&
    (sex === "female" || sex === "intersex" || sex === "preferNotToSay"),
  component: GestationalDiabetesQuestion,
};
