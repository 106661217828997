import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button, Heading, Spacing, Stack } from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { InformationalScreen } from "@/components/survey/questionTypes/core";
import { PregnantSelection } from "@/constants";
import { useAppContext } from "@/contexts";
import { calculateBMI } from "@/utils/calculateBMI";
import { partnerOffersPrograms } from "@/utils/programs";
import { captureException } from "@/utils/sentry";

declare module "@/contexts" {
  interface SurveyAnswers {
    liabilityWaiverAlertEatingDisorder?: boolean;
    liabilityWaiverAlertBMI?: boolean;
    liabilityWaiverAlertPregnant?: boolean;
  }
}

const LiabilityWaiverAlert: (
  questionId: string,
) => React.FC<QuestionComponentProps> = (questionId) => {
  const Component: React.FC<QuestionComponentProps> = ({
    onClickNext,
    surveyAnswers,
  }) => {
    const { enrollmentInfo, partnerInfo, setSurveyAnswers } = useAppContext();
    useTranslation();

    const offersDpp = partnerOffersPrograms(
      ["DPP"],
      enrollmentInfo,
      partnerInfo,
    );
    const offersMed = partnerOffersPrograms(
      ["MED"],
      enrollmentInfo,
      partnerInfo,
    );

    let eligibilityTextContext = "weight";
    if (offersDpp && offersMed) {
      eligibilityTextContext = "dpp_and_med";
    } else if (offersDpp) {
      eligibilityTextContext = "dpp";
    } else if (offersMed) {
      eligibilityTextContext = "med";
    }

    useEffect(() => {
      // clear prior answer in case of back navigation.
      setSurveyAnswers({ ...surveyAnswers, [questionId]: undefined });
    }, []);

    return (
      <InformationalScreen questionId={questionId}>
        <Stack gap={Spacing[5]}>
          <Heading>
            <Trans i18nKey="liabilityWaiverAlert.heading" />
          </Heading>
          <Trans i18nKey="liabilityWaiverAlert.body" />
          <strong>
            <Trans
              i18nKey="liabilityWaiverAlert.eligibilityText"
              context={eligibilityTextContext}
            />
          </strong>
          <Button
            colorScheme="primary"
            onClick={() => onClickNext(true)}
            size="xl"
            display="flex"
            margin="auto"
            maxWidth="400px"
          >
            <Trans i18nKey="liabilityWaiverAlert.cta" />
          </Button>
        </Stack>
      </InformationalScreen>
    );
  };

  return Component;
};

const QUESTION_ID_EATING_DISORDER = "liabilityWaiverAlertEatingDisorder";
export const Q_LIABILITY_WAIVER_ALERT_EATING_DISORDER: QuestionDefinition = {
  id: QUESTION_ID_EATING_DISORDER,
  shouldShowQuestion: ({
    surveyAnswers: {
      eatingDisorder,
      liabilityWaiverAlertBMI,
      liabilityWaiverAlertPregnant,
    },
  }) =>
    eatingDisorder === "yes" &&
    !(liabilityWaiverAlertBMI || liabilityWaiverAlertPregnant),
  component: LiabilityWaiverAlert(QUESTION_ID_EATING_DISORDER),
};

const QUESTION_ID_BMI = "liabilityWaiverAlertBMI";
export const Q_LIABILITY_WAIVER_ALERT_BMI: QuestionDefinition = {
  id: QUESTION_ID_BMI,
  shouldShowQuestion: ({
    selectedProgram,
    surveyAnswers: {
      heightWeight,
      liabilityWaiverAlertEatingDisorder,
      liabilityWaiverAlertPregnant,
    },
  }) => {
    if (selectedProgram === "MOOD") {
      return false;
    }

    if (!heightWeight) {
      captureException(
        Error(
          "Height and weight answers missing when determining is liabilityWaiverAlertBMI is shown",
        ),
      );
      return false;
    }
    const bmi = calculateBMI(
      heightWeight.heightFeetPortion!,
      heightWeight.heightInchesPortion!,
      heightWeight.weight!,
    );

    if (
      bmi < 18.5 &&
      !(liabilityWaiverAlertEatingDisorder || liabilityWaiverAlertPregnant)
    ) {
      return true;
    }
    return false;
  },
  component: LiabilityWaiverAlert(QUESTION_ID_BMI),
};

const QUESTION_ID_PREGNANT = "liabilityWaiverAlertPregnant";
export const Q_LIABILITY_WAIVER_ALERT_PREGNANT: QuestionDefinition = {
  id: QUESTION_ID_PREGNANT,
  shouldShowQuestion: ({
    surveyAnswers: {
      pregnant,
      liabilityWaiverAlertEatingDisorder,
      liabilityWaiverAlertBMI,
    },
  }) => {
    if (
      pregnant === PregnantSelection.YES &&
      !(liabilityWaiverAlertEatingDisorder || liabilityWaiverAlertBMI)
    ) {
      return true;
    }
    return false;
  },
  component: LiabilityWaiverAlert(QUESTION_ID_PREGNANT),
};
