import { Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  Box,
  Button,
  CompassColor,
  Heading,
  Input,
  Loader,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import { NoomEmailAddressLink, NoomHeader } from "@/components/noom-branding";
import { useGeolocation } from "@/hooks";
import { setOptOut } from "@/utils/consent/opt-out";

type FormValues = {
  email: string;
};

const initialValues: FormValues = {
  email: "",
};

const OptOutForm = () => {
  const { canOptOut, country, isLoading, state } = useGeolocation();
  const [isOptOutSuccessful, setIsOptOutSuccessful] = useState(false);
  const { t } = useTranslation();

  const MAX_LENGTH = 180;
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .max(
        MAX_LENGTH,
        t("form.errors.maxLength", {
          label: "Email address",
          maxLength: MAX_LENGTH,
        }),
      )
      .email(t("form.email.errors.invalid"))
      .required(t("form.email.errors.required")),
  });

  const onSubmit = async (
    { email }: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    try {
      await setOptOut(country, email, state);
      setIsOptOutSuccessful(true);
    } catch (e) {
      actions.setFieldError("email", t("doNotShare.optOutError"));
    }
  };

  if (isLoading) {
    return <Loader size="xl" colorScheme="primary" />;
  }

  if (!canOptOut()) {
    return (
      <Text size="lg">
        <Trans
          components={{ supportEmail: NoomEmailAddressLink }}
          i18nKey="doNotShare.regionNotApplicable"
        />
      </Text>
    );
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({
          dirty,
          errors,
          getFieldProps,
          handleSubmit,
          isSubmitting,
          isValid,
          touched,
        }) => (
          <form onSubmit={handleSubmit}>
            <Stack spacing={Spacing[8]}>
              <Input
                {...getFieldProps("email")}
                autoFocus
                error={touched.email && errors.email}
                label={t("doNotShare.email.label")}
                placeholder={t("doNotShare.email.placeholder")}
                maxWidth="none"
                size="lg"
              />
              <Button
                colorScheme="primary"
                isDisabled={!(dirty && isValid)}
                isLoading={isSubmitting}
                marginBottom="var(--spacing-m)"
                maxWidth="none"
                size="xl"
                type="submit"
                whiteSpace="normal"
              >
                {t("doNotShare.ctaText")}
              </Button>
            </Stack>
          </form>
        )}
      </Formik>
      {isOptOutSuccessful && (
        <Text color={CompassColor.kale} size="lg">
          {t("doNotShare.optOutSuccess")}
        </Text>
      )}
    </>
  );
};

export const DoNotShareView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <NoomHeader />
      <Box margin="auto" maxWidth="1280px" padding="var(--spacing-m)">
        <Stack marginBottom="var(--spacing-xxh)" spacing={Spacing[8]}>
          <Heading fontSize="28px" lineHeight="36px">
            {t("doNotShare.header")}
          </Heading>
          <Text size="lg">{t("doNotShare.description1")}</Text>
          <Text size="lg">{t("doNotShare.description2")}</Text>
          <Text size="lg">{t("doNotShare.description3")}</Text>
        </Stack>
        <Box
          border={`1px solid ${CompassColor.black}`}
          borderRadius="8px"
          margin="auto"
          maxWidth="800px"
          padding="var(--spacing-l)"
          textAlign="center"
        >
          <OptOutForm />
        </Box>
      </Box>
    </Box>
  );
};
