import { Progress } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Text } from "@noom/wax-component-library";

type ProgressBarProps = {
  onComplete?: () => void;
  totalTimeMs: number;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({
  onComplete,
  totalTimeMs,
}) => {
  const [percentage, setPercentage] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const intervalId = setInterval(
      () =>
        setPercentage((percent) => {
          if (percent === 100) {
            clearInterval(intervalId);
            return percent;
          }
          return percent + 1;
        }),
      totalTimeMs / 100,
    );

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (percentage === 100) {
      onComplete?.();
    }
  }, [percentage]);

  return (
    <Box>
      <Text
        display="flex"
        fontFamily="Brown LL"
        fontSize="12px"
        fontWeight={500}
        justifyContent="center"
        lineHeight="18px"
        marginBottom="12px"
        textTransform="uppercase"
      >
        {t("personalizingProgram.progressBarMessage")}
      </Text>
      <Box position="relative">
        <Progress
          colorScheme="primary"
          height="48px"
          size="lg"
          value={percentage}
          width="300px"
        />
        <Text
          bottom="50%"
          fontWeight={500}
          left="50%"
          position="absolute"
          transform="translateX(-50%) translateY(50%)"
        >
          {percentage}%
        </Text>
      </Box>
    </Box>
  );
};
