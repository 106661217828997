/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState } from "react";

import { MigrateUserRequestProto_Body } from "@noom/noom-contracts/noom_contracts/backend/retriage/retriage_service";
import { AddressProto } from "@noom/noom-contracts/noom_contracts/billing/address/address";
import { Api } from "@noom/noomscape";

import { PhoneNumber } from "@/components/form";
import { ProgramOffering } from "@/constants";
import { useAppContext } from "@/contexts";
import { SurveyAnswers } from "@/contexts/app-context";
import { Address, Partner, RetriageEligibility } from "@/models";
import { getOfferingFromProgram } from "@/utils/programs";
import { sanitizeRetriageAnswers } from "@/utils/retriage";

function buildAddressProto(
  address: Address,
  phoneNumber?: PhoneNumber,
): AddressProto {
  const proto: AddressProto = {
    addressLine1: address.address1,
    addressLine2: address.address2,
    city: address.city,
    zoneCode: address.region,
    postalCode: address.zipcode,
    countryCode: address.country,
  };
  if (phoneNumber) {
    proto.phoneNumber = phoneNumber.dialCode.concat(phoneNumber.value);
  }
  return proto;
}

function buildRequest(
  surveyAnswers: SurveyAnswers,
  selectedProgram: ProgramOffering | null,
  retriageEligibility: RetriageEligibility,
  partnerInfo: Partner,
  b2bIdentifiedUserId: string,
): MigrateUserRequestProto_Body {
  if (!selectedProgram) {
    throw new Error("Cannot migrate user: no selected program");
  }

  const request: MigrateUserRequestProto_Body = {
    accessCode: retriageEligibility.accessCode!,
    survey: {},
    surveyJson: sanitizeRetriageAnswers(surveyAnswers),
    offering: getOfferingFromProgram(selectedProgram),
    partnerId: partnerInfo.id,
    completionStatus: "COMPLETE",
    wasOfferedDpp: !!surveyAnswers.wasOfferedDpp,
    wasOfferedMed: !!surveyAnswers.wasOfferedMed,
  };
  if (selectedProgram === "MED") {
    request.medRetriageData = {
      userId: b2bIdentifiedUserId,
      homeAddress: buildAddressProto(
        surveyAnswers.medSignup!.address,
        surveyAnswers.medSignup!.phoneNumber,
      ),
      mainReason: surveyAnswers.mainReason!,
      familyHistory: surveyAnswers.familyHistory!,
      pregnant: surveyAnswers.pregnant! || "no",
      healthConditions: surveyAnswers.healthConditions || [],
      heightFeet: surveyAnswers.heightWeight!.heightFeetPortion!,
      heightInch: surveyAnswers.heightWeight!.heightInchesPortion!,
      weightLbs: surveyAnswers.heightWeight!.weight!,
      useMedications: Number(surveyAnswers.medications) > 0 ? "yes" : "no",
      suicideOrSelfHarm1: surveyAnswers.phq4!,
      suicideOrSelfHarm2: surveyAnswers.phq3!,
    };
  }
  if (surveyAnswers.scaleOptIn) {
    request.scaleShippingAddress = buildAddressProto(
      surveyAnswers.medSignup!.shippingAddress ||
        surveyAnswers.medSignup!.address,
    );
  }
  return request;
}

export const useMigrateUser = () => {
  const [apiError, setError] = useState<unknown>();
  const [apiLoading, setApiLoading] = useState(true);
  const {
    surveyAnswers,
    retriageEligibility,
    partnerInfo,
    b2bIdentifiedUserId,
    selectedProgram,
  } = useAppContext();

  const migrateUser = async () => {
    if (!retriageEligibility) {
      throw Error("Cannot migrate to med: No RetriageEligibility found");
    }
    if (!partnerInfo) {
      throw Error("Cannot migrate to med: No partner info");
    }

    try {
      await Api.call(
        "retriage.migrateUser",
        Api.api.retriage.migrateUser,
        buildRequest(
          surveyAnswers,
          selectedProgram,
          retriageEligibility,
          partnerInfo,
          b2bIdentifiedUserId,
        ),
      );
      setApiLoading(false);
    } catch (e) {
      const err = e as Error;
      setError(err.message);
      setApiLoading(false);
    }
  };

  return { migrateUser, apiLoading, apiError };
};
