import { B2BProductOfferingProto } from "@noom/noom-contracts/noom_contracts/backend/enrollment/b2b/enrollment_offerings";

import { Curriculum, ProgramOffering } from "@/constants";
import { EnrollmentInformation, Partner } from "@/models";

const CURRICULUM_TO_PROGRAM_OFFERING_MAP: Record<
  Curriculum,
  ProgramOffering | undefined
> = {
  [Curriculum.CURRICULUM_UNSPECIFIED]: undefined,
  [Curriculum.DPP]: "DPP",
  [Curriculum.HM]: "MOOD",
  [Curriculum.HW]: "WEIGHT",
};

export const PROGRAM_OFFERING_TO_CURRICULUM_MAP: Record<
  ProgramOffering,
  Curriculum
> = {
  DPP: Curriculum.DPP,
  MED: Curriculum.HW,
  MOOD: Curriculum.HM,
  NMPBA: Curriculum.HW,
  WEIGHT: Curriculum.HW,
};

/**
 * Gets the curriculum associated with a program.
 *
 * @param {ProgramOffering | null} program
 * @returns {Curriculum}
 */
export const getCurriculumFromProgramOffering = (
  program: ProgramOffering | null,
): Curriculum => {
  if (program === null) {
    return Curriculum.CURRICULUM_UNSPECIFIED;
  }

  return PROGRAM_OFFERING_TO_CURRICULUM_MAP[program];
};

/**
 * Get partner offerings all of the given programs - used to know if
 * certain pages or features should be gated behind those programs.
 *
 * @param enrollmentInfo Enrollment info of the UPID - used to check what
 * programs the UPID can be enrolled into
 * @param partnerInfo Information about the partner - used to check if certain
 * configs are enabled (e.g. if Noom Med is offered)
 * @returns List of programs that the partner offers
 */
export const getPartnerProgramOfferings = (
  enrollmentInfo: EnrollmentInformation | undefined,
  partnerInfo: Partner | null,
): ProgramOffering[] => {
  const { eligiblePrograms = [], triagePrograms = [] } = enrollmentInfo || {};
  const { configs: { OFFERS_MED = false } = {} } = partnerInfo || {};

  const partnerProgramOfferings = [...eligiblePrograms, ...triagePrograms]
    .map((curriculum) => CURRICULUM_TO_PROGRAM_OFFERING_MAP[curriculum])
    .filter((program) => program) as ProgramOffering[];

  if (OFFERS_MED) {
    partnerProgramOfferings.push("MED");
  }
  return partnerProgramOfferings;
};

/**
 * Determines if a partner offers all of the given programs - used to know if
 * certain pages or features should be gated behind those programs.
 *
 * @param programs List of programs to check against
 * @param enrollmentInfo Enrollment info of the UPID - used to check what
 * programs the UPID can be enrolled into
 * @param partnerInfo Information about the partner - used to check if certain
 * configs are enabled (e.g. if Noom Med is offered)
 * @returns True if the given programs are offered, false otherwise
 */
export const partnerOffersPrograms = (
  programs: ProgramOffering[],
  enrollmentInfo: EnrollmentInformation | undefined,
  partnerInfo: Partner | null,
): boolean =>
  programs.every((program) =>
    getPartnerProgramOfferings(enrollmentInfo, partnerInfo).includes(program),
  );

/**
 * Reads a B2B product offering composed of program and coursepacks to
 * determine the matching frontend program offering
 *
 * @param productOffering the product offering representation
 * @returns
 */
export const getProgramFromOffering = (
  productOffering: B2BProductOfferingProto | undefined,
): ProgramOffering | null => {
  if (!productOffering) {
    return null;
  }

  const { program, coursePacks } = productOffering;

  switch (program) {
    case "DIABETES_PREVENTION":
      return "DPP";
    case "HEALTHY_MIND":
      return "MOOD";
    case "HEALTHY_WEIGHT":
      if (coursePacks.find(({ value }) => value === "MED")) {
        return "MED";
      }

      return "WEIGHT";
    default:
      return null;
  }
};

export const getOfferingFromProgram = (
  program: ProgramOffering,
): B2BProductOfferingProto => {
  // currently we are building hardcoded offerings and ignoring coursepacks sent from the backend.
  // Supporting coursepacks sent from the backend is another level of complexity which we don't need yet.
  switch (program) {
    case "WEIGHT":
      return {
        program: "HEALTHY_WEIGHT",
        coursePacks: [],
      };
    case "MED":
      return {
        program: "HEALTHY_WEIGHT",
        coursePacks: [{ value: "MED" }],
      };
    case "DPP":
      return {
        program: "DIABETES_PREVENTION",
        coursePacks: [],
      };
    default:
      throw new Error(`Cannot convert unsupported program: ${program}`);
  }
};

/**
 * Determines the list of programs a user is eligible for based on the product
 * offerings the user is eligible for
 *
 * @param eligibleOfferings List of product offerings that a user is eligible for
 * @returns List of programs that the product offerings represent
 */
export const getEligibleProgramsFromEligibleOfferings = (
  eligibleOfferings: B2BProductOfferingProto[],
): ProgramOffering[] =>
  eligibleOfferings
    .map((offering) => getProgramFromOffering(offering))
    .filter((program): program is ProgramOffering => program !== null);
